<!-- LOGOUT CONFIRM DIALOG-->
<ng-template #logoutConfirme>
  <div class="p-2" style="width: 100%">
    <button type="button" class="close pull-right" aria-label="Close" (click)="confirmLogoutModalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <mat-divider></mat-divider>
  <div class="modal-body">
    <div class="text-center mb-2" style="color: #007ef0; background: #ffffff;">
      <p>Voulez-vous vraiment vous deconnectez.</p>
    </div>
    <mat-divider></mat-divider>
    <div class="row mt-4">
      <div class="col-12 text-center">
        <button style="color: #fff; background: #f01d2d" (click)="confirmLogoutModalRef.hide()" class="btn">Non</button>&nbsp;&nbsp;
        <button style="color: #fff; background: #007ef0" (click)="logout()" class="btn">Oui</button>
      </div>
    </div>
  </div>
</ng-template>

<!--==========================
    Top Bar
  ============================-->
<section id="topbar" class="d-none d-lg-block d-md-block">
    <div class="container clearfix">
        <div class="contact-info float-left">
            <i class="fa fa-envelope-o"></i>
            <a href="mailto:contact@mineinsiders.com">contact@mineinsiders.com</a>
            <i class="fa fa-whatsapp"></i>
            <a href="https://api.whatsapp.com/send?phone=22365128589" target="_blank">+223 65 12 85 89</a>
        </div>
        <div class="contact-info float-left">
          <div class="input-group" style="margin-left: 60px">
            <input type="text" [(ngModel)]="searchParam.query" name="query" style="height: 32px" class="form-control" placeholder="Recherche par mot clé" (keyup.enter)="search()">
            <div class="input-group-append" style="height: 32px">
              <button style="background-color: #959598;border-color: #a5a5b0;padding: 5px" class="btn btn-secondary" type="button" (click)="search()">
                <i class="fa fa-search"></i>
              </button>
            </div>
          </div>
        </div>
        <div class="social-links float-right">
            <a href="https://twitter.com/Mineinsiders?s=20" target="_blank" class="twitter"><i class="fa fa-twitter"></i></a>
            <a href="https://www.facebook.com/MineInsiders?_rdc=1&_rdr" target="_blank" class="facebook"><i class="fa fa-facebook"></i></a>
            <a href="https://www.linkedin.com/company/mineinsiders" target="_blank" class="linkedin"><i class="fa fa-linkedin"></i></a>
            <a href="https://www.instagram.com/mineinsiders/" target="_blank" class="linkedin"><i class="fa fa-instagram"></i></a>
            <a href="javascript:void(0)" routerLink="/contact"  class="linkedin">Contact</a>
        </div>
    </div>
</section>

<!--==========================
    Header
  ============================-->
<header id="header" style="height: 120px!important;">
    <div class="container">
      <div class="row">
        <div class="col-lg-5 col-md-5 col-sm-12 col-12">
          <div id="logo">
            <!--For Mobile -->
            <a href="" class="d-lg-none d-md-none">
              <img src="assets/img/logo/mineInsiders.jpeg" style="padding-top: 8px;padding-right: 40px;padding-bottom: 2px;" alt="" title="" />
            </a>
            <!--For web -->
            <a href="" class="d-none d-lg-block d-md-block">
              <img src="assets/img/logo/mineInsiders.jpeg" style="width: 100%;padding-top: 8px;padding-right: 40px;padding-bottom: 2px;" alt="" title="" />
            </a>
          </div>
        </div>
        <div class="col-lg-7  col-md-7 col-sm-12 col-12">
          <app-pub-a></app-pub-a>
        </div>
      </div>


        <nav id="nav-menu-container">
            <ul class="nav-menu">
                <!-- <li class="menu-active">
                    <a href="#" routerLink="/accueil">Accueil</a>
                </li> -->
              <li class="menu-has-children">
                <a href="javascript:void(0)" routerLink="/accueil">Accueil</a>
                <ul>
                  <li>
                    <a href="javascript:void(0)" routerLink="/about">Qui Nous Sommes</a>
                  </li>
                  <li>
                    <a href="javascript:void(0)" (click)="goToEntreprises()"  routerLink="/entreprises">Sites Miniers UEMOA</a>
                  </li>
                  <li>
                    <a href="javascript:void(0)" routerLink="/ressources">Ressources Minerales</a>
                  </li>
                  <li>
                    <a href="javascript:void(0)" routerLink="/professionnels">Professionnels des mines</a>
                  </li>
                  <li>
                    <a href="javascript:void(0)" routerLink="/jobs">Offres d'emploi</a>
                  </li>
                  <li>
                    <a href="javascript:void(0)" routerLink="/mining-news">Dernières infos</a>
                  </li>
                  <!-- <li>
                     <a href="javascript:void(0)" routerLink="/publicite">Publicité</a>
                   </li> -->
                   <li>
                     <a href="javascript:void(0)" routerLink="/login">Login</a>
                   </li>
                 </ul>
               </li>

               <li class="menu-has-children">
                 <a href="javascript:void(0)">Documents</a>
                 <ul>
                   <li>
                     <a href="javascript:void(0)" routerLink="/forum">Forum</a>
                   </li>
                   <li>
                     <a href="javascript:void(0)" routerLink="/code-miniers">Code Miniers</a>
                   </li>
                   <li>
                     <a href="javascript:void(0)" routerLink="/guides">Guide pour permis miniers
                     </a>
                   </li>
                 </ul>
               </li>

               <li class="menu-has-children">
                 <a href="javascript:void(0)">Miniers</a>
                 <ul>
                   <li>
                     <a href="javascript:void(0)" routerLink="/professionnels"  >Professionels</a>
                   </li>
                   <li>
                     <a href="javascript:void(0)" routerLink="/consultants">Consultants

                     </a>
                   </li>
                   <li>
                     <a href="javascript:void(0)" routerLink="/formateurs">Formateurs
                     </a>
                   </li>
                   <li>
                     <a href="javascript:void(0)" routerLink="/stages">Proposition de stages
                     </a>
                   </li>

                 </ul>
               </li>

               <li class="menu-has-children" >
                 <a href="javascript:void(0)">Mining news</a>
                 <ul>
                   <li>
                     <a href="javascript:void(0)" routerLink="/mining-news">News</a>
                   </li>
                   <li>
                     <a href="javascript:void(0)" routerLink="/mining-events">Events
                     </a>
                   </li>
                 </ul>
               </li>

               <li class="menu-has-children">
                 <a href="javascript:void(0)">Jobs</a>
                 <ul>
                   <li>
                     <a href="javascript:void(0)" routerLink="/jobs">Offres d'emploi</a>
                   </li>
                   <li>
                     <a href="javascript:void(0)" routerLink="/stages-opportunite">Offres de stages</a>
                   </li>

                 </ul>
               </li>

               <li class="menu-has-children">
                 <a href="javascript:void(0)">Partenaires</a>
                 <ul>
                   <li>
                     <a href="javascript:void(0)" routerLink="/partenaires">S'inscrire</a>
                   </li>
                 </ul>
               </li>

               <li class="menu-has-children">
                 <a href="javascript:void(0)">Mon Compte</a>
                 <ul>
                   <li *ngIf="!currentUser">
                     <a href="javascript:void(0)" routerLink="/login">Login</a>
                   </li>
                   <li *ngIf="!currentUser">
                     <a href="javascript:void(0)" routerLink="/register">Créer Compte</a>
                   </li>
                   <li *ngIf="currentUser">
                     <a href="javascript:void(0)" routerLink="/profil">Mon Profil</a>
                   </li>
                   <li *ngIf="currentUser">
                     <a href="javascript:void(0)" (click)="showLogoutDialog(logoutConfirme)">Se deconnecter</a>
                   </li>
                 </ul>
               </li>

             <!-- <li class="d-lg-none d-md-none">
                <div class="input-group">
                  <input type="text" [(ngModel)]="searchParam.query" name="query" style="height: 32px" class="form-control" placeholder="Recherche par mot clé" (keyup.enter)="search()">
                  <div class="input-group-append" style="height: 32px">
                    <button style="background-color: #959598;border-color: #a5a5b0;padding: 5px" class="btn btn-secondary" type="button" (click)="search()">
                      <i class="fa fa-search"></i>
                    </button>
                  </div>
                </div>
              </li>-->
             </ul>
         </nav>
         <!-- #nav-menu-container -->
    </div>
</header>
<!-- #header -->
<!--==========================
    Bottom Bar
  ============================-->
<div id="botbar" class="d-none d-lg-block d-md-block">
  <div class="container clearfix">
    <div class="contact-info float-left">
      <a href="#about" style="margin-right: 15px">Qui nous sommes</a>
      <a href="#entreprises" (click)="goToEntreprises()" style="margin-right: 15px">Répertoire des mines</a>
      <a href="#ressources" style="margin-right: 15px">Ressources Minerales</a>
      <a href="#professionnels" style="margin-right: 24px">Professionnels des mines</a>
      <a href="#jobs" style="margin-right: 24px">Offres d'emploi</a>
      <a href="#mining-news" style="margin-right: 24px">Dernières infos</a>
      <a  *ngIf="!currentUser" href="#login" style="margin-right: 24px">Login</a>
    </div>
  </div>
</div>

