<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '5px',fullScreenBackdrop:true }"></ngx-loading>
<ngx-loading-bar color="#fff"></ngx-loading-bar>
<section id="team" class="wow fadeInUp">
  <div class="container">
    <div class="section-header">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="/accueil">Accueil</a></li>
          <li class="breadcrumb-item active" aria-current="page">Opportunités de stages dans les mines de l'UEMOA</li>
        </ol>
      </nav>
    </div>
    <div class="section-header">
      <mat-card>
        <div class="row">
          <div class="col-md-8 col-lg-8 col-sm-8 col-12">
            <p style="color: #929197;font-size: large;text-align: center;">Les offres de stages dans l'espace UEMOA</p>
          </div>
        </div>
      </mat-card>
    </div>
    <div class="row">
      <div class="col-sm-12 col-lg-12 col-md-12 col-xl-12 col-12">
        <div class="card card-body bg-light" style="margin-bottom: 10px">
          <div class="row">
            <div class="col-sm-12 col-lg-12 col-md-12 col-xl-12 col-12">
              <div>
                <a href="javascript:void(0)" style="color: black">
                  <h4 style="font-size: x-large;color: #007cdc">{{stage?.nom}}</h4>
                </a>
                <p [innerHtml]="stage?.description"><span>...</span>
                <div class="row">
                  <div class="col-sm-6 col-lg-6 col-md-6 col-6 col-xl-6">
                    <span style="color: #007de2"><i class="fa fa-calendar"></i> {{ stage?.dateCreation | date: 'dd/MM/yyyy' }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

    </div>
  </div>

</section>
<!-- #team -->



