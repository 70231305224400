<!-------------HEADER-------------->
<!-- <app-header></app-header> -->
<!-------------HEADER-------------->
<!-- #about -->
<section id="about" class="wow fadeInUp">
  <div class="container">
    <div class="section-header">
      <h2>Condition Générale d'utilisation</h2>

    </div>
    <div class="row">
      <div class="col-lg-12 content">
        <h2><strong style="font-size:17px;">PRÉAMBULE</strong></h2>
            Le site MineInsiders.com a pour objet la mise à relation sous forme de networking entre les différents utilisateurs utilisant une plate-forme leur permettant de personnaliser leur profil et de voir et contacter d’autres utilisateurs, contre paiement d’un prix forfaitaire.
            En sus, il met à disposition des utilisateurs un annuaire référençant des sous-traitants professionnels dans le domaine des mines et constructions dans l’espace UEMOA.
            En accédant au site et/ou en s'inscrivant, les utilisateurs acceptent sans réserve les présentes conditions générales et reconnaissent qu’ils en ont pleinement pris connaissance.
            <h2><strong style="font-size:17px;">OBJET</strong></h2>
            Les présentes conditions générales ont pour objet de définir les modalités et conditions d’utilisation des services proposés sur MineInsiders.com, ainsi que de définir les droits et obligations entre MineInsiders et les utilisateurs.
           <h2><strong style="font-size:17px;">ACCÈS</strong></h2> 
            Le site est accessible à toute personne physique ou morale ayant la pleine capacité juridique.
            Les « guides » et les news et offres d’emploi et de stages sont accessibles gratuitement et sans inscription.
            L'accès au site peut à tout moment faire l'objet d'une interruption, d'une suspension, d'une modification sans préavis pour une maintenance ou pour tout autre cas. L'Utilisateur s'oblige à ne réclamer aucune indemnisation suite à l'interruption, à la suspension ou à la modification du présent contrat.
            Les utilisateurs peuvent contacter le site à l’adresse mail suivante :<em style="color: blue;">contact@mineinsiders.com</em> 
           <h2> <strong style="font-size:17px;">INSCRIPTION</strong></h2> 
            Le téléchargement des guides (ouvert pour un départ), ainsi que leur accès permanent sur un compte sont subordonnés à l’inscription des utilisateurs sur le site.
            L’utilisateur s’engage à remplir entièrement et avec véracité le formulaire d’inscription. Il lui sera demandé d’indiquer son prénom, son nom, son domaine d’expertise, son adresse mail et un mot de passe. L’utilisateur est entièrement responsable du maintien de sa confidentialité et reconnaît prendre toutes les précautions nécessaires afin de se prémunir de l’usurpation de son compte. MineInsiders décline toute responsabilité quant à la perte ou le vol, ainsi que ses conséquences, des identifiants de l’utilisateur.
            Il est aussi demandé à l’utilisateur de renseigner son statut de professionnel des mines ou non afin de cibler une offre dédiée à ceux-ci plus tard.
            L’inscription lui donnera accès à un espace dans lequel se trouvent ses informations personnelles qu’il pourra modifier et devra modifier lorsqu’elles auront évolué. L’utilisateur aura également accès aux modèles précédemment générés au format WORD et PDF.
            MineInsiders se réserve le droit de terminer l’inscription d’un utilisateur en cas de non - respect des présentes conditions générales.
            
           <h2><strong style="font-size:17px;">LES SERVICES</strong></h2> 
            <strong>Services</strong><br>
            MineInsiders met à disposition des utilisateurs inscrits un logiciel leur permettant de retrouver les entreprises expertes dans la sous-traitance des mines, ainsi que des documents d’étude realis2es dans les grands projets de constructions, mines, pétrole et énergie.
            MineInsiders met également à disposition un service de mise en relation, référençant des professionnels des mines en fonction de leur domaine d'activités et leur pays d'exercice.
            Enfin, MineInsiders met également à disposition des utilisateurs inscrits ou non des guides et textes rédigés par des spécialistes du domaine et portant sur des thèmes relatifs aux permis de recherche et ou d’exploitations des substances minérales dans les pays de l’UEMOA.



           <h2> <strong style="font-size:17px;">DE DOCUMENTS MINIERS</strong> </h2> 
           <strong>1) Commande</strong><br>
            
            Pour obtenir un document, l’utilisateur inscrit doit choisir un document dans la liste des documents et répondre au questionnaire. Une fois les questions répondues, il peut télécharger le document directement s’il est désigné comme gratuit. Si le modèle est désigné comme payant, il doit payer via les méthodes de paiement proposées et attendre la confirmation du paiement pour pouvoir télécharger le document.
            Le modèle généré est ensuite accessible dans l’espace de l’utilisateur. Il est à noter que les modèles peuvent ne plus être conservés dans l’espace pour des raisons techniques, l’utilisateur est donc fortement invité à sauvegarder le modèle aussitôt qu’il est généré ; MineInsiders ne pourra en aucun cas être tenu responsable de la perte du document dans l’espace.<br>
            <strong>2) Prix</strong><br>
            Pour des documents payants, le prix est indiqué au moment où l'utilisateur inscrit finalise sa commande. 
            Il est exprimé en Francs CFA et inclus tous frais.
            Certains documents étant gratuits et d'autres payants, MineInsiders se réserve le droit discrétionnaire et selon des modalités dont elle seule sera juge, d'appliquer un prix sur les documents gratuits ou de rendre gratuits des documents payants.<br>
            
            <strong>a) Révision des prix</strong><br>
            Le prix des document peut être révisé à tout moment, à la libre discrétion de MineInsiders.
            Les utilisateurs qui n’acceptent pas les nouveaux prix devront mettre fin à l'utilisation des services proposés sur le site.<br>
            
            <strong>b) Paiement</strong><br>
            Le prix est exigible à la commande. Les utilisateurs règlent leurs achats via l'application SamaMoney. Ils peuvent payer par carte bancaire (visa ou mastercard) ou par les solutions de paiement mobiles proposées par l'application (orange money, mobi-cash...). Étant entendu que les solutions de paiement mobiles sont au jour des présentes disponibles dans les pays de l’UEMOA.
            MineInsiders ne conserve aucune données bancaires, le service de paiement est réalisé entièrement par SamaMoney et ses partenaires.
            Les utilisateurs garantissent qu’ils disposent des autorisations nécessaires pour utiliser le mode de paiement choisi. Ils s’engagent à prendre toutes les mesures nécessaires afin que le prélèvement automatique du prix des Services puisse être effectué.
            MineInsiders communique par mail les factures des achats de documents effectués sur le site par les   utilisateurs inscrits.<br>
            
            <strong>c) Rétractation</strong><br>
            Conformément à l'article L221-28 13° du code de la consommation, les utilisateurs renoncent expressément à leur droit de rétractation.<br>

            
            <h2> <strong style="font-size:17px;">MISE EN RELATION AVEC PROFESSIONNEL DES MINES</strong> </h2>
            Le service de mise en relation constitue uniquement un service de référencement. En cas de mise en relation, la relation contractuelle sera totalement autonome et établie directement entre le professionnel et l’utilisateur sans aucune intervention de MineInsiders. MineInsiders ne peut garantir ni la légitimité, ni l'aptitude, ni la compétence du professionnel à intervenir auprès de l'utilisateur du service. De plus, le professionnel, à la suite d'une mise en relation, est totalement libre de son choix et susceptible de ne pas assister l’utilisateur.<br>
            
            
            <h2> <strong style="font-size:17px;">PROTECTION INTELLECTUELLE</strong> </h2>
            Les systèmes, logiciels, structures, infrastructures, bases de données et contenus de toute nature (textes, images, visuels, musiques, logos, marques, base de données, etc …) font l'objet d'une protection notamment par les articles L.111-1 et suivants du code de la propriété intellectuelle.
            L'accès au site et l'utilisation des services ne donnent aucun droit de propriété intellectuelle portant sur l'un quelconque des éléments du site, qui restent la propriété exclusive de MineInsiders.
            Le logiciel permettant la personnalisation des modèles de documents à contexte minier et mis à disposition des utilisateurs demeure la propriété exclusive de MineInsiders.
            Les modèles de document (personnalisés ou non) ainsi que les textes informatifs ne pourront, partiellement ou totalement, être reproduits, copiés, vendus, transmis, publiés, loués, représentés ou utilisés pour toute raison différente de celle d'un usage strictement privé, sauf accord expresse et préalable de MineInsiders. Tout contrevenant sera poursuivi devant les juridictions compétentes.
            Plus généralement, l'utilisateur doit solliciter l'autorisation expresse et préalable de MineInsiders pour tous désassemblages, décompilations, décryptages, extractions, réutilisations, copies et plus généralement, tous actes de reproduction, représentation, diffusion et utilisation même partiels de tout élément du site.
            L'utilisateur s'engage à une utilisation des contenus du site dans un cadre strictement privé. Une utilisation des contenus à des fins commerciales, sans autorisation expresse et préalable de MineInsiders, est strictement interdite et pourra faire l'objet de poursuites judiciaires.<br>
            
            
            <h2> <strong style="font-size:17px;">DONNÉES PERSONNELLES</strong> </h2>
            Afin d’augmenter leur visibilité, télécharger les modèles de documents et interagir avec les collègues professionnels des mines, les utilisateurs doivent s’inscrire et renseigner leurs nom, prénom, adresse mail, mot de passe et leur statut de professionnel du droit ou non (afin de cibler une offre pour ceux-ci plus tard).
            L'adresse mail pourra être utilisée par le site pour l'administration, la gestion et l'animation du service.
            Conformément à la loi « informatique et libertés » du 6 janvier 1978 modifiée, les utilisateurs disposent d’un droit d’accès et de rectification aux informations qui les concernent. Ils peuvent accéder aux informations les concernant ou s'y opposer, pour des motifs légitimes, à leur traitement en contactant MineInsiders ici.
            Déclaration de Rapid Services SARL, à considérer pour toute référence.<br>
            
            
            <h2> <strong style="font-size:17px;">RESPONSABILITÉ ET OBLIGATIONS</strong> </h2>
            MineInsiders ne peut en aucun cas être tenu responsable de l'utilisation faite des modèles de documents, guides ainsi que des textes sur les thèmes juridiques et miniers.
            MineInsiders n'est pas un cabinet de recrutement, ni un office notarial, ses services ne peuvent en aucun cas remplacer ceux de tels experts. MineInsiders met simplement à disposition des utilisateurs un logiciel leur permettant de personnaliser des modèles de documents rédigés par des chercher dans le domaine minier. Les modèles sont réputés conformes aux législations en vigueur et efficaces mais en aucun cas MineInsiders garantit leur conformité, leur efficacité ou leur parfaite exactitude, qui restent à l'entière responsabilité des utilisateurs.
            MineInsiders s’engage à fournir ses meilleurs efforts pour maintenir, via des contrôles réguliers, le fonctionnement et l’accessibilité du site. Il s'agit d'une obligation de moyens, le site ne s'engage pas à atteindre ce résultat.  Il se peut que l'accès au site soit momentanément interrompu pour des raisons de maintenance.
            En tout cas, MineInsiders ne saurait être tenu responsable des difficultés ou impossibilités momentanées d’accès au site qui auraient pour origine des circonstances qui lui sont extérieures, la force majeure, ou encore qui seraient dues à des perturbations des réseaux de télécommunication.
            Les utilisateurs s'engagent à prendre toutes les mesures appropriée de façon à protéger leurs systèmes informatique de la contamination par des éventuels virus sur le réseau de l'internet.
            Les utilisateurs s'engagent à adopter un comportement loyal dans l'utilisation du site.
            Des liens hypertextes peuvent être présents sur le site, cependant les pages web où mènent ces liens n'engagent en rien la responsabilité de MineInsiders qui n'a pas le contrôle de ces liens.
            Dans le cadre des services de référencement de professionnels, MineInsiders :
            •	ne procède à aucune vérification de la véracité, complétude et pertinence des informations fournies par les professionnels référencés ;
            •	n’intervient en aucun cas et n’exerce aucun contrôle, de quelque nature que ce soit, sur la décision des professionnels d’intervenir ou non et sur les prix de leurs prestations ;
            •	n’intervient en aucun cas dans la relation contractuelle qui est susceptible d’être établie entre l’Utilisateur et le professionnel à la suite d’une mise en relation, pas plus que dans leurs relations financières et ne peut être tenue pour responsable à cet égard ; et
            •	n’assume aucune responsabilité en cas de mauvaise utilisation des services proposés sur le site MineInsiders par le Client ou le professionnel, d’insatisfaction de l’utilisateur quant à la prestation fournie par le professionnel.<br>

            
            <h2> <strong style="font-size:17px;">MODIFICATION UNILATÉRALE DU CONTRAT</strong> </h2>
            MineInsiders se réserve à tout moment le droit de modifier les clauses stipulées dans les présentes. MineInsiders pourra donc modifier à tout moment et sans préavis tout élément du site, à savoir notamment les services et leur prix.
            
            
            <h2> <strong style="font-size:17px;">DIVISIBILITÉ DES CLAUSES</strong> </h2>
            La nullité d'une des clauses des présentes n'entraîne pas la nullité de l'ensemble des présentes conditions générales.
            
            
            <h2> <strong style="font-size:17px;"> DROIT APPLICABLE ET JURIDICTIONS COMPÉTENTES</strong> </h2>
            La législation de l’espace UEMOA s'applique au présent contrat. En cas d'absence de résolution amiable d'un litige né entre les parties, seuls les tribunaux représentant cet espace économique et monétaire sont compétents.


      </div>
    </div>
  </div>
</section>
<!-- #about -->
<!-------------HEADER-------------->
<!-- <app-footer></app-footer> -->
<!-------------HEADER-------------->
