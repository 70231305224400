
<!-- #repertoires -->

<section id="publicite" class="wow fadeInUp" style="min-height: 500px;margin-top: 20px">
  <div class="container">
    <div class="section-header">
      <h2>Publicité</h2>
    </div>
    <div class="row" *ngIf="publiciteList?.length==0">
      <h3 align="center">Aucune publicité trouvée</h3>
    </div>
    <div class="row" *ngIf="publiciteList?.length>0">
      <div class="col-lg-6 col-md-6 col-lg-12 col-sm-12" *ngFor="let publicite of publiciteList| paginate: { itemsPerPage: 2, currentPage: p }">
        <div class="box wow fadeInLeft">
          <div class="icon"><img [src]="publiciteImageUri+publicite?.imageName" width="90px" height="90px" /></div>
          <h4 class="title"><a href="javascript:void(0)">{{publicite.titre}}</a></h4>
          <p class="description" [innerHtml]="publicite.description | slice:0:100"></p><span>...</span>
        </div>
      </div>
    </div>
    <pagination-controls (pageChange)="p = $event"
                           directionLinks="true"
                           autoHide="true"
                           responsive="true"
                           previousLabel="Précédent"
                           nextLabel="Suivant"
                           screenReaderPaginationLabel="Pagination"
                           screenReaderPageLabel="page"
                           screenReaderCurrentLabel="Vous êtes dejà sur la page">
      </pagination-controls>
  </div>
</section>
<!-- #repertoires -->
