import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PartenaireInscrisService {

  private serviceUrl: string;
  private httpOptions: any;
  

  constructor(private http: HttpClient) {
   
    this.serviceUrl = environment.apiUrl;
  }

  public findAllPartenaire() {
    return this.http.get(this.serviceUrl + '/partenaireInscris' );
  }

 
  public save(partenaire) {
   // console.log('*******objectToSave*****' + JSON.stringify(partenaire));
    return this.http.post(this.serviceUrl + '/partenaireInscris/', partenaire, this.httpOptions);
  }

  public update(partenaire) {
    return this.http.put(this.serviceUrl + '/partenaireInscris/' , partenaire, this.httpOptions);
  }

  public desactivate(partenaireId) {
    // console.log('*******objectTo desactivate*****' + JSON.stringify(partenaireId));
    return this.http.put(this.serviceUrl + '/partenaireInscris/desactivate/' + partenaireId, this.httpOptions);
  }

  public activate(partenaire) {
    return this.http.put(this.serviceUrl + '/partenaireInscris/activate/'  +partenaire, this.httpOptions);
  }
  
}
