import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-detail-opportunite',
  templateUrl: './detail-opportunite.component.html',
  styleUrls: ['./detail-opportunite.component.scss']
})
export class DetailOpportuniteComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
