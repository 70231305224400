import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { environment } from 'src/environments/environment';
import { Opportunite } from 'src/app/shared/components/models/opportunite';
import { OpportuniteService } from 'src/app/core/services/opportunite.service';
import {LoadingBarService} from '@ngx-loading-bar/core';
import { StageService } from 'src/app/core/services/stage.service';

@Component({
  selector: 'app-stages-detail',
  templateUrl: './stages-detail.component.html',
  styleUrls: ['./stages-detail.component.scss']
})
export class StagesDetailComponent implements OnInit {
  stageList: Opportunite[];
  stage: Opportunite;

  loading: boolean = false;
  opportuniteImageUri: any;
  constructor(private router: Router,private route: ActivatedRoute,
              private opportuniteService: OpportuniteService,
              private loadingBar: LoadingBarService,
              private stageService: StageService) {
    this.opportuniteImageUri = environment.opportuniteImageUri;
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      if (params.opportunite) {
        this.loadingBar.start();
        this.stageService.findStageById(params.opportunite).subscribe(ret => {
          if (ret['status'] === 'OK') {
            this.stage = ret['response'];
           // console.log('list stages', this.stage);
            this.loadingBar.complete();
          } else {
            this.loadingBar.complete();
            this.router.navigate(['/error']);
          }
        }, error => {
          // console.log('erreur server');
          this.loadingBar.complete();
          this.router.navigate(['/error']);
        });
      } else {
        this.router.navigate(['/error']);
      }
    });
    window.scrollTo(0, 0);
  }

}
