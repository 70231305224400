import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import { MineInsidersUtils } from 'src/app/shared/components/utils/mineInsiders-util';
import {Minier} from '../../shared/components/models/minier';
import {SearchParam} from '../../shared/components/models/search-param';
import {Entreprise} from '../../shared/components/models/entreprise';

@Injectable({
  providedIn: 'root'
})
export class EntrepriseService {
  private serviceURL: string;
  private httpOptions: any;

  constructor(private http: HttpClient) {
    this.serviceURL = environment.apiUrl;
    this.httpOptions = new MineInsidersUtils().httpHeaders();
  }

  public save(entreprise: Entreprise) {
    return this.http.post(this.serviceURL + '/entreprise', entreprise, this.httpOptions);
  }

  public findEntrepriseByCountry(code) {
    return this.http.get(this.serviceURL + '/entreprise/find/country/' + code, this.httpOptions);
  }
  public findById(id) {
    return this.http.get(this.serviceURL + '/entreprise/find/' + id, this.httpOptions);
  }
  public findLastEntreprise() {
    return this.http.get(this.serviceURL + '/entreprise/findLast', this.httpOptions);
  }
}
