import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class EventsService {

  private serviceUrl: string;
  private httpOptions: any;

  constructor(private http: HttpClient) {
    this.serviceUrl = environment.apiUrl;
  }

  public findAllEnabledEvents() {
    return this.http.get(this.serviceUrl + '/events/findEnabled', this.httpOptions);
  }

  public findEventById(eventId) {
    return this.http.get(this.serviceUrl + '/events/findById/' + eventId, this.httpOptions);
  }

  public findLastest() {
    return this.http.get(this.serviceUrl + '/events/find/latest');
  }
  public findOthers(newsId) {
    return this.http.get(this.serviceUrl + '/events/findOthers/' + newsId);
  }
}
