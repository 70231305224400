import { Component, OnInit } from '@angular/core';
import {MyMatDialogConfig} from '../../../configs/mat-dialog-config';
import {DomaineService} from '../../../core/services/domaine.service';
import {PaysService} from '../../../core/services/pays.service';
import {EcoleService} from '../../../core/services/ecole.service';
import {ActivatedRoute, Router} from '@angular/router';
import {MinierService} from '../../../core/services/minier.service';
import {Minier} from '../../../shared/components/models/minier';
import {environment} from '../../../../environments/environment';
import {Location} from '@angular/common';
import {Message} from '../../../shared/components/models/message';
import {MessageService} from '../../../core/services/message.service';
import {ToastService} from '../../../shared/components/utils/toast.service';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {MatDialog} from '@angular/material/dialog';
import {LoadingBarService} from '@ngx-loading-bar/core';

@Component({
  selector: 'app-minier-details',
  templateUrl: './minier-details.component.html',
  styleUrls: ['./minier-details.component.scss']
})
export class MinierDetailsComponent implements OnInit {
  minier: Minier;
  message: Message = new Message();
  profilImageUri = '';
  previousPage = '';
  loading: boolean = false;
  sendMessageDialog: BsModalRef;
  config = {
    backdrop: true,
    ignoreBackdropClick: true
  };
  constructor(private matDialogConfig: MyMatDialogConfig,
              public dialog: MatDialog,
              private paysService: PaysService,
              private domaineService: DomaineService,
              private ecoleService: EcoleService,
              private minierService: MinierService,
              private router: Router,
              private route: ActivatedRoute,
              private location: Location,
              private modalService: BsModalService,
              private messageService: MessageService,
              private toast: ToastService,
              private loadingBar: LoadingBarService) {
    this.profilImageUri = environment.profilImageUri;
  }
  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      if (params.minerId) {
        this.loadingBar.start();
        this.minierService.findCurrentMiner(params.minerId).subscribe(ret => {
          if (ret['status'] === 'OK') {
            this.minier = ret['response'];
            if (this.minier.type === 'PROFESSIONNEL') {
              this.previousPage = 'Professionnels des mines';
            } else if (this.minier.type === 'CONSULTANT') {
              this.previousPage = 'Consultants dans les mines';
            } else {
              this.previousPage = 'Formateur dans les mines';
            }
           //console.log('minier', this.minier);
            this.loadingBar.complete();
          } else {
            this.loadingBar.complete();
            this.router.navigate(['/error']);
          }
        }, error => {
          // console.log('erreur server');
          this.loadingBar.complete();
          this.router.navigate(['/error']);
        });
      } else {
        this.router.navigate(['/error']);
      }
    });
    window.scrollTo(0, 0);
  }
  backClicked() {
    this.location.back();
  }
  openSendMessage(template: any) {
    this.message = new Message();
    this.sendMessageDialog = this.modalService.show(template, this.config);
  }
  sendMessage() {
    this.message.minier = this.minier;
    // console.log('message', this.message);
    this.messageService.save(this.message).subscribe(ret => {
      if (ret['status'] === 'OK') {
        this.message = ret['response'];
        // console.log('message retour', this.message);
        this.sendMessageDialog.hide();
        this.toast.info('Message envoyé avec succès!');
      } else {
        // console.log('erreur survenu');
      }
    }, error => {
      // console.log('erreur server');
    });
  }
}
