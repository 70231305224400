<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '5px',fullScreenBackdrop:true }"></ngx-loading>
<ngx-loading-bar color="#fff"></ngx-loading-bar>
<section id="team" class="wow fadeInUp">
  <div class="container">
    <div class="section-header">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="#">Acceuil</a></li>
          <li class="breadcrumb-item active" aria-current="page">Consultants dans les mines</li>
        </ol>
        <i class="fa fa-hand-pointer-o" style="color: #007cdc;"></i> <a href="#/professionnels">  Professionnels</a> |  <a href="#/formateurs"> Formateurs</a>
      </nav>
    </div>
    <div class="section-header">
      <mat-card>
        <div class="row">
          <div class="col-md-8 col-lg-8 col-sm-8 col-12">
            <span style="font-weight: bolder">Vous êtes un</span> <span style="font-size:22px;font-weight: bold"> Consultant </span> <span style="font-weight: bolder"> dans les mines?</span>
            Inscrivez-vous<span style="font-weight: bold;color: #1a68ff;"> gratuitement</span> sur notre plateforme et gagnez en visibilité !
          </div>
          <div class="col-md-4 col-lg-4 col-sm-4 col-12">
            <button class="btn btn-outline-primary" (click)="goToRegister()">Je m'inscris gratuitement</button>
          </div>
        </div>
      </mat-card>
    </div>
    <div class="row">
      <div class="col-lg-12 col-md-12">
        <mat-card>
          <div class="row">
            <div class="col-md-12 col-lg-12 col-sm-12 col-12">
              <div class="row">
                <div class="col-lg-3 col-md-3">
                  <mat-form-field style="width: 100%">
                    <mat-label>Pays</mat-label>
                    <mat-select [(ngModel)]="searchParam.countryId" name="pays">
                      <div class="select-search-input"><input matInput type="text" name="paysFilter"
                                                              [(ngModel)]="paysFilter.nom" placeholder="Rechercher..."></div>
                      <mat-option value="null">Tous</mat-option>
                      <mat-option  [value]="p.id" *ngFor="let p of paysList | filterBy: paysFilter">
                        {{p.nom}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="col-lg-3 col-md-3">
                  <mat-form-field style="width: 100%">
                    <mat-label>Domaine</mat-label>
                    <mat-select [(ngModel)]="searchParam.domaineId" name="domaine">
                      <div class="select-search-input"><input matInput type="text" name="domaineFilter"
                                                              [(ngModel)]="domaineFilter.nom" placeholder="Rechercher..."></div>
                      <mat-option value="null">Toutes</mat-option>
                      <mat-option  [value]="dom.id" *ngFor="let dom of domaines | filterBy: domaineFilter">
                        {{dom.nom}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="col-lg-4 col-md-4">
                  <mat-form-field style="width: 100%">
                    <mat-label>Ecole / Université</mat-label>
                    <mat-select [(ngModel)]="searchParam.ecoleId" name="ecole">
                      <div class="select-search-input"><input matInput type="text" name="ecoleFilter"
                                                              [(ngModel)]="ecoleFilter.nom" placeholder="Rechercher..."></div>
                      <mat-option value="null">Toutes</mat-option>
                      <mat-option  [value]="ecole.id" *ngFor="let ecole of ecoles | filterBy: ecoleFilter">
                        {{ecole.nom}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="col-md-2 col-lg-2">
                  <button class="btn btn-primary pull-right" (click)="searchMiner()">Rechercher</button>
                </div>
              </div>
            </div>
          </div>
        </mat-card>
        <br>
      </div>
      <div class="col-lg-12 col-md-12">
        <div class="row" *ngIf="miniers?.length>0">
          <div class="col-lg-4 col-md-4 col-xl-4 col-sm-12" *ngFor="let minier of miniers | paginate: { itemsPerPage: 42, currentPage: p }">
            <mat-card style="margin-bottom: 20px">
              <div class="member" style="border: 2px solid #97969c;border-radius: 5px;">
                <div class="pic"><img [src]="profilImageUri+minier.profilImage" style="height: 200px" alt=""></div>
                <div class="details">
                  <h4>{{minier?.prenom}} {{minier?.nom}}</h4>
                  <span style="font-weight: bold">{{minier.specialite}}</span>
                  <span>{{minier?.ville}} , {{minier?.pays?.nom}}</span>
                  <!--<span [innerHTML]="minier.presentation | slice:0:70">...</span> -->
                  <div class="social">
                    <button class="btn" style="color: #007ddf"  (click)="showDetailsMiniers(minier)">En savoir plus</button>
                  </div>
                </div>
              </div>
            </mat-card>
          </div>
        </div>
        <div class="row" *ngIf="miniers?.length==0">
          <h3>Aucun consultant dans les mines trouvés</h3>
        </div>
      </div>
      <div class="col-lg-12 col-md-12 col-xl-12 col-sm-12" align="center">
        <pagination-controls (pageChange)="onPageChange($event)"
                             directionLinks="true"
                             autoHide="true"
                             responsive="true"
                             previousLabel="Précédent"
                             nextLabel="Suivant"
                             screenReaderPaginationLabel="Pagination"
                             screenReaderPageLabel="page"
                             screenReaderCurrentLabel="Vous êtes dejà sur la page">
        </pagination-controls>
      </div>
    </div>
  </div>
</section>
<!-- #team -->


