import {Deserializable} from './deserializable';
import { Injectable } from '@angular/core';
import {Ecole} from './ecole';
import {Pays} from './pays';
import {Domaine} from './domaine';
@Injectable()
export class Minier implements Deserializable<Minier> {
   id: number;
   nom: string;
   prenom: string;
   telephone: string;
   email: string;
   adresse: string;
   password: string;
   confirmPassword: string;
   presentation: string;
   curriculum: string;
   specialite: string;
   formation: string;
   experience: string;
   dateCreation: Date;
   dateModification: Date;
   enabled: boolean;
   pays: Pays;
   ecole: Ecole;
   ville: string;
   sexe: string;
   type: string;
   profilImage: string;
   profilImageData: string;
   domaines: Domaine[];
  deserialize(input: any): Minier {
    Object.assign(this, input);
    return this;
  }
}
