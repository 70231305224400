import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { Publicite } from 'src/app/shared/components/models/publicite';
import { PubliciteService } from 'src/app/core/services/publicite.service';

@Component({
  selector: 'app-publicite',
  templateUrl: './publicite.component.html',
  styleUrls: ['./publicite.component.css']
})
export class PubliciteComponent implements OnInit {

  publiciteList: Publicite[];
  publiciteImageUri = '';
  loading: boolean = false;
  p: number = 1;
  sliderImageUri= '';
  constructor(private router: Router,
    private publiciteService: PubliciteService,) {
    this.publiciteImageUri = environment.publiciteImageUri;

  }

  ngOnInit() {
   this.loadPubliciteList();

  }
  loadPubliciteList() {
    this.loading = true;
    this.publiciteService.findAllPublicite().subscribe(ret => {
      if (ret['status'] === 'OK') {
        this.publiciteList = ret['response'];
        // console.log('list publicites ', this.publiciteList);
        this.loading = false;
      } else {
        // console.log('erreur survenu');
        this.loading = false;
      }
    }, error => {
      // console.log('erreur server');
      this.loading = false;
    });
  }

}
