import {Deserializable} from './deserializable';
import { Injectable } from '@angular/core';
import { File } from './file';
import {Minier} from './minier';
@Injectable()
export class Message implements Deserializable<Message> {
  public id: number;
  public nom: string;
  public email: string;
  public description: string;
  public objet: string;
  public dateCreation: string;
  public dateModification: string;
  public enabled: boolean = true;
  public see: boolean = false;
  public minier: Minier;

  deserialize(input: any): Message {
    Object.assign(this, input);
    return this;
  }
}
