import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Commentaire} from '../../shared/components/models/commentaire';

@Injectable({
  providedIn: 'root'
})
export class CommentaireService {

  private serviceUrl: string;
  private httpOptions: any;


  constructor(private http: HttpClient) {

    this.serviceUrl = environment.apiUrl;
  }

  public findCommentByDocument(docId) {
    return this.http.get(this.serviceUrl + '/comment/document/' + docId, this.httpOptions);
  }

  public save(comment: Commentaire) {
    return this.http.post(this.serviceUrl + '/comment/', comment, this.httpOptions);
  }

  public update(comment: Commentaire) {
    return this.http.put(this.serviceUrl + '/comment/' , comment, this.httpOptions);
  }

  public desactivate(docId) {
    return this.http.put(this.serviceUrl + '/comment/desactivate/' + docId, this.httpOptions);
  }

  public activate(docId) {
    return this.http.put(this.serviceUrl + '/comment/activate/'  + docId, this.httpOptions);
  }

}
