import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {EntrepriseService} from '../../../core/services/entreprise.service';
import {Entreprise} from '../../../shared/components/models/entreprise';
import {environment} from '../../../../environments/environment';
import {Location} from '@angular/common';

@Component({
  selector: 'app-entreprise-details',
  templateUrl: './entreprise-details.component.html',
  styleUrls: ['./entreprise-details.component.scss']
})
export class EntrepriseDetailsComponent implements OnInit {
  entreprise: Entreprise;
  loading: boolean = false;
  imageUrl = '';
  constructor(private router: Router,
              private route: ActivatedRoute,
              private entrepriseService: EntrepriseService,
              private location: Location) {
    this.imageUrl = environment.entrepriseImageUri;
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      if (params.entreprise) {
        this.loading = true;
        this.entrepriseService.findById(params.entreprise).subscribe(ret => {
          if (ret['status'] === 'OK') {
            this.entreprise = ret['response'];
            // console.log('entreprise', this.entreprise);
            this.loading = false;
          } else {
            this.loading = false;
            this.router.navigate(['/error']);
          }
        }, error => {
          // console.log('erreur server');
          this.loading = false;
          this.router.navigate(['/error']);
        });
      } else {
        this.router.navigate(['/error']);
      }
    });
    window.scrollTo(0, 0);
  }
  backClicked() {
    this.location.back();
  }

}
