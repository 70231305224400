import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Documents} from '../../shared/components/models/documents';
import {Minier} from '../../shared/components/models/minier';
import {MineInsidersUtils} from '../../shared/components/utils/mineInsiders-util';
import {PaysService} from '../../core/services/pays.service';
import {Pays} from '../../shared/components/models/pays';
import {DomaineService} from '../../core/services/domaine.service';
import {EcoleService} from '../../core/services/ecole.service';
import {Domaine} from '../../shared/components/models/domaine';
import {Ecole} from '../../shared/components/models/ecole';
import {MinierService} from '../../core/services/minier.service';
import {Router} from '@angular/router';
import {environment} from '../../../environments/environment';
import {Message} from '../../shared/components/models/message';
import {MessageService} from '../../core/services/message.service';
import {DomSanitizer} from '@angular/platform-browser';
import {DocumentService} from '../../core/services/document.service';
import {ToastService} from '../../shared/components/utils/toast.service';
import {Stage} from '../../shared/components/models/stage';
import {StageService} from '../../core/services/stage.service';
import {PostulantService} from '../../core/services/postulant.service';
import {Postulant} from '../../shared/components/models/postulant';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {DateAdapter} from '@angular/material/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Editor} from 'ngx-editor';
declare var $: any;

@Component({
  selector: 'app-profil',
  templateUrl: './profil.component.html',
  styleUrls: ['./profil.component.scss']
})
export class ProfilComponent implements OnInit, AfterViewInit, OnDestroy {
  currentUser: Minier;
  currentMessage: Message;
  doc: Documents = new Documents();
  stage: Stage = new Stage();
  formation = '';
  profilImageUri = '';
  documentImageUri = '';
  cvFileUri = '';
  paysList: Pays[];
  domaines: Domaine[];
  ecoles: Ecole[];
  messages: Message[];
  forumDocs: Documents[];
  stages: Stage[];
  postulants: Postulant[];
  messageNotSeeCount: number;
  listDoc: boolean = true;
  addDoc: boolean = false;
  listStage: boolean = true;
  addStage: boolean = false;
  postulantStage: boolean = false;
  showCv: boolean = false;
  editingProfilImage: boolean = false;
  loading: boolean = false;
  profilImageData: any;
  documentData = null;
  updateProfilDialog: BsModalRef;
  detailMessageDialog: BsModalRef;
  consulteCvDialog: BsModalRef;
  config = {
    backdrop: true,
    ignoreBackdropClick: true

  };
  editor: Editor;
  editor2: Editor;
  editor3: Editor;
  editor4: Editor;
  editor5: Editor;
  displayedColumns = ['date', 'nom', 'etat', 'options'];
  dataSource: MatTableDataSource<Documents>;

  displayedColumnsMessage = ['date', 'objet', 'nom', 'email', 'options'];
  dataSourceMessage: MatTableDataSource<Message>;

  displayedColumnsStage = ['date', 'nom', 'etat', 'options'];
  dataSourceStage: MatTableDataSource<any>;

  p: number = 1;
  @ViewChild('fileInput', {static: true}) fileInput: any;
  @ViewChild('docInput', {static: true}) docInput: any;
  @ViewChild('forumPaginator', {static: true}) forumPaginator: MatPaginator;
  @ViewChild('forumSort', {static: true}) forumSort: MatSort;
  @ViewChild('stagePaginator', {static: true}) stagePaginator: MatPaginator;
  @ViewChild('stageSort', {static: true}) stageSort: MatSort;
  public postulant: Postulant;
  constructor(public dateAdapter: DateAdapter<Date>,
              public paysService: PaysService,
              public domaineService: DomaineService,
              public ecoleService: EcoleService,
              public minierService: MinierService,
              public router: Router,
              public modalService: BsModalService,
              public messageService: MessageService,
              public domSanitizer: DomSanitizer,
              public documentService: DocumentService,
              public toast: ToastService,
              public stageService: StageService,
              public dialog: MatDialog,
              public postulantService: PostulantService) {
    this.currentUser = new MineInsidersUtils().getCurrentUser();
    this.dateAdapter.setLocale('fr-FR');
    this.profilImageUri = environment.profilImageUri;
    this.documentImageUri = environment.documentImageUri;
    this.cvFileUri = environment.cvFileUri;
  }

  ngOnInit() {
    // console.log('========= user current =====',this.currentUser);
    if (this.currentUser) {
      this.minierService.findCurrentMiner(this.currentUser.id).subscribe(ret => {
        if (ret['status'] === 'OK') {
          this.currentUser = ret['response'];
         // console.log('currentMiner', this.currentUser);
          this.listDoc = true;
          this.listStage = true;
          this.loadCountriesList();
          this.loadDomaines();
          this.loadEcoles();
          this.loadMessages();
          this.getNoSeeMessageCount();
          this.loadMinerForumDoc();
          this.findStageByMiner();
        } else {

        }
      }, error => {
        // console.log('erreur server');
      });
    } else {
      // console.log('non autorisé à acceder à la page');
      this.router.navigate(['/login'])
        .then(() => {
          window.location.reload();
        });
    }
    this.editor = new Editor();
    this.editor2 = new Editor();
    this.editor3 = new Editor();
    this.editor4 = new Editor();
    this.editor5 = new Editor();
  }
  ngAfterViewInit() {
    // loading templates js after dom render
  }
  // make sure to destory the editor
  ngOnDestroy(): void {
    this.editor.destroy();
    this.editor2.destroy();
    this.editor3.destroy();
    this.editor4.destroy();
    this.editor5.destroy();
  }
  showListDoc() {
    // to show documents list
    this.listDoc = true;
    this.addDoc = false;
    this.doc = new Documents();
  }
  showAddDoc() {
    // to Add new document
    this.listDoc = false;
    this.addDoc = true;
    this.doc = new Documents();
  }
  showEditDoc(row) {
    // to Add new document
    this.doc = row;
    // console.log('document edit', this.doc);
    this.listDoc = false;
    this.addDoc = true;
  }

  showListStage() {
    // to show proposition stage list
    this.listStage = true;
    this.addStage = false;
    this.findStageByMiner();
    this.stage = new Stage();
    this.postulantStage = false;
    this.showCv = false;
  }
  showAddStage() {
    // to Add new  proposition stage
    this.stage = new Stage();
    this.listStage = false;
    this.addStage = true;
    this.postulantStage = false;
    this.showCv = false;
  }

  showEditStage(row) {
    // to Add new  proposition stage
    this.stage = row;
    this.listStage = false;
    this.addStage = true;
    this.postulantStage = false;
    this.showCv = false;
  }

  showStagePostulant(row) {
    // to show postulant list by stage
    this.stage = row;
    this.findPostulantByStage(this.stage.id);
    this.postulantStage = true;
    this.listStage = false;
    this.addStage = false;
    this.showCv = false;
  }

  consultePostulantCv(row) {
    this.postulant = row;
    this.showCv = true;
    this.postulantStage = false;
    this.listStage = false;
    this.addStage = false;
  }
  showDetailMessage(message: Message, template: any) {
    this.currentMessage = message;
    this.markMessageAsSee(message);
    this.detailMessageDialog = this.modalService.show(template, this.config);
  }

  selectPicture() {
    this.fileInput.nativeElement.click();
    this.editingProfilImage = true;
  }

  selectDocPicture() {
    this.docInput.nativeElement.click();
  }

  processWebImage(event) {
    if (event.target.files.length <= 0) {
      return;
    }
    const reader = new FileReader();
    reader.onload = readerEvent => {
      const imageData = (readerEvent.target as any).result;
      let imageSize = Number(event.target.files[0].size);
      imageSize = imageSize / 1024;
      if (imageSize > 1024 * 1024 * 3) {
        // this.toast.info("la taille de l'image depasse 3 Mo");
      } else {
        this.profilImageData = imageData;
       // console.log('imgData', imageData);
      }
    };
    reader.readAsDataURL(event.target.files[0]);
    // this.readFile(event.target.files[0])
    event.stopPropagation();
  }
  processDocImage(event) {
    if (event.target.files.length <= 0) {
      return;
    }
    const reader = new FileReader();
    reader.onload = readerEvent => {
      const imageData = (readerEvent.target as any).result;
      let imageSize = Number(event.target.files[0].size);
      imageSize = imageSize / 1024;
      if (imageSize > 1024 * 1024 * 3) {
        // this.toast.info("la taille de l'image depasse 3 Mo");
      } else {
        this.documentData = imageData;
       // console.log('imgData', imageData);
      }
    };
    reader.readAsDataURL(event.target.files[0]);
    // this.readFile(event.target.files[0])
    event.stopPropagation();
  }

  loadCountriesList() {
    this.paysService.loadCountriesList().subscribe(ret => {
      if (ret['status'] === 'OK') {
        this.paysList = ret['response'];
       // console.log('paysList', this.paysList);
        if (this.currentUser.pays) {
          this.currentUser.pays = this.paysList.find(p => p.id === this.currentUser.pays.id);
        }
      } else {
        // console.log('erreur survenu');
      }
    }, error => {
      // console.log('erreur server');
    });
  }
  loadDomaines() {
    this.domaineService.loadDomaineList().subscribe(ret => {
      if (ret['status'] === 'OK') {
        this.domaines = ret['response'];
       // console.log('domaines', this.domaines);
        const tmpDomaines: Domaine[] = [];
        if (this.currentUser.domaines) {
          this.currentUser.domaines.forEach(dom => {
            tmpDomaines.push(
              this.domaines.find(d => d.id === dom.id)
            );
          });
          this.currentUser.domaines = tmpDomaines;
        }
      } else {
        // console.log('erreur survenu');
      }
    }, error => {
      // console.log('erreur server');
    });
  }
  loadEcoles() {
    this.ecoleService.loadEcoleList().subscribe(ret => {
      if (ret['status'] === 'OK') {
        this.ecoles = ret['response'];
       // console.log('ecoles', this.ecoles);
        if (this.currentUser.ecole) {
          this.currentUser.ecole = this.ecoles.find(e => e.id === this.currentUser.ecole.id);
        }
      } else {
        // console.log('erreur survenu');
      }
    }, error => {
      // console.log('erreur server');
    });
  }
  updateProfilData() {
    this.loading = true;
    this.minierService.update(this.currentUser).subscribe(ret => {
      if (ret['status'] === 'OK') {
        this.currentUser = ret['response'];
        this.loadCountriesList();
        this.loadDomaines();
        this.loadEcoles();
        this.loading = false;
        this.toast.success('Mise à jour effectuée avec succès!');
      } else {
        // console.log('erreur survenu');
        this.toast.error(ret['message']);
        this.loading = false;
      }
    }, error => {
      // console.log('erreur server');
      this.loading = false;
      this.toast.error('Problème de connexion au serveur, veuillez verifier votre connexion internet');
    });
  }
  updateProfilImage() {
    this.currentUser.profilImageData = this.profilImageData;
    // console.log('minier', this.currentUser);
    this.minierService.updateProfilImage(this.currentUser).subscribe(ret => {
      if (ret['status'] === 'OK') {
        this.currentUser = ret['response'];
      //  console.log('Minier updated profil image ', this.currentUser);
        this.updateProfilDialog.hide();
        this.loadCountriesList();
        this.loadDomaines();
        this.loadEcoles();
        this.loading = false;
      } else {
        // console.log('erreur survenu');
        this.loading = false;
        this.toast.error(ret['message']);
      }
    }, error => {
      // console.log('erreur server');
      this.loading = false;
      this.toast.error('Problème de connexion au serveur, veuillez verifier votre connexion internet');
    });
  }
  showUpdatepdateProfilImageDialog(template: any) {
    this.updateProfilDialog = this.modalService.show(template, this.config);
  }
  loadMessages() {
    this.messageService.loadMessage(this.currentUser.id).subscribe(ret => {
      if (ret['status'] === 'OK') {
        this.messages = ret['response'];
      //  console.log('messages', this.messages);
        this.dataSourceMessage = new MatTableDataSource(this.messages);
      } else {
        // console.log('erreur survenu');
      }
    }, error => {
      // console.log('erreur server');
      this.toast.error('Problème de connexion au serveur, veuillez verifier votre connexion internet');
    });
  }
  getNoSeeMessageCount() {
    this.messageService.getUnSeenMessageCount(this.currentUser.id).subscribe(ret => {
      if (ret['status'] === 'OK') {
        this.messageNotSeeCount = ret['response'];
      //  console.log('messageNotSeeCount', this.messageNotSeeCount);
      } else {
        // console.log('erreur survenu');
      }
    }, error => {
      this.toast.error('Problème de connexion au serveur, veuillez verifier votre connexion internet');
    });
  }

  markMessageAsSee(message) {
    this.messageService.update(message).subscribe(ret => {
      if (ret['status'] === 'OK') {
       // console.log('message lu' + ret['message']);
        this.getNoSeeMessageCount();
      } else {
        // console.log('erreur survenu');
      }
    }, error => {
      this.toast.error('Problème de connexion au serveur, veuillez verifier votre connexion internet');
    });
  }

  saveDocument() {
    this.doc.fileUploadData = this.documentData;
    this.doc.type = 'FORUM';
    this.doc.minier = this.currentUser;
    if(!this.doc.fileUploadData) {
      this.toast.warning("Veuillez ajouter une image avant de publier");
      return;
    }
    this.loading = true;
    // console.log('document', this.doc);
    this.documentService.save(this.doc).subscribe(ret => {
      if (ret['status'] === 'OK') {
       // console.log('doc forum', ret['response']);
        this.loading = false;
        this.toast.info('Document enregistrée avec succès. Veuillez patientez en attendant' +
          ' l\'approbation de votre document par l\'administrateur');
        this.showListDoc();
        this.loadMinerForumDoc();
      } else {
        this.toast.error(ret['message']);
        this.loading = false;
      }
    }, error => {
      // console.log('erreur server');
    });
  }
  updateDocument() {
    this.loading = true;
    this.documentService.update(this.doc).subscribe(ret => {
      if (ret['status'] === 'OK') {
       // console.log('doc forum updated', ret['response']);
        this.loading = false;
        this.toast.info('Modification effectuée avec succès');
        this.showListDoc();
        this.loadMinerForumDoc();
      } else {
        // console.log('erreur survenu');
        this.toast.error(ret['message']);
        this.loading = false;
      }
    }, error => {
      this.toast.info('Problème de connexion internet. Veuillez réessayez s\'il vous plait.');
      this.loading = false;
    });
  }
  loadMinerForumDoc() {
    this.documentService.findDocumentForumByMiner(this.currentUser.id).subscribe(ret => {
      if (ret['status'] === 'OK') {
        this.forumDocs = ret['response'];
       // console.log('list doc forum ', this.forumDocs);
        this.dataSource = new MatTableDataSource(this.forumDocs);
        this.dataSource.paginator = this.forumPaginator;
        this.dataSource.sort = this.forumSort;
      } else {
        // console.log('erreur survenu');
        this.toast.error(ret['message']);
      }
    }, error => {
      // console.log('erreur server');
    });
  }

  saveStage() {
    this.loading = true;
    this.stage.minier = this.currentUser;
    this.stageService.save(this.stage).subscribe(ret => {
      if (ret['status'] === 'OK') {
      //  console.log('stage', ret['response']);
        this.loading = false;
        this.toast.info('Proposition stage enregistrée avec succès');
        this.showListStage();
        this.findStageByMiner();
      } else {
        this.toast.error(ret['message']);
        // console.log('erreur survenu');
        this.loading = false;
      }
    }, error => {
      this.toast.info('Problème de connexion internet. Veuillez réessayez s\'il vous plait.');
      this.loading = false;
    });
  }
  updateStage() {
    this.loading = true;
    this.stageService.update(this.stage).subscribe(ret => {
      if (ret['status'] === 'OK') {
        //console.log('stage', ret['response']);
        this.loading = false;
        this.toast.info('Proposition de stage modifiée avec succès');
        this.showListStage();
      } else {
        // console.log('erreur survenu');
        this.toast.error(ret['message']);
        this.loading = false;
      }
    }, error => {
      this.toast.info('Problème de connexion internet. Veuillez réessayez s\'il vous plait.');
      this.loading = false;
    });
  }

  findStageByMiner() {
    this.stageService.findStageByMiner(this.currentUser.id).subscribe(ret => {
      if (ret['status'] === 'OK') {
        this.stages = ret['response'];
       // console.log('stages', this.stages);
        this.dataSourceStage = new MatTableDataSource(this.stages);
        this.dataSourceStage.paginator = this.stagePaginator;
        this.dataSourceStage.sort = this.stageSort;
      } else {
        // console.log('erreur survenu');
        this.toast.error(ret['message']);
      }
    }, error => {
      this.toast.info('Problème de connexion internet. Veuillez réessayez s\'il vous plait.');
    });
  }

  findPostulantByStage(stageId) {
    this.postulantService.findPostulantByStage(stageId).subscribe(ret => {
      if (ret['status'] === 'OK') {
        this.postulants = ret['response'];
        // console.log('postulants', this.postulants);
      } else {
        // console.log('erreur survenu');
        this.toast.error(ret['message']);
      }
    }, error => {
      this.toast.info('Problème de connexion internet. Veuillez réessayez s\'il vous plait.');
    });
  }

  openDialog() {
    const dialogRef = this.dialog.open(PasswordDialogComponent);

    dialogRef.afterClosed().subscribe(result => {
      // console.log(`Dialog result: ${result}`);
    });
  }

}


@Component({
  selector: 'app-profil',
  templateUrl: './password-dialog.component.html',
})
export class PasswordDialogComponent {
  currentUser: Minier;
  minier: any;
  public model = {oldPassword: '', newPassword: '',  confirmPassword: ''};

  constructor(
    public dateAdapter: DateAdapter<Date>,
    public minierService: MinierService,
    public router: Router,
    public toast: ToastService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<PasswordDialogComponent>) {
this.currentUser = new MineInsidersUtils().getCurrentUser();
this.dateAdapter.setLocale('fr-FR');

}

  reset() {

    if (this.model.newPassword !== this.model.confirmPassword) {
        this.model.confirmPassword = '';
        this.toast.error('Les Mots de passe doivent être Identiques merci de réessayer');
        return;
    }
    this.minierService.reset( this.currentUser.id, this.model.oldPassword, this.model.newPassword).subscribe(ret => {
        if (ret) {
            if (ret['status'] === 'OK') {
                this.toast.success(ret['message']);
                this.dialogRef.close();
                localStorage.removeItem('mine-insiders');
                this.router.navigate(['/login']);
            } else {
                this.toast.error( ret['message']);
            }
        } else {
            this.toast.error( 'Une erreur est survenue lors l\'operation ');
        }
    }), error =>{
        this.toast.error( 'Problème de connexion au serveur, veuillez verifier votre connexion internet');
    }
}

}
