import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {DocumentService} from '../../../core/services/document.service';
import {Documents} from '../../../shared/components/models/documents';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-guide-detail',
  templateUrl: './guide-detail.component.html',
  styleUrls: ['./guide-detail.component.scss']
})
export class GuideDetailComponent implements OnInit {
  loading: boolean = false;
  guideList: Documents[];
  guide: Documents;
  documentImageUri = '';
  page: number = 1;
  totalPages: number;
  isLoaded: boolean = false;
  constructor( private router: Router,
               private route: ActivatedRoute,
               private documentService: DocumentService) {
                this.documentImageUri = environment.documentFileUri;
               }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      if (params.document) {
        this.loading = true;
        this.documentService.findDocumentGuideById(params.document).subscribe(ret => {
          if (ret['status'] === 'OK') {
            this.guide = ret['response'];
            console.log('doc guide', this.guide);
            this.loading = false;
          } else {
            this.loading = false;
            this.router.navigate(['/error']);
          }
        }, error => {
          // console.log('erreur server');
          this.loading = false;
          this.router.navigate(['/error']);
        });
      } else {
        this.router.navigate(['/error']);
      }
    });
    window.scrollTo(0, 0);
  }

  afterLoadComplete(pdfData: any) {
    this.totalPages = pdfData.numPages;
    this.isLoaded = true;
    this.loading = false;
  }

  nextPage() {
    this.page++;
  }

  prevPage() {
    this.page--;
  }

}
