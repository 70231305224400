import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {Minier} from '../../shared/components/models/minier';
import {Pays} from '../../shared/components/models/pays';
import {MinierService} from '../../core/services/minier.service';
import {PaysService} from '../../core/services/pays.service';
import {Domaine} from '../../shared/components/models/domaine';
import {DomaineService} from '../../core/services/domaine.service';
import {EcoleService} from '../../core/services/ecole.service';
import {Ecole} from '../../shared/components/models/ecole';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {Editor} from 'ngx-editor';
declare var $: any;
@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit, AfterViewInit, OnDestroy {
  minier: Minier;
  paysList: Pays[];
  domaines: Domaine[];
  ecoles: Ecole[];
  errorMessage: string = '';
  loading: boolean = false;
  confirmeMinerRegisterModalRef: BsModalRef;
  config = {
    backdrop: true,
    ignoreBackdropClick: true
  };
  editor: Editor;
  editor2: Editor;
  editor3: Editor;
  @ViewChild('templateConfirme', {static: true}) templateConfirme: any;
  @ViewChild('fileInput', {static: true}) fileInput: any;
  profilImageData = null;
  constructor(private modalService: BsModalService,
              private router: Router,
              private minierService: MinierService,
              private paysService: PaysService,
              private domaineService: DomaineService,
              private ecoleService: EcoleService) {
    this.minier = new Minier();
  }

  ngOnInit() {
    this.loadCountriesList();
    this.loadDomaines();
    this.loadEcoles();
    this.editor = new Editor();
    this.editor2 = new Editor();
    this.editor3 = new Editor();
  }

  ngAfterViewInit() {
  }
  // make sure to destory the editor
  ngOnDestroy(): void {
    this.editor.destroy();
    this.editor2.destroy();
    this.editor3.destroy();
  }

  loadCountriesList() {
    this.paysService.loadCountriesList().subscribe(ret => {
      if (ret['status'] === 'OK') {
        this.paysList = ret['response'];
        // console.log('paysList', this.paysList);
      } else {
        // console.log('erreur survenu');
      }
    }, error => {
      // console.log('erreur server');
    });
  }
  saveMiner() {
    this.errorMessage = " ";
    if (this.minier.password !== this.minier.confirmPassword) {
      this.errorMessage = "Les mots de passe ne sont pas identiques!";
      return;
    }
    if (!this.minier.presentation) {
      this.errorMessage = "La presentation est obligatoire!";
      return;
    }
    if (!this.minier.formation) {
      this.errorMessage = "La formation est obligatoire!";
      return;
    }
    if (!this.minier.curriculum) {
      this.errorMessage = "L'experience est obligatoire!";
      return;
    }
    this.minier.profilImageData = this.profilImageData;
    if (this.minier.profilImageData === null) {
      this.errorMessage = "La photo de profil est obligatoire!";
      return;
    }
   //  console.log('minier', this.minier);
    this.loading = true;
    this.minierService.save(this.minier).subscribe(ret => {
      if (ret['status'] === 'OK') {
        this.minier = ret['response'];
        // console.log('minier', this.minier);
        this.loading = false;
        this.minier = new Minier();
        this.confirmeMinerRegisterModalRef = this.modalService.show(this.templateConfirme, this.config);
      } else {
        this.loading = false;
        // console.log( ret['message']);
        this.errorMessage = ret['message'];
      }
    }, error => {
      this.loading = false;
      // console.log('erreur server');
    });
  }

  loadDomaines() {
    this.domaineService.loadDomaineList().subscribe(ret => {
      if (ret['status'] === 'OK') {
        this.domaines = ret['response'];
      } else {
        // console.log('erreur survenu');
      }
    }, error => {
      // console.log('erreur server');
    });
  }
  loadEcoles() {
    this.ecoleService.loadEcoleList().subscribe(ret => {
      if (ret['status'] === 'OK') {
        this.ecoles = ret['response'];
      } else {
        // console.log('erreur survenu');
      }
    }, error => {
      // console.log('erreur server');
    });
  }

  hideRegisterConfirmModal() {
    this.confirmeMinerRegisterModalRef.hide();
    this.router.navigate(['/login']);
  }

  selectPicture() {
    this.fileInput.nativeElement.click();
  }
  deleteImage() {
    this.profilImageData = null;
  }
  processWebImage(event) {
    if (event.target.files.length <= 0) {
      return;
    }
    const reader = new FileReader();
    reader.onload = readerEvent => {
      const imageData = (readerEvent.target as any).result;
      let imageSize = Number(event.target.files[0].size);
      imageSize = imageSize / 1024;
      if (imageSize > 1024 * 1024 * 3) {
        // this.toast.info("la taille de l'image depasse 3 Mo");
      } else {
        this.profilImageData = imageData;
        // console.log('imgData', imageData);
      }
    };
    reader.readAsDataURL(event.target.files[0]);
    // this.readFile(event.target.files[0])
    event.stopPropagation();
  }
}
