import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {NewsService} from '../../core/services/news.service';
import {News} from '../../shared/components/models/news';
import {environment} from '../../../environments/environment';
import {LoadingBarService} from '@ngx-loading-bar/core';
import {OpportuniteService} from '../../core/services/opportunite.service';
import {Opportunite} from '../../shared/components/models/opportunite';
import {Publicite} from '../../shared/components/models/publicite';
@Component({
  selector: 'app-articles',
  templateUrl: './articles.component.html',
  styleUrls: ['./articles.component.css']
})
export class ArticlesComponent implements OnInit {
  list: boolean = true;
  detail: boolean = false;
  loading: boolean = false;
  newses: News[];
  jobList: Opportunite[] = [];
  news: News;
  newsImageUri = '';
  p: number = 1;
  publicites: Publicite[] = [];
  publiciteImageUri: string;
  otherNews: News[] = [];
  constructor(private router: Router,
              private route: ActivatedRoute,
              private newsService: NewsService,
              private loadingBar: LoadingBarService,
              private opportuniteService: OpportuniteService) {
    this.newsImageUri = environment.newsImageUri;
    this.publiciteImageUri = environment.publiciteImageUri;
  }
  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      if (params.news) {
        this.loadingBar.start();
        this.newsService.findNewsById(params.news).subscribe(ret => {
          if (ret['status'] === 'OK') {
            this.news = ret['response'];
            // console.log('news', this.news);
            this.loadingBar.complete();
            this.loadOthersNews(params.news);
          } else {
            this.loadingBar.complete();
            this.router.navigate(['/error']);
          }
        }, error => {
          // console.log('erreur server');
          this.loadingBar.complete();
          this.router.navigate(['/error']);
        });
      } else {
        this.loadNews();
        this.news = null;
      }
    });
    window.scrollTo(0, 0);
  }
  showDetail(news: News) {
    this.router.navigate(['/mining-news'] ,{ queryParams: {news: news.id} }).
    then(() => {
      window.location.reload();
    });
  }
  showList() {
    this.news = null;
    this.router.navigate(['/mining-news']);
  }
  loadNews() {
    this.loadingBar.start();
    this.newsService.findAllNews().subscribe(ret => {
      if (ret['status'] === 'OK') {
        this.newses = ret['response'];
        this.loadingBar.complete();
      } else {
        // console.log('erreur survenu');
        this.loadingBar.complete();
      }
    }, error => {
      // console.log('erreur server');
      this.loadingBar.complete();
    });
  }

  loadOthersNews(newsId) {
    this.newsService.findOthers(newsId).subscribe(ret => {
      if (ret['status'] === 'OK') {
        this.otherNews = ret['response'];
      } else {
        // console.log('erreur survenu');
      }
    }, error => {
      // console.log('erreur server');
    });
  }
}
