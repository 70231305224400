import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {MinierService} from '../../core/services/minier.service';
import {AuthService} from '../../core/services/auth.service';
import {ToastService} from '../../shared/components/utils/toast.service';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
declare var $: any;
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, AfterViewInit  {
  loginModel = {email: '', password: ''};
  errorMessage = '';
  loading: boolean = false;
  isLogin: boolean = false;
  email: string;
  confirmeMinerRegisterModalRef: BsModalRef;
  config = {
    backdrop: true,
    ignoreBackdropClick: true
  };
  @ViewChild('templateConfirme', {static: true}) templateConfirme: any;
  constructor(private router: Router,
              private minierService: MinierService,
              private authService: AuthService,
              private toast: ToastService,
              private modalService: BsModalService) { }

  ngOnInit() {
    this.isLogin = true;
    window.scrollTo(0, 0);
  }
  ngAfterViewInit() {
    // loading templates js after dom render
  }
  login() {
    // console.log('loginModel', this.loginModel);
    this.loading = true;
    this.authService.login(this.loginModel).subscribe(ret => {
      if (ret['status'] === 'OK') {
        // console.log('minier', ret['response']);
        localStorage.setItem('mine-insiders', JSON.stringify(ret['response']));
        this.loading = false;
        this.router.navigate(['/profil'])
          .then(() => {
            window.location.reload();
          });
      } else {
        // console.log('erreur survenu');
        this.loading = false;
        this.errorMessage = ret['message'];
      }
    }, error => {
      // console.log('erreur server');
      this.loading = false;
    });
  }
  showLogin() {
    this.isLogin = true;
  }
  showForgetPassword() {
    this.isLogin = false;
  }
  resetPassword() {
    this.loading = true;
    this.minierService.resetMinerPassword(this.email).subscribe(ret => {
      if (ret['status'] === 'OK') {
        this.loading = false;
        this.confirmeMinerRegisterModalRef = this.modalService.show(this.templateConfirme, this.config);
        this.email = '';
      } else {
        this.loading = false;
        this.toast.error(ret['message']);
      }
    }, error => {
      // console.log('erreur server');
      this.loading = false;
      this.toast.error('Problème de connexion au serveur');
    });
  }
  hideRegisterConfirmModal() {
    this.confirmeMinerRegisterModalRef.hide();
    this.showLogin();
  }
}
