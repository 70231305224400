import {Deserializable} from './deserializable';
import { Injectable } from '@angular/core';
import { Utilisateur } from './utilisateur';
import { File } from './file';
import {Minier} from './minier';
import {Commentaire} from './commentaire';

@Injectable()
export class Documents implements Deserializable<Documents> {
  public id: number;
  public nom: string;
  public description: string;
  public fileUploadName: string;
  public dateCreation: any;
  public dateModification?: any;
  public enabled: boolean;
  public type: string;
  public minier: Minier;
  public fileUploadData: string;
  public commentaires: Commentaire[];
  public commentCount: number;
  deserialize(input: any): Documents {
    Object.assign(this, input);
    return this;
  }
}
