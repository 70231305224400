import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {LoadingBarService} from '@ngx-loading-bar/core';
import {environment} from '../../../environments/environment';
import {Opportunite} from '../../shared/components/models/opportunite';
import {NewsService} from '../../core/services/news.service';
import {OpportuniteService} from '../../core/services/opportunite.service';
import {News} from '../../shared/components/models/news';
import {Events} from '../../shared/components/models/events';
import {EventsService} from '../../core/services/events.service';

@Component({
  selector: 'app-mining-events',
  templateUrl: './mining-events.component.html',
  styleUrls: ['./mining-events.component.scss']
})
export class MiningEventsComponent implements OnInit {
  list: boolean = true;
  detail: boolean = false;
  loading: boolean = false;
  newses: News[] = [];
  jobList: Opportunite[] = [];
  news: News;
  events: Events;
  eventList: Events[];
  newsImageUri = '';
  eventsImageUri = '';
  p: number = 1;
  otherEvents: Events[] = [];
  constructor(private router: Router,
              private route: ActivatedRoute,
              private newsService: NewsService,
              private loadingBar: LoadingBarService,
              private opportuniteService: OpportuniteService,
              private eventService: EventsService) {
    this.newsImageUri = environment.newsImageUri;
    this.eventsImageUri = environment.eventsImageUri;
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      if (params.event) {
        this.loadingBar.start();
        this.eventService.findEventById(params.event).subscribe(ret => {
          if (ret['status'] === 'OK') {
            this.events = ret['response'];
            // console.log('event', this.events);
            this.loadingBar.complete();
            this.loadOthersEvents(params.event);
          } else {
            this.loadingBar.complete();
            this.router.navigate(['/error']);
          }
        }, error => {
          console.log('erreur server');
          this.loadingBar.complete();
          this.router.navigate(['/error']);
        });
      } else {
        this.loadEvents();
        this.news = null;
      }
    });
    window.scrollTo(0, 0);
  }
  showDetail(event: Events) {
    this.router.navigate(['/mining-events'] ,{ queryParams: {event: event.id} }).
    then(() => {
      window.location.reload();
    });
  }
  showList() {
    this.events = null;
    this.router.navigate(['/mining-events']);
  }
  loadEvents() {
    this.loadingBar.start();
    this.eventService.findAllEnabledEvents().subscribe(ret => {
      if (ret['status'] === 'OK') {
        this.eventList = ret['response'];
        this.loadingBar.complete();
      } else {
        console.log('erreur survenu');
        this.loadingBar.complete();
      }
    }, error => {
      console.log('erreur server');
      this.loadingBar.complete();
    });
  }
  loadNews() {
    this.newsService.findAllNews().subscribe(ret => {
      if (ret['status'] === 'OK') {
        this.newses = ret['response'];
      } else {
        console.log('erreur survenu');
      }
    }, error => {
      console.log('erreur server');
    });
  }

  loadLatestJobs() {
    this.opportuniteService.findAllOpportunite().subscribe(ret => {
      if (ret['status'] === 'OK') {
        this.jobList = ret['response'];
        console.log('+++++ list Job ', this.jobList);
      } else {
        console.log('erreur survenu');
      }
    }, error => {
      console.log('erreur server');
    });
  }

  loadOthersEvents(eventId) {
    this.eventService. findOthers(eventId).subscribe(ret => {
      if (ret['status'] === 'OK') {
        this.otherEvents = ret['response'];
      } else {
        console.log('erreur survenu');
      }
    }, error => {
      console.log('erreur server');
    });
  }

}
