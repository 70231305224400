import {Deserializable} from './deserializable';
import { Injectable } from '@angular/core';
import {Stage} from './stage';
@Injectable()
export class Postulant implements Deserializable<Postulant> {
  public id: number;
  public nom: string;
  public email: string;
  public prenom: string;
  public fileName: string;
  public fileData: string;
  public dateCreation: string;
  public dateModification: string;
  public enabled: boolean = true;
  public stage: Stage;

  deserialize(input: any): Postulant {
    Object.assign(this, input);
    return this;
  }
}
