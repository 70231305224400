import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { environment } from 'src/environments/environment';
import { Opportunite } from 'src/app/shared/components/models/opportunite';
import { OpportuniteService } from 'src/app/core/services/opportunite.service';
import {LoadingBarService} from '@ngx-loading-bar/core';
import {News} from '../../../shared/components/models/news';
import {Publicite} from '../../../shared/components/models/publicite';
import {PubliciteService} from '../../../core/services/publicite.service';

@Component({
  selector: 'app-jobs-detail',
  templateUrl: './jobs-detail.component.html',
  styleUrls: ['./jobs-detail.component.scss']
})
export class JobsDetailComponent implements OnInit {
  jobList: Opportunite[];
  job: Opportunite;
  newses: News[] = [];
  newsImageUri = '';
  loading: boolean = false;
  opportuniteImageUri: any;
  publicites: Publicite[] = [];
  publiciteImageUri: string;
  otherNews: News[] = [];
  otherJobs: Opportunite[] = [];
  constructor(private router: Router,private route: ActivatedRoute,
              private opportuniteService: OpportuniteService,
              private loadingBar: LoadingBarService,
              private publiciteService: PubliciteService) {
    this.opportuniteImageUri = environment.opportuniteImageUri;
    this.newsImageUri = environment.newsImageUri;
    this.publiciteImageUri = environment.publiciteImageUri;
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      if (params.opportunite) {
        this.loadingBar.start();
        this.opportuniteService.findOpportuniteJobsById(params.opportunite).subscribe(ret => {
          if (ret['status'] === 'OK') {
            this.job = ret['response'];
           // console.log('list jobs', this.job);
            this.loadingBar.complete();
            this.loadOthersJobs(params.opportunite);
          } else {
            this.loadingBar.complete();
            this.router.navigate(['/error']);
          }
        }, error => {
          // console.log('erreur server');
          this.loadingBar.complete();
          this.router.navigate(['/error']);
        });
      } else {
        this.router.navigate(['/error']);
      }
    });
    window.scrollTo(0, 0);
  }

  showList() {
    this.router.navigate(['/jobs']);
  }
  loadOthersJobs(jobId) {
    this.opportuniteService.findOthers(jobId).subscribe(ret => {
      if (ret['status'] === 'OK') {
        this.otherJobs = ret['response'];
      } else {
        // console.log('erreur survenu');
      }
    }, error => {
      // console.log('erreur server');
    });
  }

}
