<!-------------HEADER-------------->
<!-- <app-header></app-header> -->
<!-------------HEADER-------------->
<!-- #about -->
<section id="about" class="wow fadeInUp">
  <div class="container">
    <div class="section-header">
      <h2>Mention legale</h2>

    </div>
    <div class="row">
      <div class="col-lg-12 content">

        <h2> <strong style="font-size:17px;">ÉDITEUR DU SITE</strong> </h2>
         <p>
         Éditeur et responsable de publication : Amadou N’DIAYE
          Adresse : Richmond Avenue, Houston TX 77082, Texas, United States – S/C Dahirou N’Diaye
          Numéro d’identification : endossé par la société Rapid Services SARL – Bamako / Faladie Sema
         </p>

          <h2> <strong style="font-size:17px;"> Contacts</strong> </h2>
          Tél : +223 65 12 85 89
          Adresse e-mail : contact@mineinsiders.com

         <h2> <strong style="font-size:17px;">HÉBERGEMENT</strong> </h2>
          Le site est hébergé par : <a href="https://ngsystem.net">Ngsystem Technology</a>

         <h2> <strong style="font-size:17px;">DONNÉES PERSONNELLES</strong> </h2>
         <p>
          Les informations recueillies sur le site web dans le formulaire d’inscription que vous complétez et dans le formulaire contact que vous utilisez pour correspondre avec nos services font l’objet d’un traitement informatique destiné à MineInsiders. MineInsiders est le seul et unique destinataire de ces informations.
          Conformément à la loi « informatique et libertés » du 6 janvier 1978 modifiée, vous disposez d’un droit d’accès et de rectification aux informations qui vous concernent. Vous pouvez accéder aux informations vous concernant en nous contactant ici.
          Vous pouvez également, pour des motifs légitimes, vous opposer au traitement des données vous concernant.
        </p>
      </div>
    </div>
    <div class="row"></div>
  </div>
</section>
<!-- #about -->
<!-------------HEADER-------------->
<!-- <app-footer></app-footer> -->
<!-------------HEADER-------------->
