import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class DocumentService {

  private serviceUrl: string;
  private httpOptions: any;


  constructor(private http: HttpClient) {

    this.serviceUrl = environment.apiUrl;
  }

  public findAllDocument() {
    return this.http.get(this.serviceUrl + '/document' );
  }
  public findDocumentByForum(){
    //console.log('*******object*****' + JSON.stringify(documentcode));
    return this.http.get(this.serviceUrl + '/document/forum/enabled');
  }
  public findDocumentByCode(){
    //console.log('*******object*****' + JSON.stringify(documentcode));
    return this.http.get(this.serviceUrl + '/document/code/enabled');
  }
  public findDocumentCodeById(codeId) {
    return this.http.get(this.serviceUrl + '/document/code/' + codeId);
  }
  public findDocumentGuideById(guideId) {
    return this.http.get(this.serviceUrl + '/document/guide/' + guideId);
  }
  public findDocumentByGuide(){
    //console.log('*******object*****' + JSON.stringify(documentcode));
    return this.http.get(this.serviceUrl + '/document/guide/enabled');
  }

  public findDocumentForumByMiner(minerId) {
    return this.http.get(this.serviceUrl + '/document/forum/minier/' + minerId);
  }

  public findDocumentForumById(docId) {
    return this.http.get(this.serviceUrl + '/document/forum/' + docId);
  }

  public save(document) {
    return this.http.post(this.serviceUrl + '/document/', document, this.httpOptions);
  }

  public update(document) {
    return this.http.put(this.serviceUrl + '/document/' , document, this.httpOptions);
  }

  public desactivate(documentId) {
    return this.http.put(this.serviceUrl + '/document/desactivate/' + documentId, this.httpOptions);
  }

  public activate(documentId) {
    return this.http.put(this.serviceUrl + '/document/activate/'  + documentId, this.httpOptions);
  }
}
