<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '5px',fullScreenBackdrop:true }"></ngx-loading>

<!-- POSTULER DIALOG-->
<ng-template #postuleTemplate>
  <div class="p-2" style="width: 100%">
    <button type="button" class="close pull-right" aria-label="Close" (click)="postuleDialog.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <mat-divider></mat-divider>
  <div class="modal-body">
    <div class="text-center mb-2" style="color: #000203; background: #ffffff;">
      <div class="mt-2">
        <form #cvForm="ngForm" name="form">
          <div class="modal-body">
            <div class="text-center mb-2" style="color: #000102; background: #ffffff;">
              <div class="mt-2">
                <mat-form-field style="width: 100%">
                  <input matInput  name="nom" [(ngModel)]="postulant.nom" placeholder="Nom" required>
                </mat-form-field>
                <br>
                <mat-form-field style="width: 100%">
                  <input matInput  name="prenom" [(ngModel)]="postulant.prenom" placeholder="prenom" required>
                </mat-form-field>
                <br>
                <mat-form-field style="width: 100%">
                  <input matInput  name="email" [(ngModel)]="postulant.email" placeholder="Email" required>
                </mat-form-field>
                <br>
                <label for="files" class="btn" style="color: #007ef0">Ajouter votre CV</label>
                <input type="file" id="files" placeholder="Ajouter votre Cv"  name="file" (change)="processUploadCv($event)"/>
              </div>
              <br>
              <div class="alert alert-danger mt-2" *ngIf="errorMessage">
                {{errorMessage}}
              </div>
            </div>
            <br>
            <mat-divider></mat-divider>
            <div class="row mt-4">
              <div class="col-12 text-center">
                <button style="color: #fff; background: #007ef0" [disabled]="!cvForm.form.valid" class="btn" (click)="postuler()">Envoyer</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</ng-template>
<!-- POSTULER DIALOG-->

<section id="team" class="wow fadeInUp">
  <div class="container">
    <div class="section-header">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb" *ngIf="!stage">
          <li class="breadcrumb-item"><a href="#">Accueil</a></li>
          <li class="breadcrumb-item active" aria-current="page">Propositions de stage des professionnels miniers</li>
        </ol>
        <ol class="breadcrumb" *ngIf="stage">
          <li class="breadcrumb-item"><a href="#">Accueil</a></li>
          <li class="breadcrumb-item"><a href="javascript:void(0)" (click)="showList()">Propositions de stage des professionnels miniers</a></li>
          <li class="breadcrumb-item active" aria-current="page">{{stage?.titre}}</li>
        </ol>
      </nav>
    </div>
    <div class="row">
      <div class="container" *ngIf="!stage">
        <h4 style="font-size: x-large;color: #007cdc;text-align: center" *ngIf="stages?.length==0">
          Aucune proposition de stage publiée
        </h4>
        <div class="card card-body bg-light" style="margin-bottom: 10px" *ngFor="let item of stages | paginate: { itemsPerPage: 20, currentPage: p }">
          <div class="row" (click)="showDetail(item)" style="cursor: pointer">
            <div class="col-sm-12 col-lg-8 col-md-8 col-xl-8 col-12">
                <a href="javascript:void(0)" style="color: black">
                  <h4 style="font-size: x-large;color: #007cdc">{{item.nom}}</h4>
                </a>
                <p [innerHtml]="item.description | slice:0:500">
                </p>
                <div class="row">
                  <div class="col-sm-6 col-lg-6 col-md-6 col-6 col-xl-6">
                    <span style="color: #959698">Publié le   {{item.dateCreation | date : 'medium'}}</span>
                  </div>
                  <div class="col-sm-6 col-lg-6 col-md-6 col-6 col-xl-6">
                    Par : <span style="color: #959698"><a href="javascript:void(0)" (click)="showDetailsMiniers(item.minier)"> {{item.minier?.prenom}} {{item.minier?.nom}} </a> </span>
                  </div>
                </div>
            </div>
          </div>
        </div>
        <pagination-controls (pageChange)="p = $event"
                             directionLinks="true"
                             autoHide="true"
                             responsive="true"
                             previousLabel="Précédent"
                             nextLabel="Suivant"
                             screenReaderPaginationLabel="Pagination"
                             screenReaderPageLabel="page"
                             screenReaderCurrentLabel="Vous êtes dejà sur la page">
        </pagination-controls>
      </div>

      <div class="container" *ngIf="stage">
        <div class="row">
          <div class="col-lg-12" style="margin-left: 20px;margin-right: 20px">
            <!-- the actual blog post: title/author/date/content -->
            <h1>
              <a href="">{{stage?.titre}}</a>
            </h1>
            <hr>
            <p><i class="fa fa-calendar"></i> Publié le {{stage.datePublication | date: 'medium'}}</p>
            <p class="lead"><i class="fa fa-user"></i> Par <a href="javascript:void(0)" (click)="showDetailsMiniers(stage.minier)"> {{stage?.minier?.prenom}} {{stage?.minier?.nom}}</a>
            <hr>
            <p [innerHtml]="stage?.description"></p>
            <hr>
            <br/>
            <div class="row">
              <button class="btn btn-primary" style="margin-bottom: 20px" (click)="showPostuleDialog(postuleTemplate)">Postuler</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- #team -->
