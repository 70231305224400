import { Component, OnInit } from '@angular/core';
import {StageService} from '../../../core/services/stage.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Stage} from '../../../shared/components/models/stage';
import { environment } from 'src/environments/environment';
import { OpportuniteService } from 'src/app/core/services/opportunite.service';
import { Opportunite } from 'src/app/shared/components/models/opportunite';
import {LoadingBarService} from '@ngx-loading-bar/core';

@Component({
  selector: 'app-stages',
  templateUrl: './stages.component.html',
  styleUrls: ['./stages.component.scss']
})
export class StagesComponent implements OnInit {
  stageList: Opportunite[];
  loading: boolean = false;
  p: number = 1;
  opportuniteImageUri: any;
  constructor(private router: Router,
              private opportuniteService: OpportuniteService,
              private stageService: StageService,
              private loadingBar: LoadingBarService) {
    this.opportuniteImageUri = environment.opportuniteImageUri;
  }

  ngOnInit() {
    this.loadstageList();
    window.scrollTo(0, 0);
  }
  showStagesDetails(stage: Opportunite) {
    this.router.navigate(['/stages-detail'] ,{ queryParams: {opportunite: stage.id} });
  }
  loadstageList() {
    this.loadingBar.start();
    this.stageService.findAllEnabled().subscribe(ret => {
      if (ret['status'] === 'OK') {
        this.stageList = ret['response'];
       // console.log('+++++ list stage ', this.stageList);
        this.loadingBar.complete();
      } else {
        // console.log('erreur survenu');
        this.loadingBar.complete();
      }
    }, error => {
      // console.log('erreur server');
      this.loadingBar.complete();
    });
  }
}
