<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '5px',fullScreenBackdrop:true }"></ngx-loading>

<!-- CONFIRM MINER REGISTER DIALOG-->
<ng-template #templateConfirme>
  <div class="p-2" style="width: 100%">
    <button type="button" class="close pull-right" aria-label="Close" (click)="hideRegisterConfirmModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <mat-divider></mat-divider>
  <div class="modal-body">
    <div class="text-center mb-2" style="color: #007ef0; background: #ffffff;">
      <p>Merci de votre inscription sur le réseau MineInsiders.</p>
      <p>Un email de confirmation vous a été envoyé contenant le reste de la precédure à suivre pour complèter votre inscription.</p>
    </div>
    <mat-divider></mat-divider>
    <div class="row mt-4">
      <div class="col-12 text-center">
        <button style="color: #fff; background: #007ef0" (click)="hideRegisterConfirmModal()" class="btn">OK</button>
      </div>
    </div>
  </div>
</ng-template>


<section id="registrer" class="wow fadeInUp">
  <div class="container">
    <input type="file" #fileInput style="visibility: hidden; height: 0px" name="file"
           (change)="processWebImage($event)"/>
    <div class="section-header">
      <h2>Inscription</h2>
    </div>
    <div>
      <h3 style="font-family: Montserrat,sans-serif;font-weight: 500;margin: 0 0 20px;">Vous êtes professionels, consultants ou formateurs dans le domaines des mines inscrivez vous gratuitement pour gagner en visibilité.</h3>
    </div>
    <div class="row justify-content-center" style="margin-bottom: 40px">
      <div class="col-md-8">
        <div class="card">
          <header class="card-header">
            <a href="#" routerLink="/login" style="background-color: #007cdc;color: #FFFFFF" class="float-right btn btn-outline-primary mt-1">Se Connecter</a>
            <h4 class="card-title mt-2">S'inscrire</h4>
          </header>
          <article class="card-body">
            <form #registerForm="ngForm" name="form">
              <div class="form-row">
                <div class="col form-group">
                  <label>Nom <span style="color: red">*</span></label>
                  <input type="text" name="nom" [(ngModel)]="minier.nom" class="form-control" placeholder="" required>
                </div> <!-- form-group end.// -->
                <div class="col form-group">
                  <label>Prenom <span style="color: red">*</span></label>
                  <input type="text" name="prenom" [(ngModel)]="minier.prenom"  class="form-control" placeholder=" " required>
                </div> <!-- form-group end.// -->
              </div> <!-- form-row end.// -->

              <div class="form-row">
                <div class="col form-group">
                  <label>Spécialité :<span style="color: red">*</span></label>
                  <input type="text" name="specialite" [(ngModel)]="minier.specialite" class="form-control" placeholder="" required>
                </div> <!-- form-group end.// -->
                <div class="form-group col-md-6">
                    <label for="ecole">Université d'obtention du dernier diplôme </label>
                    <select id="ecole" class="form-control" name="ecole" [(ngModel)]="minier.ecole" style="height: 34px" required>
                      <option> Choose...</option>
                      <option *ngFor="let ecole of ecoles" [ngValue]="ecole">{{ecole?.nom}}</option>
                    </select>
                </div> <!-- form-group end.// -->
              </div>

              <div class="form-row">
                <div class="form-group col-md-6">
                  <label>Pays : <span style="color: red">*</span></label>
                  <select id="inputState" class="form-control" name="pays" [(ngModel)]="minier.pays" style="height: 34px" required>
                    <option> Choose...</option>
                    <option *ngFor="let pays of paysList" [ngValue]="pays">{{pays?.nom}}</option>
                  </select>
                </div> <!-- form-group end.// -->
                <div class="form-group col-md-6">
                  <label>Ville <span style="color: red">*</span></label>
                  <input type="text" name="ville" [(ngModel)]="minier.ville" class="form-control" required>
                </div> <!-- form-group end.// -->
              </div> <!-- form-row.// -->
              <div class="form-row">
                <div class="form-group col-md-6">
                  <label>Adresse Email <span style="color: red">*</span> </label>
                  <input type="email" name="email" [(ngModel)]="minier.email" class="form-control" placeholder="" required>
                </div> <!-- form-group end.// -->
                <div class="form-group col-md-6">
                  <label>Téléphone <span style="color: red">*</span></label>
                  <input type="number" name="telephone" [(ngModel)]="minier.telephone" class="form-control" placeholder="" required>
                </div>
              </div>

              <div class="form-row">
                <div class="form-group col-md-12">
                  <mat-form-field style="width: 100%">
                    <mat-select id="domaine"  placeholder="Vos domaines d'expertise (multiple choix)" name="domaine" [(ngModel)]="minier.domaines"
                                multiple required> 

                      <mat-option *ngFor="let domaine of domaines" [value]="domaine">
                        {{domaine?.nom}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>

              <div class="form-row">
                <div class="col-12 form-group">
                  <label>Présentation <span style="color: red">*</span></label>
                  <div class="NgxEditor__Wrapper">
                    <ngx-editor-menu [editor]="editor3"> </ngx-editor-menu>
                    <ngx-editor
                      name="presentation"
                      outputFormat ="html"
                      [spellcheck]="true"
                      [editor]="editor3"
                      [(ngModel)]="minier.presentation"
                      placeholder="Présenter vous brièvement">
                    </ngx-editor>
                  </div>
                </div>
              </div>

              <div class="form-row">
                <div class="col-12 form-group">
                  <label>Formation : <span style="color: red">*</span></label>
                  <div class="NgxEditor__Wrapper">
                    <ngx-editor-menu [editor]="editor2"> </ngx-editor-menu>
                    <ngx-editor
                      name="formation"
                      outputFormat="html"
                      [spellcheck]="true"
                      [editor]="editor2"
                      [(ngModel)]="minier.formation"
                      placeholder="Votre Formation">
                    </ngx-editor>
                  </div>
                </div>
              </div>

              <div class="form-row">
                <div class="col-12 form-group">
                  <label>Experiences : <span style="color: red">*</span></label>
                  <div class="NgxEditor__Wrapper">
                    <ngx-editor-menu [editor]="editor"> </ngx-editor-menu>
                    <ngx-editor
                      name="curriculum"
                      outputFormat="html"
                      [spellcheck]="true"
                      [editor]="editor"
                      [(ngModel)]="minier.curriculum"
                      placeholder="Vos Experiences">
                    </ngx-editor>
                  </div>
                </div>
              </div>

              <div class="form-row">
                <div class="form-group col-md-6 col-sm-6 col-6">
                  <label for="pass">Mot de passe <span style="color: red">*</span></label>
                  <input id="pass" name="password" [(ngModel)]="minier.password" class="form-control" type="password" required>
                </div>
                <div class="form-group col-md-6 col-sm-6 col-6">
                  <label for="confpass">Confirmer <span style="color: red">*</span></label>
                  <input class="form-control" name="confirmPassword" [(ngModel)]="minier.confirmPassword" id="confpass" type="password" required>
                </div>
              </div>
              <!-- form-group end.// -->
              <div class="form-row">
                <div class="form-group">
                  <label>Êtes-vous : <span style="color: red">*</span></label>
                  <br>
                  <br>
                  <mat-radio-group  name="type" [(ngModel)]="minier.type" required>
                    <mat-radio-button value="PROFESSIONNEL" class="radio-btn-margin">Un professionnel des mines (Géologie, exploration, Construction, production, usine)
                    </mat-radio-button>
                    <br>
                    <br>
                    <mat-radio-button value="CONSULTANT" class="radio-btn-margin">Un consultant des mines</mat-radio-button> <br> <br>
                    <mat-radio-button value="FORMATEUR" class="radio-btn-margin">Un formateur technique particulier des mines</mat-radio-button>
                  </mat-radio-group>
                </div>
              </div>
              <hr>
              <div class="form-row">
                <div class="col-12 form-group">
                  <div class="mt-2">
                    <button class="btn btn-primary" (click)="selectPicture()" type="button">
                      <i class="fa fa-fw fa-camera"></i>
                      Ajouter photo de profil
                    </button>
                    <br>
                    <div class="mx-auto" style="width: 90px;" *ngIf="profilImageData">
                      <span><i style="color: #b21f2d;font-size: 20px;cursor: pointer" class="fa fa-times" (click)="deleteImage()"></i></span>
                      <div class="d-flex justify-content-center align-items-center rounded" [style.backgroundImage]="'url('+profilImageData+')'" style="height: 140px;width: 140px; background-position: center; background-color: rgb(233, 236, 239); background-size: cover;background-repeat: no-repeat;" >
                        <span></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr>
              <br>
              <span style="color: #b21f2d">
                {{errorMessage}}
              </span>
              <br>
              <div class="form-group">
                <button type="submit" class="btn btn-primary btn-block" [disabled]="!registerForm.form.valid" (click)="saveMiner()"> Enregistrer  </button>
              </div> <!-- form-group// -->
              <small class="text-muted">En cliquant sur le bouton "Enregistrer", vous confirmez que vous acceptez nos conditions d'utilisation et notre politique de confidentialité.
                <a href="javascript:void(0)" routerLink="/condition-generale" style="color: blue;"> cliquer ici pour lire les conditions</a>
              </small>
              <div class="pull-right">
                <span style="color: red;font-size: small">* les champs obligatoires</span>
              </div>
            </form>
          </article> <!-- card-body end .// -->
        </div> <!-- card.// -->
      </div> <!-- col.//-->
    </div> <!-- row.//-->
  </div>
</section>
