import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import { DocumentService } from 'src/app/core/services/document.service';
import { environment } from 'src/environments/environment';
import { Documents } from 'src/app/shared/components/models/documents';

@Component({
  selector: 'app-code-minier',
  templateUrl: './code-minier.component.html',
  styleUrls: ['./code-minier.component.scss']
})
export class CodeMinierComponent implements OnInit {
  loadCodeListe: Documents[];
  documentImageUri = '';
  loading: boolean = false;
  p: number = 1;
  constructor(private router: Router,
    private documentService: DocumentService) {
    this.documentImageUri = environment.documentImageUri;
  }

  ngOnInit() {
    this.loadCodeList();
    window.scrollTo(0, 0);
  }

  showDetails(codes: Documents) {
    this.router.navigate(['/document-detail'] ,{ queryParams: {document: codes.id} });
  }
  loadCodeList() {
    this.loading = true;
    this.documentService.findDocumentByCode().subscribe(ret => {
      if (ret['status'] === 'OK') {
        this.loadCodeListe = ret['response'];
     // console.log('list doc code ', this.loadCodeListe);
        this.loading = false;
      } else {
       // console.log('erreur survenu');
        this.loading = false;
      }
    }, error => {
     // console.log('erreur server');
      this.loading = false;
    });
  }
}
