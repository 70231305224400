import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-condition-generale',
  templateUrl: './condition-generale.component.html',
  styleUrls: ['./condition-generale.component.css']
})
export class ConditionGeneraleComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
