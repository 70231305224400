<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '5px',fullScreenBackdrop:true }"></ngx-loading>


<section id="team" class="wow fadeInUp" style="margin-bottom: 20px">

  <div class="container">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a href="#">Acceuil</a></li>
        <li class="breadcrumb-item"><a href="#">documents</a></li>
        <li class="breadcrumb-item active" aria-current="page">{{guide?.nom}}</li>
      </ol>
    </nav>
  </div>

  <div class="container">
    <!-- Main container -->
    <div class="container">

      <!--details documents-->
      <div class="row">
        <div class="col-md-12">
          <div class="card card-block" style="padding: 20px">
            <h2 style="padding: 20px;color: #1a68ff">{{guide?.nom}}</h2>
            <p style="width: 50%" [innerHtml]="guide?.description"></p>
            <br>
            <a matTooltip="Télécharger le document" [href]="documentImageUri+guide?.fileUploadName" target="_blank">
              <i style="color:blue;font-size: 20px;margin-left: 20px" class="fa fa-file-pdf-o"></i> Télécharger
            </a>
            <hr>
            <div class="col-sm-12">
              <div style="text-align: center" *ngIf="loading">
                Veuillez patientez s'il vous plait .....
              </div>
              <pdf-viewer [src]="documentImageUri+guide?.fileUploadName"
                          [render-text]="true"
                          style="display: block;"
                          [show-all]="false"
                          [page]="page"
                          [autoresize]="true"
                          (after-load-complete)="afterLoadComplete($event)"
              >
              </pdf-viewer>
              <br>
              <br>
              <div *ngIf="isLoaded" style="text-align: center;">
                <button class="btn btn-sm btn-primary" (click)="prevPage()" [disabled]="page === 1">Précedent</button>
                <span style="margin-left: 10px;margin-right: 10px">{{ page }} / {{ totalPages }}</span>
                <button class="btn btn-sm btn-primary" (click)="nextPage()" [disabled]="page === totalPages">Suivant</button>
                <input style="width: 100px;margin-left: 10px" placeholder="Aller à" [(ngModel)]="page" name="page" type="number" [min]="1" [max]="totalPages" required>
              </div>
              <br>
              <br>
            </div>
          </div>
        </div>
      </div>
      <!-- End :details profiles -->

    </div> <!--End of Container-->
  </div>
</section>
<!-- #team -->

