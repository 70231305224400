
<section id="portfolio" class="wow fadeInUp">
    <div class="container">
        <div class="section-header">
          <h2>Guide pour obtenir un permis de recherche ou d’exploitation minière au sein de l’UEMOA</h2>
        </div>
    </div>

    <div class="container-fluid">
      <div class="row">
        <div class="col-md-3 col-lg-3 col-3 col-sm-3">

        </div>
        <div class="col-md-9 col-lg-9 col-9 col-sm-9">
          <mat-list>
            <mat-list-item>
              <mat-icon mat-list-icon>file_copy</mat-icon>
              <a href="#" (click)="showDetails()" style="color: #959598" mat-line>Comment obtenir un permis d’exploration ou d’exploitation minière au sein de l’UEMOA
              </a>
            </mat-list-item>

            <mat-list-item>
              <mat-icon mat-list-icon>file_copy</mat-icon>
              <a href="#" (click)="showDetails()" style="color: #959598" mat-line>Le code minier des pays de l’UEMOA
              </a>
            </mat-list-item>

            <mat-list-item>
              <mat-icon mat-list-icon>file_copy</mat-icon>
              <a href="#" (click)="showDetails()" style="color: #959598" mat-line>La politique minière au sein de l’UEMOA
              </a>
            </mat-list-item>

            <mat-list-item>
              <mat-icon mat-list-icon>file_copy</mat-icon>
              <a href="#" (click)="showDetails()" style="color: #959598" mat-line>Le registre des consultants MineInsiders
              </a>
            </mat-list-item>

            <mat-list-item>
              <mat-icon mat-list-icon>file_copy</mat-icon>
              <a href="#" (click)="showDetails()" style="color: #959598" mat-line>Comment obtenir un permis d’exploration ou d’exploitation minière au sein de l’UEMOA
              </a>
            </mat-list-item>
          </mat-list>
        </div>
      </div>
    </div>
</section>
<!-- #portfolio -->

