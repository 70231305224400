import {Deserializable} from './deserializable';
import { Injectable } from '@angular/core';
import { Utilisateur } from './utilisateur';
import { File } from './file';
@Injectable()
export class Partenaire implements Deserializable<Partenaire> {
  public id: number;
  public nom: string;
  public prenom:string;
  public lien: string;
  public objet: string;
  public autreInfos: string;
  public adresse: string;
  public nomEntreprise:string;
  public telephone: string;
  public email:string;
  public pays:string;
  public logo: string;
  public description: string;
  public dateCreation: any;
  public dateModification?: any;
  public files: File[];
  public enabled: boolean;
  public utilisateur: Utilisateur;
  public lieu: string;
  deserialize(input: any): Partenaire {
    Object.assign(this, input);
    return this;
  }
}
