import {Deserializable} from './deserializable';
import { Injectable } from '@angular/core';
import {Minier} from './minier';
import {Documents} from './documents';
@Injectable()
export class Commentaire implements Deserializable<Commentaire> {
  public id: number;
  public email: string;
  public message: string;
  public dateCreation: Date;
  public dateModification: Date;
  public minier: Minier;
  public document: Documents;
  public enabled: boolean;
  deserialize(input: any): Commentaire {
    Object.assign(this, input);
    return this;
  }
}
