import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AccueilComponent } from './pages/accueil/accueil.component';
import { AboutComponent } from './pages/about/about.component';
import { LoginComponent } from './pages/login/login.component';
import { RegisterComponent } from './pages/register/register.component';
import { ArticlesComponent } from './pages/mining-news/articles.component';
import { DocumentsComponent } from './pages/mining-documents/documents/documents.component';
import { ContactComponent } from './pages/contact/contact.component';
import { PartenairesComponent } from './pages/partenaires/partenaires.component';
import { ExpertsComponent } from './pages/miner/experts/experts.component';
import {SitesComponent} from './pages/entreprise-miniere/entreprise/sites.component';
import {RepertoireComponent} from './pages/entreprise-miniere/repertoire-entreprise/repertoire.component';
import {RessourcesComponent} from './pages/ressources/ressources.component';
import {PubliciteComponent} from './pages/publicite/publicite.component';
import {ProfilComponent} from './pages/profil/profil.component';
import {ConsultantsComponent} from './pages/miner/consultants/consultants.component';
import {FormateursComponent} from './pages/miner/formateurs/formateurs.component';
import {PropositionStageComponent} from './pages/miner/proposition-stage/proposition-stage.component';
import {MinierDetailsComponent} from './pages/miner/minier-details/minier-details.component';
import {ForumComponent} from './pages/mining-documents/forum/forum.component';
import {ForumDetailComponent} from './pages/mining-documents/forum-detail/forum-detail.component';
import {DocumentDetailComponent} from './pages/mining-documents/document-detail/document-detail.component';
import {GuideComponent} from './pages/mining-documents/guide/guide.component';
import {CodeMinierComponent} from './pages/mining-documents/code-minier/code-minier.component';
import {JobsComponent} from './pages/opportunite/jobs/jobs.component';
import {StagesComponent} from './pages/opportunite/stages/stages.component';
import {ErrorComponent} from './pages/error/error.component';
import {EntrepriseDetailsComponent} from './pages/entreprise-miniere/entreprise-details/entreprise-details.component';
import {RessourcesDetailsComponent} from './pages/ressources/ressources-details/ressources-details.component';
import { GuideDetailComponent } from './pages/mining-documents/guide-detail/guide-detail.component';
import { JobsDetailComponent } from './pages/opportunite/jobs-detail /jobs-detail.component';
import { StagesDetailComponent } from './pages/opportunite/stages-detail/stages-detail.component';
import { ConditionGeneraleComponent } from './pages/condition-generale/condition-generale.component';
import { MentionLegaleComponent } from './pages/mention-legale/mention-legale.component';
import {MiningEventsComponent} from './pages/mining-events/mining-events.component';
import {SearchResultComponent} from './pages/search-result/search-result.component';


const routes: Routes = [
  {path: '', redirectTo: 'accueil', pathMatch: 'full'},
  {path: 'accueil', component: AccueilComponent},
  {path: 'about', component: AboutComponent},
  {path: 'mention-legale', component: MentionLegaleComponent},
  {path: 'condition-generale', component: ConditionGeneraleComponent},
  {path: 'login', component: LoginComponent},
  {path: 'register', component: RegisterComponent},
  {path: 'mining-news', component: ArticlesComponent},
  {path: 'mining-events', component: MiningEventsComponent},
  {path: 'documents', component: DocumentsComponent},
  {path: 'contact', component: ContactComponent},
  {path: 'partenaires', component: PartenairesComponent},
  {path: 'entreprises', component: SitesComponent},
  {path: 'repertoire', component: RepertoireComponent},
  {path: 'ressources', component: RessourcesComponent},
  {path: 'publicite', component: PubliciteComponent},
  {path: 'profil', component: ProfilComponent},
  {path: 'professionnels', component: ExpertsComponent},
  {path: 'consultants', component: ConsultantsComponent},
  {path: 'formateurs', component: FormateursComponent},
  {path: 'stages', component: PropositionStageComponent},
  {path: 'detail-cadre-mines', component: MinierDetailsComponent},
  {path: 'forum', component: ForumComponent},
  {path: 'forum-detail', component: ForumDetailComponent},
  {path: 'document-detail', component: DocumentDetailComponent},
  {path: 'guide-detail', component: GuideDetailComponent},
  {path: 'guides', component: GuideComponent},
  {path: 'code-miniers', component: CodeMinierComponent},
  {path: 'jobs', component: JobsComponent},
  {path: 'stages-detail', component: StagesDetailComponent},
  {path: 'Jobs-detail', component: JobsDetailComponent},
  {path: 'stages-opportunite', component: StagesComponent},
  {path: 'entreprise-details', component: EntrepriseDetailsComponent},
  {path: 'ressource-details', component: RessourcesDetailsComponent},
  {path: 'search', component: SearchResultComponent},
  {path: '**', component: ErrorComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true, anchorScrolling: 'enabled', scrollPositionRestoration: 'enabled', onSameUrlNavigation: 'reload'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
