import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {MinierService} from '../../../core/services/minier.service';
import {Pays} from '../../../shared/components/models/pays';
import {EcoleService} from '../../../core/services/ecole.service';
import {Minier} from '../../../shared/components/models/minier';
import {SearchParam} from '../../../shared/components/models/search-param';
import {PaysService} from '../../../core/services/pays.service';
import {Domaine} from '../../../shared/components/models/domaine';
import {DomaineService} from '../../../core/services/domaine.service';
import {environment} from '../../../../environments/environment';
import {Ecole} from '../../../shared/components/models/ecole';
import {LoadingBarService} from '@ngx-loading-bar/core';

@Component({
  selector: 'app-consultants',
  templateUrl: './consultants.component.html',
  styleUrls: ['./consultants.component.scss']
})
export class ConsultantsComponent implements OnInit {

  miniers: Minier[];
  p: number = 1;
  profilImageUri = '';
  paysList: Pays[];
  domaines: Domaine[];
  ecoles: Ecole[];
  searchParam: SearchParam;
  ecoleFilter: any = { nom: '' };
  domaineFilter: any = { nom: '' };
  paysFilter: any = { nom: '' };
  loading: boolean = false;
  constructor(private router: Router,
              private minierService: MinierService,
              private paysService: PaysService,
              private domaineService: DomaineService,
              private ecoleService: EcoleService,
              private loadingBar: LoadingBarService) {
    this.profilImageUri = environment.profilImageUri;
    this.searchParam = new SearchParam();

    this.searchParam.type = 'CONSULTANT';
  }

  ngOnInit() {
    this.searchMiner();
    this.loadCountriesList();
    this.loadDomaines();
    this.loadEcoles();
    window.scrollTo(0, 0);
  }

  showDetailsMiniers(miner: Minier) {
    this.router.navigate(['/detail-cadre-mines'] ,{ queryParams: {minerId: miner.id} });
  }
  goToRegister() {
    this.router.navigate(['/register']);
  }
  searchMiner() {
    this.loadingBar.start();
   // console.log('searchParam', this.searchParam);
    this.minierService.search(this.searchParam).subscribe(ret => {
      if (ret['status'] === 'OK') {
        this.miniers = ret['response'];
        this.loadingBar.complete();
       // console.log('Miniers', this.miniers);
      } else {
        // console.log('erreur survenu');
        this.loadingBar.complete();
      }
    }, error => {
      // console.log('erreur server');
      this.loadingBar.complete();
    });
  }
  loadCountriesList() {
    this.paysService.loadCountriesList().subscribe(ret => {
      if (ret['status'] === 'OK') {
        this.paysList = ret['response'];
       // console.log('paysList', this.paysList);
      } else {
        // console.log('erreur survenu');
      }
    }, error => {
      // console.log('erreur server');
    });
  }
  loadDomaines() {
    this.domaineService.loadDomaineList().subscribe(ret => {
      if (ret['status'] === 'OK') {
        this.domaines = ret['response'];
       // console.log('domaines', this.domaines);
        const tmpDomaines: Domaine[] = [];
      } else {
        // console.log('erreur survenu');
      }
    }, error => {
      // console.log('erreur server');
    });
  }
  loadEcoles() {
    this.ecoleService.loadEcoleList().subscribe(ret => {
      if (ret['status'] === 'OK') {
        this.ecoles = ret['response'];
      //  console.log('ecoles', this.ecoles);
      } else {
        // console.log('erreur survenu');
      }
    }, error => {
      // console.log('erreur server');
    });
  }
  onPageChange(page: number) {
    this.p = page;
    window.scrollTo(0, 0);
  }

}
