import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {EntrepriseService} from '../../../core/services/entreprise.service';
import {Entreprise} from '../../../shared/components/models/entreprise';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-repertoire',
  templateUrl: './repertoire.component.html',
  styleUrls: ['./repertoire.component.scss']
})
export class RepertoireComponent implements OnInit {
  loading: boolean = false;
  entreprises: Entreprise[];
  imageUrl = '';
  constructor(private router: Router,
              private route: ActivatedRoute,
              private entrepriseService: EntrepriseService) {
    this.imageUrl = environment.entrepriseImageUri;
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      if (params.pays) {
        this.loading = true;
        this.entrepriseService.findEntrepriseByCountry(params.pays).subscribe(ret => {
          if (ret['status'] === 'OK') {
            this.entreprises = ret['response'];
            // console.log('entreprises', this.entreprises);
            this.loading = false;
          } else {
            this.loading = false;
            this.router.navigate(['/error']);
          }
        }, error => {
          console.log('erreur server');
          this.loading = false;
          this.router.navigate(['/error']);
        });
      } else {
        this.router.navigate(['/error']);
      }
    });
    window.scrollTo(0, 0);
  }
  goToEntrepriseDetails(entreprise: Entreprise) {
    this.router.navigate(['/entreprise-details'], { queryParams: {entreprise: entreprise.id}});
  }
  returnToEnterpriseListByConutry(code) {
    this.router.navigate(['/entreprises'],{ queryParams: {pays: code}});
  }
}
