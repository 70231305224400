import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-qui-sommes-nous',
  templateUrl: './qui-sommes-nous.component.html',
  styleUrls: ['./qui-sommes-nous.component.scss']
})
export class QuiSommesNousComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
