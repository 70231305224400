import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mention-legale',
  templateUrl: './mention-legale.component.html',
  styleUrls: ['./mention-legale.component.css']
})
export class MentionLegaleComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
