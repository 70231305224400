import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class OpportuniteService {

  private serviceUrl: string;
  private httpOptions: any;


  constructor(private http: HttpClient) {

    this.serviceUrl = environment.apiUrl;
  }

  public findAllOpportunite() {
    return this.http.get(this.serviceUrl + '/opportunite/find/enabled');
  }
  public findLastest() {
    return this.http.get(this.serviceUrl + '/opportunite/find/lastest');
  }
  public findOthers(oppId) {
    return this.http.get(this.serviceUrl + '/opportunite/findOthers/' + oppId);
  }
  public findOpportuniteJobsById(oppId) {
    return this.http.get(this.serviceUrl + '/opportunite/jobs/' + oppId);
  }
  public findOpportuniteStagesById(oppId) {
    return this.http.get(this.serviceUrl + '/opportunite/stages/' + oppId);
  }
  public save(opportunite) {
    return this.http.post(this.serviceUrl + '/opportunite', opportunite, this.httpOptions);
  }

  public update(opportunite) {
    return this.http.put(this.serviceUrl + '/opportunite' , opportunite, this.httpOptions);
  }

  public desactivate(opportunite) {
    return this.http.put(this.serviceUrl + '/opportunite/desactivate/' + opportunite, this.httpOptions);
  }

  public activate(opportunite) {
    return this.http.put(this.serviceUrl + '/opportunite/activate/' + opportunite, this.httpOptions);
  }

}
