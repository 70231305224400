import {Deserializable} from './deserializable';
import { Injectable } from '@angular/core';
@Injectable()
export class Contact implements Deserializable<Contact> {
  public id: number;
  public nom: string;
  public email: string;
  public message: string;
  public objet: string;
  public dateCreation: string;
  public dateModification: string;
  public enabled: boolean = true;

  deserialize(input: any): Contact {
    Object.assign(this, input);
    return this;
  }
}
