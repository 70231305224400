import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import { MineInsidersUtils } from 'src/app/shared/components/utils/mineInsiders-util';
import {Minier} from '../../shared/components/models/minier';
import {SearchParam} from '../../shared/components/models/search-param';

@Injectable({
  providedIn: 'root'
})
export class MinierService {
  private serviceURL: string;
  private httpOptions: any;

  constructor(private http: HttpClient) {
    this.serviceURL = environment.apiUrl;
    this.httpOptions = new MineInsidersUtils().httpHeaders();
  }

  public save(minier: Minier) {
    return this.http.post(this.serviceURL + '/minier', minier, this.httpOptions);
  }

  public search(search: SearchParam) {
    return this.http.post(this.serviceURL + '/minier/search', search, this.httpOptions);
  }

  public loadMiners() {
    return this.http.get(this.serviceURL + '/minier', this.httpOptions);
  }
  public findCurrentMiner(minerId) {
    return this.http.get(this.serviceURL + '/minier/find/' + minerId, this.httpOptions);
  }

  public update(minier: Minier) {
    return this.http.put(this.serviceURL + '/minier', minier, this.httpOptions);
  }

  public updateProfilImage(minier: Minier) {
    return this.http.put(this.serviceURL + '/minier/profil/image', minier, this.httpOptions);
  }

  public activate(minierId) {
    return this.http.put(this.serviceURL + '/minier/activate/' + minierId, this.httpOptions);
  }

  public desactivate(minierId) {
    return this.http.put(this.serviceURL + '/minier/desactivate/' + minierId, this.httpOptions);
  }
  public reset(id, oldPassword, newPassword) {
    return this.http.put(this.serviceURL + '/minier/update/password/' + id + '/' + oldPassword + '/' + newPassword, this.httpOptions);
  }
  public resetMinerPassword(email) {
    return this.http.get(this.serviceURL + '/minier/reset/password?' + 'email=' + email , this.httpOptions);
  }
//   public reset(id, nouveau) {
//     return this.http.get(this.serviceURL + '/minier/change/password?id=' + id + '&nouveau=' + nouveau);
// }
}
