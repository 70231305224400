import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {DocumentService} from '../../../core/services/document.service';
import {Documents} from '../../../shared/components/models/documents';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-document-detail',
  templateUrl: './document-detail.component.html',
  styleUrls: ['./document-detail.component.scss']
})
export class DocumentDetailComponent implements OnInit {
  loading: boolean = false;
  guideList: Documents[];
  document: Documents;
  code: any;
  documentFileUri = '';
  page: number = 1;
  totalPages: number;
  isLoaded: boolean = false;
  constructor( private router: Router,
               private route: ActivatedRoute,
               private documentService: DocumentService) {
                this.documentFileUri = environment.documentFileUri;
               }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      if (params.document) {
        this.loading = true;
        this.documentService.findDocumentCodeById(params.document).subscribe(ret => {
          if (ret['status'] === 'OK') {
            this.code = ret['response'];
            // console.log('doc code', this.code);
            this.loading = false;
          } else {
            this.loading = false;
            this.router.navigate(['/error']);
          }
        }, error => {
          // console.log('erreur server');
          this.loading = false;
          this.router.navigate(['/error']);
        });
      } else {
        this.router.navigate(['/error']);
      }
    });
    window.scrollTo(0, 0);
  }
  loadGuideList() {
    this.loading = true;
    this.documentService.findDocumentGuideById(this.document.id).subscribe(ret => {
      if (ret['status'] === 'OK') {
        this.guideList = ret['response'];
        // console.log('list doc guide ', this.guideList);
        this.loading = false;
      } else {
        console.log('erreur survenu');
        this.loading = false;
      }
    }, error => {
      console.log('erreur server');
      this.loading = false;
    });
  }

  afterLoadComplete(pdfData: any) {
    this.totalPages = pdfData.numPages;
    this.isLoaded = true;
    this.loading = false;
  }

  nextPage() {
    this.page++;
  }

  prevPage() {
    this.page--;
  }

}
