import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PartenaireInscrisService } from 'src/app/core/services/partenaireInscris.service';
import { Partenaire } from 'src/app/shared/components/models/partenaire';
import { ToastService } from 'src/app/shared/components/utils/toast.service';
import { environment } from 'src/environments/environment';
import {PaysService} from '../../core/services/pays.service';
import {Pays} from '../../shared/components/models/pays';

@Component({
  selector: 'app-partenaires',
  templateUrl: './partenaires.component.html',
  styleUrls: ['./partenaires.component.css']
})
export class PartenairesComponent implements OnInit {
  images: Partenaire[] = [];
  imageUrl: any;
  partenaire = new Partenaire();
  partenaires: Partenaire[] = [];
  infosPartenaire: boolean = false;
  loading: boolean = false;
  paysList: Pays[];
  constructor(private router: Router,
    private partenaireService: PartenaireInscrisService,
    public toast: ToastService,
    private paysService: PaysService,
) {
this. imageUrl = environment.partenaireImageUri;
}

  ngOnInit() {
    this.loadCountriesList();
  }
  // showAddPartenaire() {
  //   // to Add new document
  //   this.addPartenaire = true;
  //   this.partenaire = new Partenaire();
  // }
   showInfosPartenaire() {
    // to Add new document
    this.infosPartenaire = true;
    this.partenaire = new Partenaire();
  }
  onSave() {
    //console.log("partenaire", this.partenaire);
    this.loading = true;
    this.partenaireService.save(this.partenaire).subscribe(ret => {
      if (ret['status'] == 'OK') {
        this.partenaire = ret['response'];
        //console.log("partenaire", this.partenaire);
       // this.toast.success(ret['message']);
        this.showInfosPartenaire();
        this.loading = false;
        window.scrollTo(0, 0);
      } else {
      //  this.toast.info('erreur server');

      }
    }, error => {
      // console.log('erreur server');
      this.toast.info('erreur server');
      this.loading = false;
    });

  }

 /* onUpdate() {
    this.loading = true;
    this.partenaireService.update(this.partenaire).subscribe(ret => {
      if (ret['status'] == 'OK') {
        this.partenaire = ret['response'];
        this.toast.success(ret['message']);
        this.loading = false;
      } else {
        this.toast.error(ret['message']);
        this.loading = false;
      }
    }, error => {
      // console.log('erreur server');
      this.toast.info('erreur server');
      this.loading = false;
    });
  }
*/
  loadCountriesList() {
    this.paysService.loadCountriesList().subscribe(ret => {
      if (ret['status'] === 'OK') {
        this.paysList = ret['response'];
      } else {
      }
    }, error => {
    });
  }


}
