<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '5px',fullScreenBackdrop:true }"></ngx-loading>

<!-- CONFIRM RESET PASSWORD DIALOG-->
<ng-template #templateConfirme>
  <div class="p-2" style="width: 100%">
    <button type="button" class="close pull-right" aria-label="Close" (click)="hideRegisterConfirmModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <mat-divider></mat-divider>
  <div class="modal-body">
    <div class="text-center mb-2" style="background: #ffffff;">
      <p style="color: #49f026">Votre mot de passe a été réinitialisé avec succès!</p>
      <p style="color: #49f026">Un email de confirmation vous a été envoyé contenant le nouveau mot de passe.</p>
      <br/>
      <p style="color: #49f026">Merci.</p>
    </div>
    <mat-divider></mat-divider>
    <div class="row mt-4">
      <div class="col-12 text-center">
        <button style="color: #fff; background: #007ef0" (click)="hideRegisterConfirmModal()" class="btn">OK</button>
      </div>
    </div>
  </div>
</ng-template>

<section id="ressources" class="wow fadeInUp" style="min-height: 500px;margin-top: 20px">
  <div class="container">
    <div class="limiter" *ngIf="isLogin" style="min-height: 500px;margin-top: 20px">
      <div class="container-login100">
        <div class="wrap-login100">
          <form #loginForm="ngForm" name="form" class="login100-form validate-form">
            <h3 style="text-align: center">
              Connectez-vous
            </h3>
            <hr>
            <div class="wrap-input100 validate-input">
              <input class="input100" type="text" name="email" [(ngModel)]="loginModel.email" id="email" placeholder="Entrez votre Email" required>
              <!-- <span class="focus-input100" data-placeholder="Email"></span> -->
            </div>

            <div class="wrap-input100 validate-input">
                    <span class="btn-show-pass">
                      <i class="zmdi zmdi-eye"></i>
                    </span>
              <input class="input100" type="password" name="password" id="user-password" [(ngModel)]="loginModel.password" placeholder="Entrez votre mot de passe" required>
              <!-- <span class="focus-input100" data-placeholder="Password"></span> -->
            </div>
            <p style="color: #e51d30;text-align: center">{{errorMessage}}</p>
            <div class="container-login100-form-btn">
              <div class="wrap-login100-form-btn">
                <div class="login100-form-bgbtn"></div>
                <button (click)="login()" [disabled]="!loginForm.form.valid" style="background-color: #007cdc " class="login100-form-btn">
                  Se connecter
                </button>
              </div>
            </div>
            <br/>
            <div class="text-center p-t-115">
              <a class="txt2" href="javascript:void(0)" (click)="showForgetPassword()">
                Mot de passe oublié ?
              </a>
            </div>
            <br/>
            <div class="text-center p-t-115">
              <a class="txt2" routerLink="/register">
                Pas encore inscrit? Enregistrez vous ici.
              </a>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div class="limiter" *ngIf="!isLogin">
      <div class="container-login100">
        <div class="wrap-login100">
          <form #resetForm="ngForm" name="form" class="login100-form validate-form">
        <span>
          Réinitialisation de mot de passe
        </span>
            <hr>
            <div class="wrap-input100 validate-input">
              <input class="input100" type="text" name="email" [(ngModel)]="email" id="emails" placeholder="Entrez votre Email" required>
              <!-- <span class="focus-input100" data-placeholder="Email"></span> -->
            </div>
            <p style="color: #e51d30;text-align: center">{{errorMessage}}</p>
            <div class="container-login100-form-btn">
              <div class="wrap-login100-form-btn">
                <div class="login100-form-bgbtn"></div>
                <button  [disabled]="!resetForm.form.valid" (click)="resetPassword()" style="background-color: #007cdc" class="login100-form-btn">
                  Envoyer
                </button>
              </div>
            </div>
            <br/>
            <div class="text-center p-t-115">
              <a class="txt2" href="javascript:void(0)" (click)="showLogin()">
                Se Connecter
              </a>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>


