
<section id="portfolio" class="wow fadeInUp">
  <div class="container-fluid">
    <div class="section-header">
      <h2>Code Miniers</h2>
    </div>
  </div>

  <div class="container-fluid">
    <div class="row">

      <div class="col-md-12 col-lg-12 col-12 col-sm-12" *ngFor="let codes of loadCodeListe | paginate: { itemsPerPage: 8, currentPage: p }">
        <mat-list>
          <mat-list-item>
            <mat-icon mat-list-icon>file_copy</mat-icon>
            <a href="javascript:void(0)" (click)="showDetails(codes)" style="color: #959598" mat-line>{{codes.nom}}</a>
          </mat-list-item>
        </mat-list>
      </div>
      <div align="center">
        <pagination-controls (pageChange)="p = $event"
                             directionLinks="true"
                             autoHide="true"
                             responsive="true"
                             previousLabel="Précédent"
                             nextLabel="Suivant"
                             screenReaderPaginationLabel="Pagination"
                             screenReaderPageLabel="page"
                             screenReaderCurrentLabel="Vous êtes dejà sur la page">
        </pagination-controls>
      </div>
    </div>
  </div>
</section>
<!-- #portfolio -->


