
<!-- #ressources -->
<section id="ressources" class="wow fadeInUp" style="min-height: 500px;margin-top: 20px">
  <div class="container">
    <div class="section-header">
      <h2>Ressources Minerales</h2>
    </div>
    <div class="row" *ngIf="ressources?.length==0">
      <h3 align="center">Aucune ressource trouvée</h3>
    </div>
    <div class="row" *ngIf="ressources?.length>0">
      <div class="col-lg-6 col-md-6 col-sm-12" *ngFor="let res of ressources">
        <div class="box wow fadeInLeft" (click)="goToRessourceDetails(res)" style="cursor: pointer">
          <div class="icon"><img [src]="imageUrl+res?.imageName" width="90px" height="90px" /></div>
          <h4 class="title"><a href="javascript:void(0)">{{res.nom}}</a></h4>
          <p class="description" [innerHtml]="res.description | slice:0:100"></p>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- #ressources -->
