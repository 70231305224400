import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {MineInsidersUtils} from '../../shared/components/utils/mineInsiders-util';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private serviceURL: string;
  private httpOptions: any;

  constructor(private http: HttpClient) {
    this.serviceURL = environment.apiUrl;
    this.httpOptions = new MineInsidersUtils().httpHeaders();
  }

  public login(loginModel: any) {
    return this.http.get(this.serviceURL + '/auth/minier/' + loginModel.email + '/' + loginModel.password);
  }

  public logout(minerId: number) {
    return this.http.get(this.serviceURL + '/auth/minier/logout/' + minerId, this.httpOptions);
  }

  public resetPassword(email: string) {
    return this.http.get(this.serviceURL + '/auth/minier/reset/password/' + email , this.httpOptions);
  }

  public changePassword(passwordObject: any) {
    return this.http.get(this.serviceURL + '/auth/minier/update/password/' + passwordObject.minerId + '/' + passwordObject.oldPassword + '/' + passwordObject.newPassword, this.httpOptions);
  }
}
