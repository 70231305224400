<div style="width: 300px;">
  <div mat-dialog-title=""></div>
  <div mat-dialog-content>
    <form>
      <mat-form-field style="width: 100%">
        <input matInput  name="nom" placeholder="Nom complet" required>
      </mat-form-field>
      <br>
      <mat-form-field style="width: 100%">
        <input matInput  name="email" placeholder="Email" required>
      </mat-form-field>
      <button class="btn btn-primary btn-sm" style="margin-left: 20px;">
        Ajouter votre CV
      </button>
      <br>
      <br>
    </form>
  </div>
  <hr>
  <div mat-dialog-actions align="center">
    <button class="btn btn-danger btn-sm" (click)="onNoClick()">Annuler</button>
    <button class="btn btn-primary btn-sm" style="margin-left: 20px;">
      Envoyer
    </button>
  </div>
</div>

