<!-------------HEADER-------------->
<!-- <app-header></app-header> -->
<!-------------HEADER-------------->
<!-- #contact -->
<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '5px',fullScreenBackdrop:true }"></ngx-loading>
<div class="container" style="overflow-y: scroll">
  <section id="contact" class="wow fadeInUp">
    <div class="container">
      <div class="section-header">
        <h2>Contactez nous</h2>
      </div>
      <div class="contact">
        <div class="row">
          <div class="col-md-3">
            <div class="contact-info">
              <i class="fa fa-envelope"></i>
              <div style="color: #fafbff">Contactez-nous</div>
              <div style="color: #fafbff">Pour tout info supplementaire!</div>
            </div>
          </div>
          <div class="col-md-9">
            <form #contactForm="ngForm">
              <div class="contact-form">
              <div class="form-group">
                <label class="control-label col-sm-2" for="fname">Nom *:</label>
                <div class="col-sm-10">
                  <input type="text" class="form-control" id="fname" [(ngModel)]="message.nom" name="nom" placeholder="Nom complet" required>
                </div>
              </div>
              <div class="form-group">
                <label class="control-label col-sm-2" for="email">Email *:</label>
                <div class="col-sm-10">
                  <input type="email" class="form-control" id="email" placeholder="Email" [(ngModel)]="message.email" name="email" required>
                </div>
              </div>
                <div class="form-group">
                  <label class="control-label col-sm-2" for="objet">Objet *:</label>
                  <div class="col-sm-10">
                    <input type="text" class="form-control" id="objet" [(ngModel)]="message.objet" name="objet" placeholder="Objet" required>
                  </div>
                </div>
              <div class="form-group">
                <label class="control-label col-sm-2" for="comment">Message *:</label>
                <div class="col-sm-10">
                  <textarea class="form-control" placeholder="Votre message" [(ngModel)]="message.message" name="description" rows="5" id="comment" required></textarea>
                </div>
              </div>
              <div class="form-group">
                <div class="col-sm-offset-2 col-sm-10">
                  <button type="submit" [disabled]="!contactForm.form.valid" (click)="sendMessage()" class="btn btn-default">Envoyer</button>
                </div>
              </div>
            </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
<!-- #contact -->
<!-- <app-footer></app-footer> -->
