import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {RessourceService} from '../../../core/services/ressource.service';
import {Ressource} from '../../../shared/components/models/ressource';

@Component({
  selector: 'app-ressources-details',
  templateUrl: './ressources-details.component.html',
  styleUrls: ['./ressources-details.component.scss']
})
export class RessourcesDetailsComponent implements OnInit {
  ressource: Ressource;
  loading: boolean = false;
  constructor(private router: Router,
              private route: ActivatedRoute,
              private ressourceService: RessourceService) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      if (params.ressource) {
        this.loading = true;
        this.ressourceService.findById(params.ressource).subscribe(ret => {
          if (ret['status'] === 'OK') {
            this.ressource = ret['response'];
            // console.log('ressource', this.ressource);
            this.loading = false;
          } else {
            this.loading = false;
            this.router.navigate(['/error']);
          }
        }, error => {
          // console.log('erreur server');
          this.loading = false;
          this.router.navigate(['/error']);
        });
      } else {
        this.router.navigate(['/error']);
      }
    });
  }

  goToRessources() {
    this.router.navigate(['/ressources']);
  }
}
