import {Injectable} from '@angular/core';
import {MatDialogConfig} from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})
export class MyMatDialogConfig {

  config(data): MatDialogConfig {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = data;
    dialogConfig.position = {'top': '60px'};
    return dialogConfig;
  }
}
