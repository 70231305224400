import {AfterViewInit, Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import { Partenaire } from 'src/app/shared/components/models/partenaire';
import { PartenaireService } from 'src/app/core/services/partenaire.service';
import { environment } from 'src/environments/environment';
import { SlidersService } from 'src/app/core/services/sliders.service';
import { Sliders } from 'src/app/shared/components/models/sliders';
import { DocumentService } from 'src/app/core/services/document.service';
import { Documents } from 'src/app/shared/components/models/documents';
import { Temoignage } from 'src/app/shared/components/models/temoignage';
import { TemoignageService } from 'src/app/core/services/temoignage.service';
import { OwlOptions } from 'ngx-owl-carousel-o';
import {NewsService} from '../../core/services/news.service';
import {LoadingBarService} from '@ngx-loading-bar/core';
import {News} from '../../shared/components/models/news';
import {OpportuniteService} from '../../core/services/opportunite.service';
import {Opportunite} from '../../shared/components/models/opportunite';
import {Publicite} from '../../shared/components/models/publicite';
import {PubliciteService} from '../../core/services/publicite.service';
import {VideoService} from '../../core/services/video.service';
import {Video} from '../../shared/components/models/video';
import {EntrepriseService} from '../../core/services/entreprise.service';
import {Entreprise} from '../../shared/components/models/entreprise';
import {Pochette} from '../../shared/components/models/pochette';
declare var $: any;
@Component({
  selector: 'app-accueil',
  templateUrl: './accueil.component.html',
  styleUrls: ['./accueil.component.css']
})
export class AccueilComponent implements OnInit, AfterViewInit {
  // images: Partenaire[];
  pochettes: Pochette[] = [];
  images: Partenaire[] = [];
  sliderList: Sliders[];
  imageUrl: any;
  forumList: Documents[];
  documentImageUri = '';
  loading: boolean = false;
  p: number = 1;
  newses: News[] = [];
  jobList: Opportunite[] = [];
  sliderImageUri = '';
  newsImageUri = '';
  temoignages: Temoignage[] = [];
  temoignageImageUri: any;
  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    autoplay:true,
    autoplayTimeout:5000,
    autoplayHoverPause:false,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      }
    },
    nav: true
  };
  player: YT.Player;
  idYoutoube: string;
  pubB: Publicite;
  pubC: Publicite;
  pubD: Publicite;
  pubE: Publicite;
  pubF: Publicite;
  pubG: Publicite;
  publicites: Publicite[] = [];
  publiciteImageUri: string;
  entrepriseImageUri: string;
  pochetteImageUri: string;
  videos: Video[] = [];
  entreprises: Entreprise;
  constructor(private router: Router,
              private partenaireService: PartenaireService,
              private documentService: DocumentService,
              private temoignageService: TemoignageService,
              private slidersService: SlidersService,
              private newsService: NewsService,
              private loadingBar: LoadingBarService,
              private opportuniteService: OpportuniteService,
              private videoService: VideoService,
              private entrepriseService: EntrepriseService) {
    this.imageUrl = environment.partenaireImageUri;
    this.sliderImageUri = environment.sliderImageUri;
    this.documentImageUri = environment.documentImageUri;
    this.temoignageImageUri = environment.temoignageImageUri;
    this.newsImageUri = environment.newsImageUri;
    this.publiciteImageUri = environment.publiciteImageUri;
    this.entrepriseImageUri = environment.entrepriseImageUri;
    this.pochetteImageUri = environment.pochetteImageUrl;
    this.findVideo();
  }

  ngOnInit() {
  this.initTemoignages();
  this.findAllPartenaires();
  this.loadJobList();
  this.loadNews();
  this.loadLastEntreprise();
  this.findPochettes();
  window.scrollTo(0, 0);
  }
  initTemoignages() {
    this.loading = true;
    this.temoignageService.findAllTemoignage().subscribe(ret => {
      if (ret['status'] == 'OK') {
        this.temoignages = ret['response'];
  } else { }
    }, error => {});
  }

  findAllPartenaires() {
    this.partenaireService.findAllPartenaire().subscribe(ret => {
        if (ret['status'] == 'OK') {
          this.images = ret['response'];
        } else {
        }
    }), error => {
    };
  }
  findPochettes() {
    this.partenaireService.findDocAndServicePochette().subscribe(ret => {
      if (ret['status'] == 'OK') {
        this.pochettes = ret['response'];
        // console.log('pochettes', this.pochettes);
      } else {
      }
    }), error => {
    };
  }
  findVideo() {
    this.videoService.findAll().subscribe(ret => {
      if (ret['status'] === 'OK') {
        this.videos = ret['response'];
        // console.log('Video' , this.videos);
        if(this.videos.length>0) {
          this.idYoutoube = this.videos[0].identifiant;
          // console.log(this.idYoutoube);
        }
      } else {
      }
    }), error => {
    };
  }
  loadSliderList() {
    this.loadingBar.start();
    this.slidersService.findAllSlider().subscribe(ret => {
      if (ret['status'] === 'OK') {
        this.sliderList = ret['response'];
        this.loadingBar.complete();
      } else {
        // console.log('erreur survenu');
        this.loadingBar.complete();
      }
    }, error => {
      // console.log('erreur server');
      this.loadingBar.complete();
    });
  }

  loadNews() {
    this.newsService.findLastest().subscribe(ret => {
      if (ret['status'] === 'OK') {
        this.newses = ret['response'];
      } else {
        // console.log('erreur survenu');
      }
    }, error => {
      // console.log('erreur server');
    });
  }

  loadJobList() {
    this.loading = true;
    this.opportuniteService.findLastest().subscribe(ret => {
      if (ret['status'] === 'OK') {
        this.jobList = ret['response'];
        // console.log('+++++ list Job ', this.jobList);
        this.loading = false;
      } else {
        // console.log('erreur survenu');
        this.loading = false;
      }
    }, error => {
      // console.log('erreur server');
      this.loading = false;
    });
  }

  loadLastEntreprise() {
    this.entrepriseService.findLastEntreprise().subscribe(ret => {
      if (ret['status'] === 'OK') {
        this.entreprises = ret['response'];
      } else {
        // console.log('erreur survenu');
      }
    }, error => {
      // console.log('erreur server');
    });
  }

  savePlayer(player) {
    this.player = player;
  }
  onStateChange(event) {
  }
  goToEntrepriseDetails(entreprise: Entreprise) {
    this.router.navigate(['/entreprise-details'], { queryParams: {entreprise: entreprise.id}});
  }
  ngAfterViewInit() {
    // loading templates js after dom render
    $.getScript('https://platform.twitter.com/widgets.js', () => {
    });
  }
  goToRegister() {
    this.router.navigate(['/register']);
  }
  goToEntreprises() {
    this.router.navigate(['/entreprises'])
      .then(() => {
        window.location.reload();
      });
  }
}
