<ngx-loading-bar color="#fff"></ngx-loading-bar>
<div class="container">
  <section id="test" style="overflow-x: hidden">
    <div class="row" style="padding: 10px">
        <div class="col-sm-12 col-md-8 col-lg-8">
          <mat-card>
            <app-pub-b></app-pub-b>
          </mat-card>
        </div>
        <div class="col-sm-12 col-md-4 col-lg-4">
          <mat-card style="background-color: #007cdc;padding-top: 10px;color: #FFFFFF;margin: 0">
            <div class="row">
              <div class="col-sm-12 col-12">
                <h3 style="font-size: 14px">Vous êtes miniers? inscrivez vous gratuitement pour gagner en visibilité!</h3>
              </div>
              <div class="col-sm-12 col-12">
                <button class="btn btn-grey" routerLink="/register">Je m'inscris gratuitement</button>
              </div>
            </div>
          </mat-card>
        </div>
      </div>
  
    <div class="row" style="padding: 10px">
      <div class="col-sm-12 col-md-8 col-lg-8">
        <!-- First row -->
        <div class="row">
          <div class="col-sm-12 col-md-6 col-lg-6" *ngIf="newses.length>0">
            <a [href]="'#/mining-news?news=' + newses[0]?.id">
              <mat-card style="margin-bottom: 10px">
                <img mat-card-image class="img-article" src={{newsImageUri+newses[0]?.imageName}} alt="">
                <mat-card-content>
                </mat-card-content>
                <mat-card-actions class="action-buttons">
                  <mat-card-title style="font-size: 16px" class="title">{{newses[0]?.titre}}</mat-card-title>
                  <a mat-button color="primary" [href]="'#/mining-news?news=' + newses[0]?.id" ><mat-icon>visibility</mat-icon> En savoir plus</a>
                </mat-card-actions>
              </mat-card>
            </a>
          </div>
          <div class="col-12 col-sm-12 col-md-6 col-lg-6" *ngIf="newses.length>0">
            <a [href]="'#/mining-news?news=' + newses[1]?.id">
              <mat-card style="margin-bottom: 10px">
                <img mat-card-image class="img-article" src={{newsImageUri+newses[1]?.imageName}} alt="">
                <mat-card-content>
                </mat-card-content>
                <mat-card-actions class="action-buttons">
                  <mat-card-title style="font-size: 16px" class="title">{{newses[1]?.titre}}</mat-card-title>
                  <a mat-button color="primary" [href]="'#/mining-news?news=' + newses[1]?.id" ><mat-icon>visibility</mat-icon> En savoir plus</a>
                </mat-card-actions>
              </mat-card>
            </a>
          </div>
          <div class="col-12 col-sm-12 col-md-12 col-lg-12">
            <mat-card style="background: #f6f6f6;;margin-bottom: 10px">
              <div style="text-align: center;font-size: 20px;font-weight: bold">Dernières offres d'emploi</div>
              <mat-divider></mat-divider>
              <br>
              <carousel [noPause]="false" [showIndicators]="false">
                <slide *ngFor="let job of jobList">
                  <div align="center">
                    <a [href]="'#/Jobs-detail?opportunite='+ job.id" style="color: #047bdc;font-size: 18px">
                      <h3>
                        {{job.nom}}
                      </h3>
                    </a>
                  </div>
                </slide>
              </carousel>
            </mat-card>
          </div>
          <!--For web -->
          <div class="col-12 col-sm-12 col-md-12 col-lg-12 d-none d-lg-block d-md-block" style="margin-bottom: 10px">
            <carousel [noPause]="false" [showIndicators]="false">
              <slide *ngFor="let news of newses">
                <a [href]="'#/mining-news?news=' + news.id">
                  <img [src]="newsImageUri+news.imageName" style="height: 450px;width: 100%">
                  <div class="carousel-caption" style="background-color: #6d6d6dc7;position: initial !important;">
                    <h3 style="font-size: 24px">{{news?.titre}}</h3>
                  </div>
                </a>
              </slide>
            </carousel>
          </div>
          <!-- For Mobile-->
          <div class="col-12 col-sm-12  d-lg-none d-md-none" style="margin-bottom: 5px">
            <carousel [noPause]="false" [showIndicators]="false">
              <slide *ngFor="let news of newses">
                <a [href]="'#/mining-news?news=' + news.id">
                  <img [src]="newsImageUri+news.imageName" style="width: 100%">
                  <div class="carousel-caption" style="background-color: #6d6d6dc7;position: initial !important;">
                    <h3 style="font-size: 14px">{{news?.titre}}</h3>
                  </div>
                </a>
              </slide>
            </carousel>
          </div>
          <div class="col-12 col-sm-12 col-md-12 col-lg-12">
            <mat-card style="margin-bottom: 10px">
              <app-pub-e></app-pub-e>
            </mat-card>
          </div>
          <div class="col-12 col-sm-12 col-md-12 col-lg-12">
            <div class="row">
              <div class="col-12 col-sm-12 col-md-6 col-lg-6">
                <mat-card style="margin-bottom: 10px">
                  <app-pub-f></app-pub-f>
                </mat-card>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-6">
                <mat-card style="margin-bottom: 10px">
                  <app-pub-g></app-pub-g>
                </mat-card>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-12" *ngIf="idYoutoube">
            <mat-card style="width: 100%;margin-bottom: 10px">
              <youtube-player
                [videoId]="idYoutoube"
                width="100%"
                height="450px"
                (ready)="savePlayer($event)"
                (change)="onStateChange($event)"
              ></youtube-player>
            </mat-card>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-12">
            <div class="row">
              <div class="col-sm-12 col-md-5 col-lg-5">
                <mat-card style="margin-bottom: 10px">
                  <div class="section-header">
                    <h2>Nos Documents et services</h2>
                  </div>
                  <div class="row">
                      <div class="col-md-12 col-lg-12 col-xl-12 col-sm-12 col-12" *ngFor="let pochette of pochettes" style="margin-bottom: 5px">
                        <a [href]="pochette?.link">
                          <mat-card>
                            <mat-card-header>
                              <mat-card-title style="font-size: medium;">{{pochette?.title}}</mat-card-title>
                            </mat-card-header>
                            <mat-card-content>
                              <img [src]="pochetteImageUri + pochette?.imageName" style="width: 100%" alt="">
                            </mat-card-content>
                          </mat-card>
                        </a>
                      </div>
                    </div>
                </mat-card>
              </div>
              <div class="col-sm-12 col-md-7 col-lg-7">
                <mat-card style="margin-bottom: 10px">
                  <div class="section-header">
                    <h2>Entreprises de prestations de services miniers</h2>
                  </div>
                  <div class="row" style="padding: 10px">
                    <div class="col-lg-12 col-md-12 col-sm-12" *ngFor="let item of entreprises">
                      <div class="box wow fadeInRight" (click)="goToEntrepriseDetails(item)" style="cursor:pointer">
                        <div class="icon"><img [src]="entrepriseImageUri+item.logoImageName" width="40px" height="40px" /></div>
                        <h4 class="title"><a href="javascript:void(0)">{{item.nom}}</a></h4>
                      </div>
                      <mat-divider></mat-divider>
                      <br>
                    </div>
                  </div>
                  <div align="center">
                    <button class="btn btn-sm btn-outline-secondary" (click)="goToEntreprises()">Voir toutes les entreprises</button>
                  </div>
                </mat-card>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-12 col-md-4 col-lg-4">
        <div class="row">
          <div class="col-sm-12 col-md-12 col-lg-12">
            <mat-card style="margin-bottom: 10px">
             <app-pub-c></app-pub-c>
            </mat-card>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-12">
            <mat-card style="margin-bottom: 10px">
              <div class="col-sm-12 col-12">
                <button style="background-color: #047bdd;border-color: #007ce2;" class="btn btn-primary" routerLink="/guides">Consulter gratuitement nos guides
                </button>
              </div>
            </mat-card>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-12">
            <mat-card style="margin-bottom: 10px">
              <app-pub-d></app-pub-d>
            </mat-card>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-12">
            <mat-card style="margin-bottom: 10px">
              <div class="col-sm-12 col-12">
                <button style="background-color: #047bdd;border-color: #007ce2;" class="btn btn-primary" routerLink="/professionnels">Rejoindre le reseau social des miniers
                </button>
              </div>
            </mat-card>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-12">
            <div class="row">
              <!-- Twitter news feed -->
              <div class="col-sm-12 col-md-12 col-lg-12">
                <mat-card style="margin-bottom: 10px;">
                  <a class="twitter-timeline" data-height="700" href="https://twitter.com/Mineinsiders?ref_src=twsrc%5Etfw">Tweets by Mineinsiders</a>
                </mat-card>
              </div>
              <!-- Facebook news feed -->
              <div class="col-sm-12 col-md-12 col-lg-12">
                <br>
                <mat-card style="margin-bottom: 10px">
                  <div style="width: 100%" class="fb-page" data-href="https://www.facebook.com/MineInsiders?_rdc=1&amp;_rdr" data-tabs="timeline" data-width="" data-height="" data-small-header="false" data-adapt-container-width="true" data-hide-cover="false" data-show-facepile="true">
                    <blockquote cite="https://www.facebook.com/MineInsiders?_rdc=1&amp;_rdr" class="fb-xfbml-parse-ignore"><a href="https://www.facebook.com/MineInsiders?_rdc=1&amp;_rdr">MineInsiders</a></blockquote>
                  </div>
                </mat-card>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-12">
            <div class="row">
              <div class="col-sm-12 col-md-12 col-lg-12">
                <mat-card style="margin-bottom: 10px">
                  <div id="embed" style="height: 400px; width:100%; "></div>
                </mat-card>
              </div>
              <div class="col-sm-12 col-md-12 col-lg-12">
                <br>
                <mat-card style="margin-bottom: 10px" *ngIf="false">
                  Dollar rate
                </mat-card>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-12">
            <div class="row">
              <!-- Qui sommes nous -->
              <div class="col-sm-12 col-md-12 col-lg-12">
               <app-qui-sommes-nous></app-qui-sommes-nous>
              </div>
              <div class="col-sm-12 col-md-12 col-lg-12">
                <br>
                <!--  Pub Google -->
                <mat-card style="margin-bottom: 10px" *ngIf="false">
  
                </mat-card>
              </div>
              <div class="col-sm-12 col-md-12 col-lg-12">
                <br>
                <!--  Pub Google -->
                <mat-card style="margin-bottom: 10px" *ngIf="false">
  
                </mat-card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- #avis -->
  
  <!-- #avis -->
  
  <section id="avis" class="wow fadeInUp" style="margin-top: 100px" *ngIf="temoignages?.length>0">
    <div class="container-fluid">
      <div class="section-header" align="center">
        <h2>Ce qu'ils pensent de nous</h2>
      </div>
      <owl-carousel-o [options]="customOptions">
        <ng-container *ngFor="let slide of temoignages">
          <ng-template carouselSlide [id]="slide.id">
            <p style="text-align: center; margin-bottom: 50px;font-weight: lighter;" class="testimonial blockquote" [innerHtml]="slide?.message"></p>
            <p style="text-align: center;margin-bottom: 20px" class="overview"><b>{{slide?.prenom}} {{slide?.nom}}</b> <br>{{slide?.profession}}</p>
          </ng-template>
        </ng-container>
      </owl-carousel-o>
    </div>
  </section>
  <!-- #partenaire -->
  <section class="wow fadeInUp" style="margin-top: 40px">
    <div class="container-fluid" style="padding-bottom: 40px;">
      <div class="section-header" align="center">
        <h2>Ils nous ont fait confiance</h2>
      </div>
      <div class="d-none d-lg-block d-md-block">
        <div class="row">
          <div class="col-sm-2" *ngFor="let img of images" style="padding-bottom: 20px;">
            <img [src]="imageUrl+img.logo" alt="" style="width: 140px;">
          </div>
        </div>
      </div>
      <div class="row d-lg-none d-md-none" align="center">
        <div class="col-12" *ngFor="let img of images" style="padding-bottom: 20px;">
          <img [src]="imageUrl+img.logo" alt="" style="width: 140px;">
        </div>
      </div>
    </div>
  </section>
</div>
