import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {DocumentService} from '../../../core/services/document.service';
import {Documents} from '../../../shared/components/models/documents';
import {environment} from '../../../../environments/environment';
import {Minier} from '../../../shared/components/models/minier';

@Component({
  selector: 'app-forum',
  templateUrl: './forum.component.html',
  styleUrls: ['./forum.component.scss']
})
export class ForumComponent implements OnInit {
  forumList: Documents[];
  documentImageUri = '';
  loading: boolean = false;
  p: number = 1;
  constructor(private router: Router,
              private documentService: DocumentService) {
    this.documentImageUri = environment.documentImageUri;
  }

  ngOnInit() {
    this.loadForumList();
    window.scrollTo(0, 0);
  }
  showDocDetails(forum: Documents) {
    this.router.navigate(['/forum-detail'] ,{ queryParams: {publication: forum.id} });
  }
  loadForumList() {
    this.loading = true;
    this.documentService.findDocumentByForum().subscribe(ret => {
      if (ret['status'] === 'OK') {
        this.forumList = ret['response'];
        // console.log('list doc forum ', this.forumList);
        this.loading = false;
      } else {
        // console.log('erreur survenu');
        this.loading = false;
      }
    }, error => {
      // console.log('erreur server');
      this.loading = false;
    });
  }
  showDetailsMiniers(miner: Minier) {
    this.router.navigate(['/detail-cadre-mines'] ,{ queryParams: {minerId: miner.id} });
  }
}
