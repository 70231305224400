<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '5px',fullScreenBackdrop:true }"></ngx-loading>

<section id="portfolio" class="wow fadeInUp">
  <div class="container">
    <div class="section-header">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="/accueil">Accueil</a></li>
          <li class="breadcrumb-item active" aria-current="page">Forum des professionnels du mine</li>
        </ol>
      </nav>
    </div>
  </div>

  <div class="container-fluid" style="min-height: 400px">
    <div class="container">

      <div class="card card-body" style="margin-bottom: 10px" *ngFor="let forum of forumList | paginate: { itemsPerPage: 20, currentPage: p }">
          <div class="row" (click)="showDocDetails(forum)" style="cursor: pointer">
            <div class="col-sm-12 col-lg-4 col-md-4 col-xl-4 col-12">
              <div class="text-center">
                <a href="javascript:void(0)">
                  <img style="width: 200px;height: 200px;" [src]="documentImageUri+forum.fileUploadName">
                </a>
              </div>
            </div>
            <div class="col-sm-12 col-lg-8 col-md-8 col-xl-8 col-12">
              <div>
                <a href="javascript:void(0)" (click)="showDocDetails(forum)" style="color: black">
                  <h4 style="font-size: x-large;color: #007cdc">{{forum.nom}}</h4>
                </a>
                <p [innerHtml]="(forum.description | slice:0:200) + '... voir plus'">
                </p>
                <div class="row">
                  <div class="col-sm-12 col-lg-12 col-md-12 col-12 col-xl-12">
                    <span style="font-weight: bold">Publié par :</span>  <a href="javascript:void(0)" (click)="showDetailsMiniers(forum.minier)"> {{forum.minier?.prenom}} {{forum.minier.nom}} </a>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-6 col-lg-6 col-md-6 col-6 col-xl-6">
                    <span style="color: #007de2"><i class="fa fa-calendar"></i>  {{forum.dateCreation | date : 'medium'}}</span>
                  </div>
                  <div class="col-sm-6 col-lg-6 col-md-6 col-6 col-xl-6">
                    <span style="color: #007de2"><i class="fa fa-comment"></i> {{forum.commentCount}} commentaires</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
      <pagination-controls (pageChange)="p = $event"
                           directionLinks="true"
                           autoHide="true"
                           responsive="true"
                           previousLabel="Précédent"
                           nextLabel="Suivant"
                           screenReaderPaginationLabel="Pagination"
                           screenReaderPageLabel="page"
                           screenReaderCurrentLabel="Vous êtes dejà sur la page">
      </pagination-controls>
      <div class="col-md-12 col-lg-12 col-12 col-sm-12" *ngIf="forumList?.length==0" align="center">
        Aucun document forum trouvé
      </div>
    </div>
  </div>
</section>
<!-- #portfolio -->

