import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class TemoignageService {

 
  private serviceUrl: string;
  private httpOptions: any;
  

  constructor(private http: HttpClient) {
   
    this.serviceUrl = environment.apiUrl;
  }

  public findAllTemoignage() {
    return this.http.get(this.serviceUrl + '/temoignages/find/enabled' );
  }
}
