import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {StageService} from '../../../core/services/stage.service';
import {Stage} from '../../../shared/components/models/stage';
import {Minier} from '../../../shared/components/models/minier';
import {Postulant} from '../../../shared/components/models/postulant';
import {PostulantService} from '../../../core/services/postulant.service';
import {ToastService} from '../../../shared/components/utils/toast.service';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-proposition-stage',
  templateUrl: './proposition-stage.component.html',
  styleUrls: ['./proposition-stage.component.scss']
})
export class PropositionStageComponent implements OnInit {
  loading: boolean = false;
  stages: Stage[];
  stage: Stage;
  postulant: Postulant = new Postulant();
  cvData;
  errorMessage;
  p: number = 1;
  postuleDialog: BsModalRef;
  config = {
    backdrop: true,
    ignoreBackdropClick: true
  };
  constructor(private stageService: StageService,
              private router: Router,
              private route: ActivatedRoute,
              private modalService: BsModalService,
              private postulantService: PostulantService,
              private toast: ToastService) {
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      if (params.stage) {
        this.loading = true;
        this.stageService.findStageById(params.stage).subscribe(ret => {
          if (ret['status'] === 'OK') {
            this.stage = ret['response'];
            // console.log('stage', this.stage);
            this.loading = false;
          } else {
            this.loading = false;
            this.router.navigate(['/error']);
          }
        }, error => {
          // console.log('erreur server');
          this.loading = false;
          this.router.navigate(['/error']);
        });
      } else {
        this.loadStages();
      }
    });
    window.scrollTo(0, 0);
  }
  showDetail(stage: Stage) {
    this.router.navigate(['/stages'] ,{ queryParams: {stage: stage.id} });
  }
  showList() {
    this.stage = null;
    this.router.navigate(['/stages']);
  }
  loadStages() {
    this.loading = true;
    this.stageService.findAllEnabled().subscribe(ret => {
      if (ret['status'] === 'OK') {
        this.stages = ret['response'];
        // console.log('list stages ', this.stages);
        this.loading = false;
      } else {
        // console.log('erreur survenu');
        this.loading = false;
      }
    }, error => {
      // console.log('erreur server');
      this.loading = false;
    });
  }
  showDetailsMiniers(miner: Minier) {
    this.router.navigate(['/detail-cadre-mines'] ,{ queryParams: {minerId: miner.id} });
  }

  processUploadCv(event) {
    if (event.target.files.length <= 0) {
      return;
    }
    const reader = new FileReader();
    reader.onload = readerEvent => {
      const imageData = (readerEvent.target as any).result;
      let imageSize = Number(event.target.files[0].size);
      imageSize = imageSize / 1024;
      if (imageSize > 1024 * 1024 * 3) {
        this.errorMessage =  'la taille du fichier pdf ne doit pas depasser 3 Mo';
      } else {
        const parts: any[] = imageData.split(',');
        const extension = (((parts[0].split(';'))[0]).split('/')[1]);
        if (extension !== 'pdf') {
          this.errorMessage =  'Le format du fichier doit être en pdf';
          return;
        } else {
          this.cvData = imageData;
          this.errorMessage = null;
          // console.log('cvData', this.cvData);
        }
      }
    };
    reader.readAsDataURL(event.target.files[0]);
    // this.readFile(event.target.files[0])
    event.stopPropagation();
  }

  showPostuleDialog(template: any) {
    this.postulant = new Postulant();
    this.postuleDialog = this.modalService.show(template, this.config);
  }
  postuler() {
    if (this.cvData) {
      this.errorMessage = null;
      this.postulant.fileData = this.cvData;
      this.postulant.stage = this.stage;
      // console.log('postulant', this.postulant);
      this.loading = true;
      this.postulantService.save(this.postulant).subscribe(ret => {
        if (ret['status'] === 'OK') {
          this.postulant = ret['response'];
          // console.log('postulant saver', this.postulant);
          this.loading = false;
          this.postuleDialog.hide();
          this.toast.info('Cv envoyé avec succès');
        } else {
          // console.log('erreur survenu');
          this.loading = false;
        }
      }, error => {
        // console.log('erreur server');
        this.loading = false;
      });
    } else {
      this.errorMessage =  'Le CV est obligatoire !';
    }
  }
}
