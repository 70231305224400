import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {SearchParam} from '../../shared/components/models/search-param';

@Injectable({
  providedIn: 'root'
})
export class NewsService {

  private serviceUrl: string;
  private httpOptions: any;

  constructor(private http: HttpClient) {
    this.serviceUrl = environment.apiUrl;
  }

  public findAllNews() {
    return this.http.get(this.serviceUrl + '/news/find/enabled', this.httpOptions);
  }

  public findNewsById(newsId) {
    return this.http.get(this.serviceUrl + '/news/findById/' + newsId, this.httpOptions);
  }
  public findLastest() {
    return this.http.get(this.serviceUrl + '/news/find/lastest');
  }
  public findOthers(newsId) {
    return this.http.get(this.serviceUrl + '/news/findOthers/' + newsId);
  }

  public search(searchParam: SearchParam) {
    return this.http.post(this.serviceUrl + '/search',searchParam, this.httpOptions);
  }
}
