<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '5px',fullScreenBackdrop:true }"></ngx-loading>
<ngx-loading-bar color="#fff"></ngx-loading-bar>
<!-- SEND MESSAGE DIALOG-->
<ng-template #sendMessageTemplate>
  <div class="p-2" style="width: 100%">
    <button type="button" class="close pull-right" aria-label="Close" (click)="sendMessageDialog.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <mat-divider></mat-divider>
  <form #messageForm="ngForm" name="form">
    <div class="modal-body">
    <div class="text-center mb-2" style="color: #007ef0; background: #ffffff;">
      <div class="mt-2">
          <mat-form-field style="width: 100%">
            <input matInput  name="nom" [(ngModel)]="message.nom" placeholder="Nom complet" required>
          </mat-form-field>
          <br>
          <mat-form-field style="width: 100%">
            <input matInput  name="objet" [(ngModel)]="message.objet" placeholder="Objet" required>
          </mat-form-field>
          <br>
          <mat-form-field style="width: 100%">
            <input matInput  name="email" [(ngModel)]="message.email" placeholder="Email" required>
          </mat-form-field>
          <br>
          <mat-form-field style="width: 100%">
            <textarea matInput name="description" cdkTextareaAutosize="400" [(ngModel)]="message.description"  placeholder="Message" required></textarea>
          </mat-form-field>
      </div>
    </div>
    <div class="mt-2">
      <span style="color: #b21f2d">* </span> <span style="color: black">Les champs obligatoires</span>
    </div>
    <mat-divider></mat-divider>
    <div class="row mt-4">
      <div class="col-12 text-center">
        <button style="color: #fff; background: #007ef0" [disabled]="!messageForm.form.valid" class="btn" (click)="sendMessage()">Envoyer</button>
      </div>
    </div>
  </div>
  </form>
</ng-template>
<!-- SEND MESSAGE DIALOG-->

<section id="team" class="wow fadeInUp" style="margin-bottom: 20px">

  <div class="container">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a href="javascript:void(0)" routerLink="/accueil">Acceuil</a></li>
        <li class="breadcrumb-item"><a (click)="backClicked()" href="javascript:void(0)">{{previousPage}}</a></li>
        <li class="breadcrumb-item active" aria-current="page">{{minier?.prenom}} {{minier?.nom}}</li>
      </ol>
    </nav>
  </div>

  <div class="container">
    <!-- ******HEADER****** -->
    <header class="header">
      <div class="container">
        <div class="teacher-name" style="padding-top:20px;">
          <div class="row" style="margin-top:0px;">
            <div class="col-md-9">
              <h2 style="font-size:38px">{{minier?.prenom}}  {{minier?.nom}}</h2>
            </div>
          </div>
        </div>

        <div class="row" style="margin-top:20px;">
          <div class="col-md-3"> <!-- Image -->
            <a href="javascript:void(0)"> <img class="rounded-circle" [src]="profilImageUri+minier?.profilImage" alt="profile" style="width:200px;height:200px"></a>
          </div>

          <div class="col-md-6"> <!-- Rank & Qualifications -->
            <h5 style="color:#017ee7">{{minier?.specialite}}</h5>
            <p>{{minier?.ecole?.nom}}.</p>
            <p>Adresse: {{minier?.ville}}, {{minier?.pays?.nom}}</p>
          </div>

          <div class="col-md-3 text-center"> <!-- Phone & Social -->
            <div class="button" style="padding-top:18px">
              <a href="javascript:void(0)" class="btn btn-outline-primary btn-block" (click)="openSendMessage(sendMessageTemplate)">Envoyer un message</a>
            </div>
            <div class="button" style="padding-top:18px" *ngIf="false">
              <a href="mailto:ahmkctg@yahoo.com" class="btn btn-outline-primary btn-block">Afficher numéro de telephone</a>
            </div>
            <div class="social-icons" style="padding-top:18px" *ngIf="false">
              <a href="#">
            <span class="fa-stack fa-lg">
              <i class="fa fa-circle fa-stack-2x" style="color:#017ee7"></i>
              <i class="fa fa-linkedin fa-stack-1x fa-inverse"></i>
            </span></a>
              <a href="#">
            <span class="fa-stack fa-lg">
              <i class="fa fa-circle fa-stack-2x" style="color:#017ee7"></i>
              <i class="fa fa-google-plus fa-stack-1x fa-inverse"></i>
            </span></a>
              <a href="#">
            <span class="fa-stack fa-lg">
              <i class="fa fa-circle fa-stack-2x" style="color:#017ee7"></i>
              <i class="fa fa-facebook fa-stack-1x fa-inverse"></i>
            </span></a>

            </div>
          </div>
        </div>
      </div>
    </header>
    <!--End of Header-->

    <!-- Main container -->
    <div class="container">

      <!-- Section:Presentation -->
      <div class="row">
        <div class="col-md-12">
          <div class="card card-block text-xs-left">
            <h3 class="card-title" style="color:#017ee7;padding: 10px;font-size: medium"><i class="fa fa-list fa-fw"></i>   Présentation</h3>
            <div style="height: 15px"></div>
            <p style="padding-left: 20px;padding-right: 20px" [innerHtml]="minier?.presentation"></p>
          </div>
        </div>
      </div>
      <!-- End:Presentation -->

      <!--Formation-->
      <div class="row">
        <div class="col-md-12">
          <div class="card card-block">
            <h3 class="card-title" style="color:#017ee7;font-size: medium;padding: 10px"><i class="fa fa-graduation-cap fa-fw"></i>   Formation</h3>
            <p style="padding:20px" [innerHtml]="minier?.formation">
            </p>
          </div>
        </div>
      </div>
      <!-- End :Formation -->

      <!--Experiences-->
      <div class="row">
        <div class="col-md-12">
          <div class="card card-block">
            <h3 class="card-title" style="color:#017ee7;font-size: medium;padding: 10px"><i class="fa fa-suitcase fa-fw"></i>    Experience</h3>
            <p style="padding:20px" [innerHtml]="minier?.curriculum">
            </p>
          </div>
        </div>
      </div>
      <!-- End :Experiences -->

    </div> <!--End of Container-->
  </div>
</section>
<!-- #team -->
