<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '5px',fullScreenBackdrop:true }"></ngx-loading>
<!-- <div *ngIf="addPartenaire"></div> -->
<section id="inscription">
  <div class="container">
    <div class="section-header">
      <h2>Inscription partenaires</h2>
    </div>
    <div *ngIf="!infosPartenaire">
      <h3 style="font-family: Montserrat,sans-serif;font-weight: 500;margin: 0 0 20px;">Vous êtes partenaires des mines, enregistrer vous en precisant vos services pour gagner en visibilité.</h3>
    </div>
    <div *ngIf="infosPartenaire">
      <br>
      <br>
      <br>
      <header class="card-header">
        <div class="card-title mt-2" style="text-align: center;">
          <span style="font-weight: bolder;color:green"> Votre Inscription en tant que partenaire a été prise en compte avec succès !</span><br>
          <span style="font-weight: bolder;color:green"> Nous vous contacterons sous peu pour completer le reste de la procedure Merci.</span>
        </div>
      </header>
      <article class="card-body">
        <div class="form-group" align="center" >
          <button type="button"   class="btn btn-primary"  routerLink="/accueil"> aller à page d'accueil </button>
        </div>
      </article> <!-- card-body end .// -->
      <br>
      <br>
      <br>
      <br>
    </div>
    <div class="row justify-content-center" style="margin-bottom: 40px" *ngIf="!infosPartenaire">
      <div class="col-md-8">
        <div class="card">

          <header class="card-header">
            <div class="card-title mt-2" style="text-align: center">
              <span style="font-weight: bold">Appelez (heure de travail) / contacter via <i class="fa fa-whatsapp"></i> <a href="https://api.whatsapp.com/send?phone=22365128589" target="_blank"> +223 65 12 85 89</a> ou... </span><br><br>
              <span style="font-weight: bolder">Veuillez remplir le formulaire suivant pour que nous vous rappelions afin d'enregistrer votre business aujourd'hui.</span>
            </div>
          </header>
          <article class="card-body">
            <form #partenaireForm="ngForm" name="form">
              <div class="form-row">
                <div class="form-group col-md-4">
                  <label> Objet de votre demande <span style="color: red">*</span></label>
                </div> <!-- form-group end.// -->
                <div class="form-group col-md-6">
                  <select id="inputStateObjet" name="objet" [(ngModel)]="partenaire.objet" required class="form-control" style="height: 34px">
                    <option value="Publicité"> Publicité</option>
                    <option value="Autre Partenariat"> Autre Partenariat</option>
                  </select>
                </div> <!-- form-group end.// -->
              </div> <!-- form-row.// -->
              <div class="form-row">
                <div class="form-group col-md-4">
                  <label>Nom du Business <span style="color: red">*</span></label>
                </div> <!-- form-group end.// -->
                <div class="form-group col-md-6">
                  <input type="text" name="nomEntreprise" [(ngModel)]="partenaire.nomEntreprise" class="form-control" required="">
                </div>
              </div> <!-- form-row end.// -->
              <br>
              <div class="form-row">
                <div class="form-group col-md-4">
                  <label> Etat du Business <span style="color: red">*</span></label>
                </div> <!-- form-group end.// -->
                <div class="form-group col-md-6">
                  <select id="inputState" name="pays" [(ngModel)]="partenaire.pays" required class="form-control" style="height: 34px">
                    <option> Choose...</option>
                    <option [value]="pays.nom" *ngFor="let pays of paysList">{{pays.nom}}</option>
                  </select>
                </div> <!-- form-group end.// -->
              </div> <!-- form-row.// -->

              <div class="form-row">
                <div class="form-group col-md-4">
                  <label>Votre Nom et Prenom <span style="color: red">*</span></label>
                </div> <!-- form-group end.// -->
                <div class="form-group col-md-6">
                  <input type="text" name="prenom" [(ngModel)]="partenaire.prenom" class="form-control" required="">
                </div>
              </div> <!-- form-row end.// -->

              <div class="form-row">
                <div class="form-group col-md-4">
                  <label>Votre Telephone <span style="color: red">*</span></label>
                </div> <!-- form-group end.// -->
                <div class="form-group col-md-6">
                  <input type="number" name="telephone" [(ngModel)]="partenaire.telephone" class="form-control" required="">
                </div>
              </div> <!-- form-row end.// -->

              <div class="form-row">
                <div class="form-group col-md-4">
                  <label>Votre email<span style="color: red">*</span></label>
                </div> <!-- form-group end.// -->
                <div class="form-group col-md-6">
                  <input type="text" name="email" [(ngModel)]="partenaire.email" class="form-control" required="">
                </div>
              </div> <!-- form-row end.// -->

              <div class="form-row">
                <div class="form-group col-md-4">
                  <label>Votre Lieu de Travail <span style="color: red">*</span></label>
                </div> <!-- form-group end.// -->
                <div class="form-group col-md-6">
                  <input type="text" name="lieu" [(ngModel)]="partenaire.lieu" class="form-control" required="">
                </div>
              </div> <!-- form-row end.// -->
              <br>
              <div class="form-row">
                <div class="form-group col-md-4">
                  <label>Autres infos </label>
                </div> <!-- form-group end.// -->
                <div class="form-group col-md-6">
                  <textarea for="" name="autreInfos" [(ngModel)]="partenaire.autreInfos" class="form-control">
                  </textarea>
                </div>
              </div> <!-- form-row end.// -->
              <hr>
              <br>
              <div class="form-group" align="center" >
                <button type="button" *ngIf="!partenaire.id" [disabled]="!partenaireForm.form.valid" (click)="onSave()" class="btn btn-primary"> Envoyer </button>
              </div> <!-- form-group// -->
              <div class="pull-right">
                <span style="color: red;font-size: small">* les champs obligatoires</span>
              </div>
            </form>
          </article> <!-- card-body end .// -->
        </div> <!-- card.// -->
      </div> <!-- col.//-->

    </div> <!-- row.//-->
  </div>
</section>

