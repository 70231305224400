<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '5px',fullScreenBackdrop:true }"></ngx-loading>
<ngx-loading-bar color="#fff"></ngx-loading-bar>
<section id="portfolio" class="wow fadeInUp">
  <div class="container-fluid">
    <div class="section-header">
      <nav aria-label="breadcrumb" *ngIf="!events">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="#">Accueil</a></li>
          <li class="breadcrumb-item active" aria-current="page">Mining Events</li>
        </ol>
      </nav>
      <nav aria-label="breadcrumb" *ngIf="events">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="#">Accueil</a></li>
          <li class="breadcrumb-item"><a href="javascript:void(0)" (click)="showList()">Mining Events</a></li>
          <li class="breadcrumb-item active" aria-current="page">{{events.titre}}</li>
        </ol>
      </nav>
    </div>
  </div>

  <div class="container" *ngIf="!events" style="min-height: 400px">
    <div class="card card-body" style="margin-bottom: 10px" *ngFor="let events of eventList | paginate: { itemsPerPage: 30, currentPage: p }">
      <div class="row" (click)="showDetail(events)" style="cursor: pointer">
        <div class="col-sm-12 col-lg-4 col-md-4 col-xl-4 col-12" >
          <div class="text-center">
            <a href="javascript:void(0)">
              <img class="img-thumbnail"  style="height: 120px;width: 200px" [src]="eventsImageUri+events.imageName">
            </a>
          </div>
        </div>
        <div class="col-sm-12 col-lg-8 col-md-8 col-xl-8 col-12">
          <div>
            <a href="javascript:void(0)" (click)="showDetail(events)" style="color: black">
              <h4 style="font-size: 18px;color: #007cdc">{{events.titre}}</h4>
            </a>
            <p [innerHtml]="(events.description | slice:0:500) + '... voir plus'">
            </p>
            <div class="row">
              <div class="col-sm-6 col-lg-6 col-md-6 col-6 col-xl-6">
                <span style="color: #959595"><i class="fa fa-calendar"></i>  {{events.dateCreation | date : 'medium'}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <pagination-controls (pageChange)="p = $event"
                         directionLinks="true"
                         autoHide="true"
                         responsive="true"
                         previousLabel="Précédent"
                         nextLabel="Suivant"
                         screenReaderPaginationLabel="Pagination"
                         screenReaderPageLabel="page"
                         screenReaderCurrentLabel="Vous êtes dejà sur la page">
    </pagination-controls>
    <div class="col-md-12 col-lg-12 col-12 col-sm-12" *ngIf="eventList?.length==0" align="center">
      Aucun évènement trouvé !
    </div>
  </div>

  <div class="container" *ngIf="events" style="min-height: 400px">
    <div class="row" style="padding: 10px">
      <div class="col-sm-12 col-md-8 col-lg-8">
        <mat-card>
          <app-pub-b></app-pub-b>
        </mat-card>
      </div>
      <div class="col-sm-12 col-md-4 col-lg-4">
        <mat-card style="background-color: #007cdc;padding-top: 10px;color: #FFFFFF;margin: 0">
          <div class="row">
            <div class="col-sm-12 col-12">
              <h3 style="font-size: 14px">Vous êtes miniers? inscrivez vous gratuitement pour gagner en visibilité!</h3>
            </div>
            <div class="col-sm-12 col-12">
              <button class="btn btn-grey" routerLink="/register">Je m'inscris gratuitement</button>
            </div>
          </div>
        </mat-card>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-8 col-md-8 col-sm-12">
        <div class="col-lg-12 col-md-12 col-sm-12">
          <!-- the actual blog post: title/author/date/content -->
          <h1><a style="color: #027be1" href="javascript:void(0)">{{events.titre}}</a></h1>
          <hr>
          <p><i class="fa fa-calendar"></i> Publié le {{events?.datePublication | date: 'dd/MM/yyyy'}}</p>
          <hr>
          <img [src]="eventsImageUri+events?.imageName" width="100%">
          <hr>
          <share-buttons theme="modern-light"
                         [include]="['facebook','twitter','linkedin','telegram','whatsapp','email','print']"
                         [show]="8"
                         [size]="2"
                         [url]="'https://mineinsiders.com/#/mining-events?news='+ events.id"
                         [image]="eventsImageUri+events?.imageName"
                         [title]="events.titre"
                         [description]="events.titre"
                         [autoSetMeta]="true"
          ></share-buttons>
          <br/>
          <p [innerHtml]="events?.description"></p>
          <br/>
          <br/>
          <br/>
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12">
          <mat-card style="margin-bottom: 10px">
            <app-pub-e></app-pub-e>
          </mat-card>
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12">
          <div class="row" style="margin-bottom: 10px">
            <div class="col-lg-6 col-md-6 col-sm-12">
              <mat-card *ngIf="otherEvents.length>0" style="height: 460px;margin-bottom: 10px">
                <img mat-card-image class="img-article" src={{eventsImageUri+otherEvents[0]?.imageName}} alt="">
                <mat-card-content>
                </mat-card-content>
                <mat-card-actions class="action-buttons">
                  <mat-card-title style="font-size: 18px" class="title">{{otherEvents[0]?.titre}}</mat-card-title>
                  <a mat-button color="primary" [href]="'#/mining-events?event=' + otherEvents[0]?.id" target="_blank" ><mat-icon>visibility</mat-icon> En savoir plus</a>
                </mat-card-actions>
              </mat-card>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12">
              <mat-card *ngIf="otherEvents.length>0" style="height: 460px;margin-bottom: 10px">
                <img mat-card-image class="img-article" src={{eventsImageUri+otherEvents[1]?.imageName}} alt="">
                <mat-card-content>
                </mat-card-content>
                <mat-card-actions class="action-buttons">
                  <mat-card-title style="font-size: 18px" class="title">{{otherEvents[1]?.titre}}</mat-card-title>
                  <a mat-button color="primary" [href]="'#/mining-events?event=' + otherEvents[1]?.id" target="_blank" ><mat-icon>visibility</mat-icon> En savoir plus</a>
                </mat-card-actions>
              </mat-card>
            </div>
          </div>
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12">
          <mat-card>
            <div class="fb-comments" data-href="https://mineinsiders.com/#/mining-events" data-width="" data-numposts="5"></div>
          </mat-card>
        </div>
      </div>
      <div class="col-lg-4 col-md-4 col-sm-12">
        <div class="row">
          <div class="col-sm-12 col-md-12 col-lg-12">
            <mat-card style="margin-bottom: 10px">
              <app-pub-c></app-pub-c>
            </mat-card>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-12">
            <mat-card style="margin-bottom: 10px">
              <div class="col-sm-12 col-12">
                <button style="background-color: #047bdd;border-color: #007ce2;" class="btn btn-primary" routerLink="/guides">Consulter gratuitement nos guides
                </button>
              </div>
            </mat-card>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-12">
            <mat-card style="margin-bottom: 10px">
              <app-pub-d></app-pub-d>
            </mat-card>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-12">
            <mat-card style="margin-bottom: 10px">
              <div class="section-header">
                <h2> Articles recents</h2>
              </div>
              <app-article-recents></app-article-recents>
            </mat-card>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-12">
            <div class="row">
              <!-- Qui sommes nous -->
              <div class="col-sm-12 col-md-12 col-lg-12">
                <app-qui-sommes-nous></app-qui-sommes-nous>
              </div>
              <div class="col-sm-12 col-md-12 col-lg-12">
                <br>
                <!--  Pub Google -->
                <mat-card style="margin-bottom: 10px" *ngIf="false">

                </mat-card>
              </div>
              <div class="col-sm-12 col-md-12 col-lg-12">
                <br>
                <!--  Pub Google -->
                <mat-card style="margin-bottom: 10px" *ngIf="false">

                </mat-card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- #mining events -->
