
<section id="ressources" class="wow fadeInUp" style="min-height: 500px;margin-bottom: 20px">
  <div class="container">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a href="#">Acceuil</a></li>
        <li class="breadcrumb-item"><a href="javascript:void (0)" (click)="goToRessources()">Ressources</a></li>
        <li class="breadcrumb-item active" aria-current="page">{{ressource?.nom}}</li>
      </ol>
    </nav>
  </div>
  <div class="container">
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-12">
        <mat-tab-group>
          <mat-tab [label]="ressource?.nom">
            <p [innerHtml]="ressource?.description"></p>
          </mat-tab>
          <mat-tab [label]="'Les mines qui produisent ' + ressource?.nom">
            <div *ngIf="ressource?.entreprises">
              <div style="font-weight: bold;color: #1a68ff" *ngFor="let e of ressource?.entreprises">
                {{e?.nom}}
              </div>
            </div>
            <span *ngIf="!ressource?.entreprises" style="font-weight: bold;color: #ce2c34">Aucune Entreprise trouvée</span>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </div>
</section>

