<!-------------HEADER-------------->
<!-- <app-header></app-header> -->
<!-------------HEADER-------------->
<!-- #about -->
<section id="about" class="wow fadeInUp">
  <div class="container">
    <div class="section-header">
      <h2>Qui Sommes nous ?</h2>
    </div>

    <div class="row">
      <div class="col-lg-6 about-img">
        <img src="assets/img/about.jpg" alt="" />
      </div>

      <div class="col-lg-6 content">
        <h3>
          <span style="color: #007cdc">Mine </span><span style="color: #97969b">Insiders</span> est une plateforme d'information et de mise en relation des partenaires et professionnels du monde minier de l'Afrique de l'Ouest.
        </h3>
        <ul>
          <li>
            <i class="ion-android-checkmark-circle"></i>Les ingénieurs ou experts miniers: qui peuvent facilement s’échanger des expériences, documents et ou des opportunités d’emploi ou de projet,
          </li>
          <li>
            <i class="ion-android-checkmark-circle"></i>Les entreprises qui peuvent facilement trouver de la compétence adaptée à leurs besoins spécifiques ou publier des offres d'emploi pour la communauté des miniers, et ou voir directement des profils de professionnels qualifiés pour leur projet minier,
          </li>
          <li>
            <i class="ion-android-checkmark-circle"></i>Les partenaires ou prestataires de services miniers: ils peuvent facilement présenter leur produit, leur service et ou leurs références sur notre page pour gagner en visibilité, s'ouvrir sur le plan international en gagnant encore plus de marchés ou contrats dans le secteur minier Ouest Africain.
          </li>
        </ul>
        <h3>Veuillez nous contacter via  <a href="mailto:contact@mineinsiders.com">contact@mineinsiders.com</a> pour plus d'informations. </h3>
      </div>
    </div>
  </div>
</section>
<!-- #about -->
<!-------------HEADER-------------->
<!-- <app-footer></app-footer> -->
<!-------------HEADER-------------->
