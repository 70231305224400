import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PubliciteService {

  private serviceUrl: string;
  private httpOptions: any;


  constructor(private http: HttpClient) {

    this.serviceUrl = environment.apiUrl;
  }

  public findAllPublicite() {
    return this.http.get(this.serviceUrl + '/publicite');
  }

  public findPubA() {
    return this.http.get(this.serviceUrl + '/publicite/A');
  }

  public findPubB() {
    return this.http.get(this.serviceUrl + '/publicite/B');
  }
  public findPubC() {
    return this.http.get(this.serviceUrl + '/publicite/C');
  }
  public findPubD() {
    return this.http.get(this.serviceUrl + '/publicite/D');
  }
  public findPubE() {
    return this.http.get(this.serviceUrl + '/publicite/E');
  }
  public findPubF() {
    return this.http.get(this.serviceUrl + '/publicite/F');
  }
  public findPubG() {
    return this.http.get(this.serviceUrl + '/publicite/G');
  }

}
