import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import { MineInsidersUtils } from 'src/app/shared/components/utils/mineInsiders-util';
import {Message} from '../../shared/components/models/message';
import {Contact} from '../../shared/components/models/contact';

@Injectable({
  providedIn: 'root'
})
export class MessageService {
  private serviceURL: string;
  private httpOptions: any;

  constructor(private http: HttpClient) {
    this.serviceURL = environment.apiUrl;
    this.httpOptions = new MineInsidersUtils().httpHeaders();
  }

  public save(message: Message) {
    return this.http.post(this.serviceURL + '/message', message, this.httpOptions);
  }

  public loadMessage(minerId) {
    return this.http.get(this.serviceURL + '/message/find/' + minerId, this.httpOptions);
  }
  public getUnSeenMessageCount(minerId) {
    return this.http.get(this.serviceURL + '/message/find/noSee/' + minerId, this.httpOptions);
  }

  public update(message: Message) {
    return this.http.put(this.serviceURL + '/message', message, this.httpOptions);
  }

  public sendContactMessage(contact: Contact) {
    return this.http.post(this.serviceURL + '/contact', contact, this.httpOptions);
  }
}
