import {Injectable} from '@angular/core';
import {HttpHeaders} from '@angular/common/http';
import {Utilisateur} from '../models/utilisateur';
import {Droit} from '../models/droit';

import {AppConfig} from './app-config';
import {Minier} from '../models/minier';


@Injectable()
export class MineInsidersUtils {

  static now = new Date();

  constructor() {
  }

  static dateDebut() {
    return {year: this.now.getFullYear(), month: this.now.getMonth() + 1, day: this.now.getDate() - 7};
  }

  static dateFin() {
    return {year: this.now.getFullYear(), month: this.now.getMonth() + 1, day: this.now.getDate()};
  }

  static formatDate(date) {
    let d = new Date(Date.parse(date));
    return (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + '/' + ((date.getMonth() + 1) < 10 ? '0' + ((date.getMonth() + 1)) : ((date.getMonth() + 1))) + '/' + date.getFullYear();
  }

  static formatStringtoDate(date) {
    let d = new Date(Date.parse(date));

    return d.getFullYear() + '-' + ((d.getMonth() + 1) < 10 ? '0' + ((d.getMonth() + 1)) : ((d.getMonth() + 1))) + '-' + (d.getDate() < 10 ? '0' + d.getDate() : d.getDate());
  }

  static formatDateYMD(date) {
    return date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
  }

  static formatDateTo(date) {
    return (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + '/' + ((date.getMonth() + 1) < 10 ? '0' + ((date.getMonth() + 1)) : ((date.getMonth() + 1))) + '/' + date.getFullYear() + ' ' + date.getHours() + ':' + date.getMinutes() + ':' + date.getSeconds();
  }

  static formatDateToString(date) {
    return date.year + '-' + (parseInt(date.month) < 10 ? '0' + parseInt(date.month) : parseInt(date.month)) + '-' + (parseInt(date.day) < 10 ? '0' + parseInt(date.day) : parseInt(date.day));
  }

  static addDays(date, numberOfDay) {
    return new Date(date.getTime() + numberOfDay * 24 * 60 * 60 * 1000);
  }

  static subDays(date, numberOfDay) {
    return new Date(date.getTime() - numberOfDay * 24 * 60 * 60 * 1000);
  }

  httpHeaders() {
    let headers = new HttpHeaders();

    headers = headers.set('Accept', 'application/json');
    headers = headers.set('Content-Type', 'application/json');
    const httpOptions = {
      headers: headers
    };
    return httpOptions;
  }

  public getCurrentUser(): Minier {
    if (localStorage.getItem('mine-insiders')) {
      const minier: Minier = JSON.parse(localStorage.getItem('mine-insiders'));
      return minier;
    }
  }

  fomatDateFromJson(date) {
    let dateSplit;
    if (typeof  date === 'string') {
      dateSplit = date.replace('T', ' ').replace('.000Z', '');
      dateSplit = new Date(dateSplit);
    } else {
      dateSplit = date;
    }
    return dateSplit;
  }
}
