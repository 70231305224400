import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Postulant} from '../../shared/components/models/postulant';

@Injectable({
  providedIn: 'root'
})
export class PostulantService {

  private serviceUrl: string;
  private httpOptions: any;


  constructor(private http: HttpClient) {
    this.serviceUrl = environment.apiUrl;
  }

  public findPostulantByStage(stageId) {
    return this.http.get(this.serviceUrl + '/postulant/findByStage/' + stageId, this.httpOptions);
  }

  public save(postulant: Postulant) {
    return this.http.post(this.serviceUrl + '/postulant/', postulant, this.httpOptions);
  }
}
