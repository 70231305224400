import { Component, OnInit } from '@angular/core';
import { Opportunite } from 'src/app/shared/components/models/opportunite';
import { Router } from '@angular/router';
import { OpportuniteService } from 'src/app/core/services/opportunite.service';
import { environment } from 'src/environments/environment';
import {LoadingBarService} from '@ngx-loading-bar/core';

@Component({
  selector: 'app-jobs',
  templateUrl: './jobs.component.html',
  styleUrls: ['./jobs.component.css']
})
export class JobsComponent implements OnInit {
  jobList: Opportunite[];
  loading: boolean = false;
  p: number = 1;
  opportuniteImageUri: any;
  constructor(private router: Router,
              private opportuniteService: OpportuniteService,
              private loadingBar: LoadingBarService) {
    this.opportuniteImageUri = environment.opportuniteImageUri;
  }

  ngOnInit() {
    this.loadJobList();
    window.scrollTo(0, 0);
  }
  showJobsDetails(job: Opportunite) {
    this.router.navigate(['/Jobs-detail'] ,{ queryParams: {opportunite: job.id} });
  }
  loadJobList() {
    this.loadingBar.start();
    this.opportuniteService.findAllOpportunite().subscribe(ret => {
      if (ret['status'] === 'OK') {
        this.jobList = ret['response'];
       // console.log('+++++ list Job ', this.jobList);
        this.loadingBar.complete();
      } else {
        // console.log('erreur survenu');
        this.loadingBar.complete();
      }
    }, error => {
      // console.log('erreur server');
      this.loadingBar.complete();
    });
  }
}
