import { Component, OnInit } from '@angular/core';
import {LoadingBarService} from '@ngx-loading-bar/core';
import {OpportuniteService} from '../../../core/services/opportunite.service';
import {NewsService} from '../../../core/services/news.service';
import {News} from '../models/news';
import {Opportunite} from '../models/opportunite';

@Component({
  selector: 'app-article-recents',
  templateUrl: './article-recents.component.html',
  styleUrls: ['./article-recents.component.scss']
})
export class ArticleRecentsComponent implements OnInit {
  jobList: Opportunite[] = [];
  newses: News[] = [];
  constructor(private newsService: NewsService,
              private loadingBar: LoadingBarService,
              private opportuniteService: OpportuniteService,) { }

  ngOnInit(): void {
    this.loadLatestJobs();
    this.loadLatestNews();
  }
  loadLatestJobs() {
    this.opportuniteService.findLastest().subscribe(ret => {
      if (ret['status'] === 'OK') {
        this.jobList = ret['response'];
        // console.log('+++++ recent Jobs', this.jobList);
      } else {
        // console.log('erreur survenu');
      }
    }, error => {
      // console.log('erreur server');
    });
  }

  loadLatestNews() {
    this.newsService.findLastest().subscribe(ret => {
      if (ret['status'] === 'OK') {
        this.newses = ret['response'];
        // console.log('+++++ recent news ', this.newses);
      } else {
        // console.log('erreur survenu');
      }
    }, error => {
      // console.log('erreur server');
    });
  }
}
