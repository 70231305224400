import { Component, OnInit } from '@angular/core';
import {Publicite} from '../../../shared/components/models/publicite';
import {PubliciteService} from '../../../core/services/publicite.service';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-pub-b',
  templateUrl: './pub-b.component.html',
  styleUrls: ['./pub-b.component.scss']
})
export class PubBComponent implements OnInit {
  pubB: Publicite;
  publicites: Publicite[] = [];
  publiciteImageUri: string;
  constructor(private publiciteService: PubliciteService) {
    this.publiciteImageUri = environment.publiciteImageUri;
  }

  ngOnInit(): void {
    this.loadPublicite();
  }

  loadPublicite() {
    this.publiciteService.findPubB().subscribe(ret => {
      if (ret['status'] === 'OK') {
        this.publicites = ret['response'];
        // console.log(this.publicites);
        this.pubB = this.publicites.find(p=>p.type==='B');
      } else {
        // console.log('erreur survenu');
      }
    }, error => {
      // console.log('erreur server');
    });
  }
}
