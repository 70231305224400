<!-- Footer -->
<div class="row" style="margin-top: 50px">
  <!--  Pub Google -->
  <mat-card style="width: 100%">

  </mat-card>
</div>
<section id="footer">
  <div class="container">
    <div class="row text-center text-xs-center text-sm-left text-md-left">
      <div class="col-12 col-sm-6 col-md-6">
        <h5>À propos</h5>
        <p style="color: #FFFFFF">
          Mine Insiders est une plateforme d'information et de mise en relation des partenaires et professionnels du monde minier de l'Afrique de l'Ouest.</p>
        <p style="color: #FFFFFF">
          Les ingénieurs ou experts miniers: qui peuvent facilement s’échanger des expériences, documents et ou des opportunités d’emploi ou de projet,</p>
        <p style="color: #FFFFFF">
          Les entreprises qui peuvent facilement trouver de la compétence adaptée à leurs besoins spécifiques ou publier des offres d'emploi pour la communauté des miniers, et ou voir directement des profils de professionnels qualifiés pour leur projet minier,        </p>
        <p style="color: #FFFFFF">
          Les partenaires ou prestataires de services miniers: ils peuvent facilement présenter leur produit, leur service et ou leurs références sur notre page pour gagner en visibilité, s'ouvrir sur le plan international en gagnant encore plus de marchés ou contrats dans le secteur minier Ouest Africain.        </p>
      </div>
      <div class="col-12 col-sm-3 col-md-3">
        <h5>Contact</h5>
        <ul class="list-unstyled quick-links">
          <li><a href="javascript:void(0);"><i class="fa fa-location-arrow"></i> Richmond Avenue, Houston TX 77082, Texas, United States</a></li>
          <li><a href="javascript:void(0);"><i class="fa fa-envelope"></i> contact@mineinsiders.com</a></li>
        </ul>
      </div>
      <div class="col-xs-12 col-sm-3 col-md-3">
        <h5>Suivez-nous</h5>
        <ul class="list-unstyled list-inline social text-center">
          <li class="list-inline-item"><a href="https://web.facebook.com/MineInsiders" target="bank"><i class="fa fa-facebook" style="color: #fafbff;"></i></a></li>
          <li class="list-inline-item"><a href="https://twitter.com/Mineinsiders?s=20" target="bank"><i class="fa fa-twitter" style="color: #fafbff;"></i></a></li>
          <li class="list-inline-item"><a href="https://www.linkedin.com/company/mineinsiders" target="bank"><i class="fa fa-linkedin" style="color: #fafbff;"></i></a></li>
          <li class="list-inline-item"><a href="https://www.instagram.com/mineinsiders/" target="bank"><i class="fa fa-instagram" style="color: #fafbff;"></i></a></li>
        </ul>
        <hr/>
        </div>
    </div>
    <br>
    <div class="row" style="padding-left: 10px">
      <ul class="list-unstyled quick-links">
        <li><a href="javascript:void(0)" routerLink="/mention-legale"> Mentions légales</a></li>
        <li><a href="javascript:void(0)" routerLink="/condition-generale"> Conditions générales d'utilisation</a></li>
      </ul>
    </div>
  </div>
</section>
<section style="background-color: #FFFFFF">
  <div class="row">
    <!-- <div class="col-12 col-sm-12 col-md-6 col-lg-6">
      <div id="logo" class="pull-left" style="margin: 20px">
        <a href="javascript:void (0)">
          <img src="assets/img/logo/mineInsiders.jpeg" style="height: 50px; width: 294px" alt="" title="" />
        </a>
      </div>
    </div> -->
    <div class="col-12 col-sm-12 col-md-12 col-lg-12" align="center" style="padding: 10px">
      <p class="h6">Copyright © mineinsiders.com 2020 tous droits réservés, Design by <a class="text-green ml-2" href="https://ngsystem.net" target="_blank">NG System Technology.</a></p>
    </div>
    <hr/>
  </div>
</section>
<!-- ./Footer -->
