import {AfterViewInit, Component, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { MyMatDialogConfig } from 'src/app/configs/mat-dialog-config';
import { Utilisateur } from '../models/utilisateur';
import { Profile } from '../models/profile';
import {MineInsidersUtils} from '../utils/mineInsiders-util';
import {Minier} from '../models/minier';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {MatDialog} from '@angular/material/dialog';
import {SearchParam} from '../models/search-param';
declare var $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, AfterViewInit  {
  users: Utilisateur[] = [];
  profiles: Profile[] = [];
  user: Utilisateur = new Utilisateur();
  currentUser: Minier;
  confirmLogoutModalRef: BsModalRef;
  config = {
    backdrop: true,
    ignoreBackdropClick: true
  };
  searchParam: SearchParam = new SearchParam();
  constructor(
    private router: Router,
    private matDialogConfig: MyMatDialogConfig,
    public dialog: MatDialog,
    private modalService: BsModalService,
  ) {
    this.currentUser = new MineInsidersUtils().getCurrentUser();
  }

  ngOnInit() {
  }
  ngAfterViewInit() {
    // loading templates js after dom render
    /*$.getScript('assets/lib/jquery/jquery.min.js', () => {
    });
    $.getScript('assets/lib/jquery/jquery-migrate.min.js', () => {
    });
    $.getScript('assets/lib/bootstrap/js/bootstrap.bundle.min.js', () => {
    });
    $.getScript('assets/lib/easing/easing.min.js', () => {
    });
    $.getScript('assets/lib/superfish/hoverIntent.js', () => {
    });
    $.getScript('assets/lib/superfish/superfish.min.js', () => {
    });
    $.getScript('assets/lib/wow/wow.min.js', () => {
    });
    $.getScript('assets/lib/owlcarousel/owl.carousel.min.js', () => {
    });
    $.getScript('assets/lib/magnific-popup/magnific-popup.min.js', () => {
    });
    $.getScript('assets/lib/sticky/sticky.js', () => {
    });
    $.getScript('assets/lib/magnific-popup/magnific-popup.min.js', () => {
    });
    $.getScript('src/assets/js/main.js', () => {
    }); */
  }
  showLogoutDialog(template) {
    this.confirmLogoutModalRef = this.modalService.show(template, this.config);
  }
  logout() {
    localStorage.removeItem('mine-insiders');
    this.router.navigate(['/login'])
      .then(() => {
        window.location.reload();
      });
    this.confirmLogoutModalRef.hide();
  }
  goToEntreprises() {
    this.router.navigate(['/entreprises'])
      .then(() => {
        window.location.reload();
      });
  }
  search() {
    console.log(this.searchParam.query);
    this.router.navigate(['/search'] ,{ queryParams: {query: this.searchParam.query} }).
    then(() => {
    });
  }
  removeMobileMode() {
    console.log(this.searchParam.query);
    $('body').removeClass('mobile-nav-active');
  }
 /* goToHome() {
    this.router.navigate(['/accueil'])
      .then(() => {
        window.location.reload();
      });
  }
  goToRegister() {
    this.router.navigate(['/register'])
      .then(() => {
        window.location.reload();
      });
  }
  goToProfil() {
    this.router.navigate(['/profil'])
      .then(() => {
        window.location.reload();
      });
  }
  goToContact() {
    this.router.navigate(['/contact'])
      .then(() => {
        window.location.reload();
      });
  }
  goToLogin() {
    this.router.navigate(['/login'])
      .then(() => {
        window.location.reload();
      });
  }
  goToAbout() {
    this.router.navigate(['/about'])
      .then(() => {
        window.location.reload();
      });
  }
  goToEntreprise() {
    this.router.navigate(['/entreprises'])
      .then(() => {
        window.location.reload();
      });
  }
  goToRessources() {
    this.router.navigate(['/ressources'])
      .then(() => {
        window.location.reload();
      });
  }
  goToPub() {
    this.router.navigate(['/publicite'])
      .then(() => {
        window.location.reload();
      });
  }
  goToProfMiners() {
    this.router.navigate(['/professionnels'])
      .then(() => {
        window.location.reload();
      });
  }
  goToConsultants() {
    this.router.navigate(['/consultants'])
      .then(() => {
        window.location.reload();
      });
  }
  goToFormateurs() {
    this.router.navigate(['/formateurs'])
      .then(() => {
        window.location.reload();
      });
  }
  goToStage() {
    this.router.navigate(['/stages'])
      .then(() => {
        window.location.reload();
      });
  }
  goToForum() {
    this.router.navigate(['/forum'])
      .then(() => {
        window.location.reload();
      });
  }
  goToGuide() {
    this.router.navigate(['/guides'])
      .then(() => {
        window.location.reload();
      });
  }
  goToCode() {
    this.router.navigate(['/code-miniers'])
      .then(() => {
        window.location.reload();
      });
  }
  goToNews() {
    this.router.navigate(['/mining-news'])
      .then(() => {
        window.location.reload();
      });
  }
  goToJobs() {
    this.router.navigate(['/jobs'])
      .then(() => {
        window.location.reload();
      });
  }
  goToStageOpportunity() {
    this.router.navigate(['/stages-opportunite'])
      .then(() => {
        window.location.reload();
      });
  }
  goToPartenaire() {
    this.router.navigate(['/partenaires'])
      .then(() => {
        window.location.reload();
      });
  }
  goToPartenaireList() {
    this.router.navigate(['/repertoire'])
      .then(() => {
        window.location.reload();
      });
  }
  */
}
