import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class StageService {

  private serviceUrl: string;
  private httpOptions: any;


  constructor(private http: HttpClient) {
    this.serviceUrl = environment.apiUrl;
  }

  public findAll() {
    return this.http.get(this.serviceUrl + '/stage', this.httpOptions);
  }

  public findAllEnabled() {
    return this.http.get(this.serviceUrl + '/stage/find/enabled', this.httpOptions);
  }

  public findStageByMiner(minerId) {
    return this.http.get(this.serviceUrl + '/stage/findByMiner/' + minerId, this.httpOptions);
  }

  public findStageById(stageId) {
    return this.http.get(this.serviceUrl + '/stage/findById/' + stageId, this.httpOptions);
  }

  public save(stage) {
    return this.http.post(this.serviceUrl + '/stage/', stage, this.httpOptions);
  }

  public update(stage) {
    return this.http.put(this.serviceUrl + '/stage/' , stage, this.httpOptions);
  }

  public desactivate(stageId) {
    return this.http.put(this.serviceUrl + '/stage/desactivate/' + stageId, this.httpOptions);
  }

  public activate(stageId) {
    return this.http.put(this.serviceUrl + '/stage/activate/'  + stageId, this.httpOptions);
  }
}
