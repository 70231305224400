import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Documents} from '../../../shared/components/models/documents';
import {DocumentService} from '../../../core/services/document.service';
import {environment} from '../../../../environments/environment';
import {Commentaire} from '../../../shared/components/models/commentaire';
import {CommentaireService} from '../../../core/services/commentaire.service';
import {Minier} from '../../../shared/components/models/minier';
import {MineInsidersUtils} from '../../../shared/components/utils/mineInsiders-util';

@Component({
  selector: 'app-forum-detail',
  templateUrl: './forum-detail.component.html',
  styleUrls: ['./forum-detail.component.scss']
})
export class ForumDetailComponent implements OnInit {
  loading: boolean = false;
  forum: Documents;
  documentImageUri = '';
  commentaire: Commentaire = new Commentaire();
  commentaires: Commentaire[];
  currentUser: Minier;
  constructor( private router: Router,
               private route: ActivatedRoute,
               private documentService: DocumentService,
               private commentaireService: CommentaireService) {
    this.documentImageUri = environment.documentImageUri;
    this.currentUser = new MineInsidersUtils().getCurrentUser();
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      if (params.publication) {
        this.loading = true;
        this.documentService.findDocumentForumById(params.publication).subscribe(ret => {
          if (ret['status'] === 'OK') {
            this.forum = ret['response'];
            console.log('doc forum', this.forum);
            this.findCommentaireByDocument(this.forum.id);
            this.loading = false;
          } else {
            this.loading = false;
            this.router.navigate(['/error']);
          }
        }, error => {
          // console.log('erreur server');
          this.loading = false;
          this.router.navigate(['/error']);
        });
      } else {
        this.router.navigate(['/error']);
      }
    });
    window.scrollTo(0, 0);
  }

  saveCommentaire() {
    this.loading = true;
    this.commentaire.document = this.forum;
    this.commentaire.minier = this.currentUser;
    this.commentaire.enabled = true;
    // console.log('commentaire', this.commentaire);
    this.commentaireService.save(this.commentaire).subscribe(ret => {
      if (ret['status'] === 'OK') {
        this.commentaire = ret['response'];
        // console.log('commentaire', this.commentaire);
        this.findCommentaireByDocument(this.forum.id);
        this.loading = false;
        this.commentaire = new Commentaire();
      } else {
        this.loading = false;
      }
    }, error => {
      console.log('erreur server');
      this.loading = false;
    });
  }
  findCommentaireByDocument(docId) {
    this.loading = true;
    this.commentaireService.findCommentByDocument(docId).subscribe(ret => {
      if (ret['status'] === 'OK') {
        this.commentaires = ret['response'];
        // console.log('lise commentaires', this.commentaires);
        this.loading = false;
      } else {
        this.loading = false;
      }
    }, error => {
      // console.log('erreur server');
      this.loading = false;
    });
  }
  showDetailsMiniers(miner: Minier) {
    this.router.navigate(['/detail-cadre-mines'] ,{ queryParams: {minerId: miner.id} });
  }
}
