
<!-- #repertoires -->
<section id="repertoires" class="wow fadeInUp" style="min-height: 500px;margin-top: 20px">
  <div class="container">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a href="#">Acceuil</a></li>
        <li class="breadcrumb-item"><a href="javascript:void (0)">Entreprises par pays</a></li>
      </ol>
    </nav>
  </div>
  <div class="container">
    <div class="section-header">
      <h2 *ngIf="entreprises?.length>0">Entreprises minières du {{entreprises['0']?.pays?.nom}}</h2>
      <h2 *ngIf="entreprises?.length==0">Aucune Entreprise trouvée</h2>
    </div>
    <div class="row">
      <div class="col-lg-6 col-md-6 col-sm-12" *ngFor="let item of entreprises">
        <div class="box wow fadeInRight" (click)="goToEntrepriseDetails(item)" style="cursor:pointer">
          <div class="icon"><img [src]="imageUrl+item.logoImageName" width="90px" height="90px" /></div>
          <h4 class="title"><a href="javascript:void(0)">{{item.nom}}</a></h4>
          <p class="description" [innerHtml]="item.information | slice:0:100"></p>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- #repertoires -->
