<mat-tab-group>
  <mat-tab label="News">
    <mat-list role="list">
      <mat-list-item role="listitem" *ngFor="let n of newses" style="margin-bottom: 10px">
        <a [href]="'#/mining-news?news='+n.id">
          <span style="font-size: 13px"> <strong>{{n.datePublication | date : 'dd/MM'}} :</strong> {{n.titre}}</span>
        </a>
      </mat-list-item>
    </mat-list>
  </mat-tab>
  <mat-tab label="Jobs">
    <mat-list role="list">
      <mat-list-item role="listitem" *ngFor="let j of jobList">
        <a [href]="'#/Jobs-detail?opportunite='+j.id">
          <span style="font-size: 14px"><strong>{{j.datePublication | date : 'dd/MM'}} :</strong> {{j.nom}}</span>
        </a>
      </mat-list-item>
    </mat-list>
  </mat-tab>
</mat-tab-group>
