<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '5px',fullScreenBackdrop:true }"></ngx-loading>

<!-- UPDATE PROFIL IMAGE DIALOG-->
<ng-template #updateProfilTemplate>
  <div class="p-2" style="width: 100%">
    <button type="button" class="close pull-right" aria-label="Close" (click)="updateProfilDialog.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <mat-divider></mat-divider>
  <div class="modal-body">
    <div class="text-center mb-2" style="color: #007ef0; background: #ffffff;">
      <div class="mt-2">
        <button class="btn btn-primary" (click)="selectPicture()" type="button">
          <i class="fa fa-fw fa-camera"></i>
          Selectionner Photo
        </button>
        <div class="mx-auto" style="width: 140px;">
          <div class="d-flex justify-content-center align-items-center rounded" [style.backgroundImage]="'url('+profilImageData+')'" style="height: 140px;width: 140px; background-position: center; background-color: rgb(233, 236, 239); background-size: cover;background-repeat: no-repeat;" >
            <span></span>
          </div>
        </div>
      </div>
    </div>
    <mat-divider></mat-divider>
    <div class="row mt-4">
      <div class="col-12 text-center">
        <button style="color: #fff; background: #007ef0" [disabled]="!profilImageData" (click)="updateProfilImage()" class="btn">Enregistrer</button>
      </div>
    </div>
  </div>
</ng-template>
<!-- UPDATE PROFIL IMAGE DIALOG-->

<!-- MESSAGE DETAIL DIALOG-->
<ng-template #messageDetailTemplate>
  <div class="p-2" style="width: 100%">
    <button type="button" class="close pull-right" aria-label="Close" (click)="detailMessageDialog.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <mat-divider></mat-divider>
  <div class="modal-body">
    <div style="background: #ffffff;width: 100%">
      <p [innerHtml]="currentMessage?.description"></p>
    </div>
    <mat-divider></mat-divider>
    <div class="row mt-4">
      <div class="col-12 text-center">
        <button style="color: #fff; background: #007ef0" (click)="detailMessageDialog.hide()">Fermer</button>
      </div>
    </div>
  </div>
</ng-template>
<!-- MESSAGE DETAIL DIALOG-->

<!-- CV CONSULTE DIALOG-->
<ng-template #cvTemplate>
  <div class="p-2" style="width: 100%">
    <button type="button" class="close pull-right" aria-label="Close" (click)="consulteCvDialog.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <mat-divider></mat-divider>
  <div class="modal-body">
    <div class="text-center mb-2" style="color: #007ef0; background: #ffffff;">
      <div>
        <a matTooltip="Télécharger le cv" [href]="cvFileUri+postulant.fileName" target="_blank">
          <i style="color:blue" class="fa fa-file-pdf-o"></i>
        </a>
        <pdf-viewer [src]="cvFileUri+postulant.fileName"
                    [render-text]="true"
                    style="display: block;">
        </pdf-viewer>
      </div>
    </div>
    <mat-divider></mat-divider>
    <div class="row mt-4">
      <div class="col-12 text-center">
        <button style="color: #fff; background: #007ef0" class="btn" (click)="consulteCvDialog.hide()">Quitter</button>
      </div>
    </div>
  </div>
</ng-template>
<!-- CV CONSULTE DIALOG-->

<div class="container" style="min-height: 490px">
  <div class="row">
    <input type="file" #fileInput style="visibility: hidden; height: 0px" name="file"
           (change)="processWebImage($event)"/>
    <input type="file" #docInput style="visibility: hidden; height: 0px" name="file"
           (change)="processDocImage($event)"/>
  </div>
  <mat-tab-group mat-align-tabs="start">
    <mat-tab label="Profil">
      <div class="container">
        <div class="row flex-lg-nowrap">
          <div class="col">
            <div class="row">
              <div class="col mb-3">
                <div class="card">
                  <div class="card-body">
                    <div class="e-profile">
                      <div class="row">
                        <div class="col-12 col-sm-auto mb-3">
                          <div class="mx-auto">
                            <div class="d-flex justify-content-center align-items-center rounded" [style.backgroundImage]="'url('+profilImageUri+currentUser.profilImage+')'" style="height: 160px;width: 140px; background-position: center; background-color: rgb(233, 236, 239); background-size: cover;background-repeat: no-repeat;" >
                              <span></span>
                            </div>
                          </div>
                        </div>
                        <div class="col d-flex flex-column flex-sm-row justify-content-between mb-3">
                          <div class="text-center text-sm-left mb-2 mb-sm-0">
                            <h4 class="pt-sm-2 pb-1 mb-0 text-nowrap">{{currentUser.prenom}} {{currentUser.nom}}</h4>
                            <p class="mb-0">{{currentUser.email}}</p>
                            <div class="mt-2">
                              <button class="btn btn-primary" (click)="showUpdatepdateProfilImageDialog(updateProfilTemplate)" type="button">
                                <i class="fa fa-fw fa-camera"></i>
                                Ajouter photo
                              </button>
                            </div>
                          </div>
                          <div class="text-center text-sm-right">
                            <span class="badge badge-secondary" *ngIf="currentUser.type=='PROFESSIONNEL'">Professionnel dans les mines</span>
                            <span class="badge badge-secondary" *ngIf="currentUser.type=='CONSULTANT'">Consultant dans les mines</span>
                            <span class="badge badge-secondary" *ngIf="currentUser.type=='FORMATEUR'">Formateur dans les mines</span>
                            <div class="text-muted"><small>Inscrit le {{currentUser.dateCreation | date : 'dd-MM-yyyy'}}</small></div>
                            <button class="btn btn-primary" type="button" (click)="openDialog()"><i class="fa fa-fw fa-pencil"></i>Changer mot de passe</button>
                          </div>
                        </div>
                      </div>
                      <hr>
                      <div class="tab-content pt-3">
                        <div class="tab-pane active">
                          <form class="form" novalidate="">
                            <div class="form-row">
                              <div class="form-group col-md-6">
                                <h4>Spécialité : </h4>
                                <input type="text" name="specialite" [(ngModel)]="currentUser.specialite" class="form-control" placeholder="">
                              </div> <!-- form-group end.// -->
                            </div>
                            <hr>
                            <div class="row">
                              <div class="col">
                                <div class="row">
                                  <div class="col mb-3">
                                    <div class="form-group">
                                      <h4>Présentation</h4>
                                      <div class="NgxEditor__Wrapper">
                                        <ngx-editor-menu [editor]="editor3"> </ngx-editor-menu>
                                        <ngx-editor
                                          name="presentation"
                                          outputFormat ="html"
                                          [spellcheck]="true"
                                          [editor]="editor3"
                                          [(ngModel)]="currentUser.presentation"
                                          placeholder="Présenter vous brièvement">
                                        </ngx-editor>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div>

                              <div class="form-row">
                                <div class="col form-group">
                                  <label>Nom </label>
                                  <input type="text" name="nom" [(ngModel)]="currentUser.nom" class="form-control" placeholder="">
                                </div> <!-- form-group end.// -->
                                <div class="col form-group">
                                  <label>Prenom </label>
                                  <input type="text" name="prenom" [(ngModel)]="currentUser.prenom" class="form-control" placeholder=" ">
                                </div> <!-- form-group end.// -->
                              </div> <!-- form-row end.// -->
                              <div class="form-row">
                                <div class="form-group col-md-6">
                                  <label>Adresse Email </label>
                                  <input type="email" name="email" [(ngModel)]="currentUser.email" class="form-control" placeholder="">
                                </div> <!-- form-group end.// -->
                                <div class="form-group col-md-6">
                                  <label>Téléphone </label>
                                  <input type="number" name="telephone" [(ngModel)]="currentUser.telephone" class="form-control" placeholder="">
                                </div>
                              </div>
                              <div class="form-group" *ngIf="false">
                                <label class="form-check form-check-inline">
                                  <input class="form-check-input" type="radio" name="gender" value="option1">
                                  <span class="form-check-label"> Masculin </span>
                                </label>
                                <label class="form-check form-check-inline">
                                  <input class="form-check-input" type="radio" name="gender" value="option2">
                                  <span class="form-check-label"> Feminin</span>
                                </label>
                              </div> <!-- form-group end.// -->
                              <div class="form-row">
                                <div class="form-group col-md-6">
                                  <label>Pays </label>
                                  <select id="inputState" name="pays" [(ngModel)]="currentUser.pays" class="form-control">
                                      <option> Choose...</option>
                                      <option *ngFor="let pays of paysList" [ngValue]="pays">{{pays.nom}}</option>
                                  </select>
                                </div> <!-- form-group end.// -->
                                <div class="form-group col-md-6">
                                  <label>Ville </label>
                                  <input type="text" name="ville" [(ngModel)]="currentUser.ville" class="form-control">
                                </div> <!-- form-group end.// -->
                              </div> <!-- form-row.// -->

                              <div class="form-row">
                                <div class="form-group col-md-6">
                                  <mat-form-field style="width: 400px;margin-top:20px">
                                    <mat-select id="domaine"  placeholder="Vos domaines d'expertise (multiple choix)" name="domaine" [(ngModel)]="currentUser.domaines"
                                                multiple>

                                      <mat-option *ngFor="let domaine of domaines" [value]="domaine">
                                        {{domaine.nom}}
                                      </mat-option>
                                    </mat-select>
                                  </mat-form-field>
                                </div>

                                <div class="form-group col-md-6">
                                  <div class="form-group">
                                    <label for="ecole">Ecole / Université d'obtention du dernier diplôme </label>
                                    <select id="ecole" class="form-control" name="ecole" [(ngModel)]="currentUser.ecole" style="height: 34px">
                                      <option> Choose...</option>
                                      <option *ngFor="let ecole of ecoles" [ngValue]="ecole">{{ecole.nom}}</option>
                                    </select>
                                  </div> <!-- form-group end.// -->
                                </div>
                              </div>

                              <div class="form-row">
                                <div class="form-group">
                                  <mat-form-field style="width: 550px;">
                                    <mat-label>Vous êtes ? <span style="color: red">*</span></mat-label>
                                    <mat-select id="type" [(ngModel)]="currentUser.type" name="type">
                                      <mat-option value="PROFESSIONNEL">
                                        Un professionnel des mines (Géologie, exploration, Construction, production, usine)
                                      </mat-option>
                                      <mat-option value="CONSULTANT">
                                        Un consultant des mines
                                      </mat-option>
                                      <mat-option value="PROFESSIONNEL">
                                        Un formateur technique particulier des mines
                                      </mat-option>
                                    </mat-select>
                                  </mat-form-field>
                                </div>
                              </div>
                            </div>
                            <br>
                            <h4>Formations :</h4>
                            <div class="NgxEditor__Wrapper">
                              <ngx-editor-menu [editor]="editor2"> </ngx-editor-menu>
                              <ngx-editor
                                name="formation"
                                [spellcheck]="true"
                                outputFormat ="html"
                                [editor]="editor2"
                                [(ngModel)]="currentUser.formation"
                                placeholder="Votre Formation">
                              </ngx-editor>
                            </div>
                            <br>
                            <h4>Experiences :</h4>
                            <div class="NgxEditor__Wrapper">
                              <ngx-editor-menu [editor]="editor"> </ngx-editor-menu>
                              <ngx-editor
                                name="curriculum"
                                [spellcheck]="true"
                                outputFormat ="html"
                                [editor]="editor"
                                [(ngModel)]="currentUser.curriculum"
                                placeholder="Vos Experiences">
                              </ngx-editor>
                            </div>
                            <br>
                            <div class="row">
                              <div class="col d-flex justify-content-end">
                                <button class="btn btn-primary" type="submit" (click)="updateProfilData()">Sauvegarder</button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </mat-tab>

    <mat-tab label="Publication sur Forum">

      <div *ngIf="listDoc">
        <mat-card>
          <button class="btn-outline-primary btn-sm" (click)="showAddDoc()"><i class="fa fa-plus"></i> Publier sur le forum</button> &nbsp;&nbsp;&nbsp;
          <button class="btn-outline-secondary btn-sm" (click)="loadMinerForumDoc()"><i class="fa fa-refresh"></i></button>
        </mat-card>
        <div class="table-responsive">
          <table mat-table [dataSource]="dataSource"  class="table-hover" matSort>
            <ng-container matColumnDef="date">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Date création</th>
              <td mat-cell *matCellDef="let row">{{row.dateCreation | date: 'medium'}}</td>
            </ng-container>

            <ng-container matColumnDef="nom">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Nom</th>
              <td mat-cell *matCellDef="let row">{{row.nom}}</td>
            </ng-container>

            <ng-container matColumnDef="etat">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Etat</th>
              <td class="text-truncate" mat-cell *matCellDef="let row">
                <span *ngIf="row.enabled" style="color: #14ff11">approuvé</span>
                <span *ngIf="!row.enabled" style="color: #b21f2d">en attente</span>
              </td>
            </ng-container>

            <ng-container matColumnDef="options">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Option</th>
              <td mat-cell *matCellDef="let row">
                <a matTooltip="Modifier" style="cursor: pointer" (click)="showEditDoc(row)"><i class="fa fa-edit"></i>
                </a>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
          <mat-paginator #forumPaginator [pageSize]="10" [pageSizeOptions]="[5, 10, 25]"> </mat-paginator>
        </div>
      </div>

      <div *ngIf="addDoc">
        <mat-card>
          <h3 align="center" *ngIf="!doc.id">Nouveau Article</h3>
          <h3 align="center" *ngIf="doc.id">Modification Article</h3>
          <div class="card card-body bg-light" style="margin-bottom: 15px">
            <form #docForm="ngForm" name="form">
              <div class="form-row">
                <div class="form-group col-md-12">
                  <label>Nom ou titre <span style="color: red">*</span></label>
                  <input type="text" class="form-control" required name="nom" [(ngModel)]="doc.nom" placeholder="">
                </div>
              </div> <!-- form-row end.// -->
              <br>
              <div class="form-row">
                <div class="form-group col-md-6">
                  <button class="btn-outline-primary btn-sm" (click)="selectDocPicture()"><i class="fa fa-plus"></i> Ajouter une Image</button>
                </div>
                <div class="form-group col-md-6" *ngIf="documentData">
                  <a href="#">
                    <img class="img-thumbnail" style="margin-bottom: 15px" [src]="domSanitizer.bypassSecurityTrustUrl(documentData)">
                  </a>
                </div>
                <div class="form-group col-md-6" *ngIf="!documentData && doc.fileUploadName">
                  <a href="#">
                    <img class="img-thumbnail" style="margin-bottom: 15px" [src]="documentImageUri+doc.fileUploadName">
                  </a>
                </div>
              </div> <!-- form-row end.// -->
              <br>
              <div class="form-row">
                <div class="form-group col-md-12">
                  <label>Contenu : <span style="color: red">*</span></label>
                  <div class="NgxEditor__Wrapper">
                    <ngx-editor-menu [editor]="editor4"> </ngx-editor-menu>
                    <ngx-editor
                      name="description"
                      outputFormat ="html"
                      [spellcheck]="true"
                      [editor]="editor4"
                      [(ngModel)]="doc.description"
                      placeholder="Contenu de votre article"
                     required>
                    </ngx-editor>
                  </div>
                </div>
              </div> <!-- form-row end.// -->
              <hr>
              <br>
              <div class="form-group" align="center" *ngIf="!doc.id">
                <button type="submit" class="btn btn-primary" [disabled]="!docForm.form.valid" (click)="saveDocument()"> Publier </button> &nbsp;&nbsp;&nbsp;
                <button type="submit" class="btn btn-danger" (click)="showListDoc()"><i class="fa fa-arrow-left"></i> Retour</button>
              </div> <!-- form-group// -->
              <div class="form-group" align="center" *ngIf="doc.id">
                <button type="submit" class="btn btn-primary" [disabled]="!docForm.form.valid" (click)="updateDocument()"> Modifier </button> &nbsp;&nbsp;&nbsp;
                <button type="submit" class="btn btn-danger" (click)="showListDoc()"><i class="fa fa-arrow-left"></i> Retour</button>
              </div> <!-- form-group// -->
              <div class="pull-right">
                <span style="color: red;font-size: small">* les champs obligatoires</span>
              </div>
            </form>
          </div> <!-- card-body end .// -->
        </mat-card>
      </div>

    </mat-tab>

    <mat-tab label="Proposition de stage">

      <div *ngIf="listStage">
        <mat-card>
          <button class="btn-outline-primary btn-sm" (click)="showAddStage()"><i class="fa fa-plus"></i> Ajout proposition de stage </button>&nbsp;&nbsp;&nbsp;
          <button class="btn-outline-secondary btn-sm" (click)="findStageByMiner()"><i class="fa fa-refresh"></i></button>
        </mat-card>
        <div class="table-responsive">
          <table mat-table [dataSource]="dataSourceStage"  class="table-hover" matSort>
            <ng-container matColumnDef="date">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Date création</th>
              <td mat-cell *matCellDef="let row">{{row.dateCreation | date: 'medium'}}</td>
            </ng-container>

            <ng-container matColumnDef="nom">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Nom</th>
              <td mat-cell *matCellDef="let row">{{row.nom}}</td>
            </ng-container>

            <ng-container matColumnDef="etat">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Etat</th>
              <td class="text-truncate" mat-cell *matCellDef="let row">
                <span *ngIf="row.enabled" style="color: #14ff11">approuvé</span>
                <span *ngIf="!row.enabled" style="color: #b21f2d">en attente</span>
              </td>
            </ng-container>

            <ng-container matColumnDef="options">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Option</th>
              <td mat-cell *matCellDef="let row">
                <a matTooltip="Modifier" style="cursor: pointer" (click)="showEditStage(row)"><i class="fa fa-edit"></i>
                </a>&nbsp;&nbsp;&nbsp;
                <a matTooltip="Voir les postulants" style="cursor: pointer; color: #1a68ff" (click)="showStagePostulant(row)">
                  <i class="fa fa-file-pdf-o"></i>
                </a>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumnsStage"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnsStage;"></tr>
          </table>
          <mat-paginator #stagePaginator [pageSize]="10" [pageSizeOptions]="[5, 10, 25]"> </mat-paginator>
        </div>
      </div>

      <div *ngIf="addStage">
        <mat-card>
          <button class="btn btn-sm pull-left" style="background-color: #dddddd" (click)="showListStage()"><i class="fa fa-arrow-left"></i> Retour</button>
          <h3 align="center" *ngIf="!stage.id">Nouvelle proposition de stage</h3>
          <h3 align="center" *ngIf="stage.id">Modification de la proposition de stage</h3>
          <div class="card card-body bg-light" style="margin-bottom: 15px">
            <form #stageForm="ngForm" name="form">
              <div class="form-row">
                <div class="form-group col-md-12">
                  <label>Nom ou titre <span style="color: red">*</span></label>
                  <input type="text" name="stage"  required [(ngModel)]="stage.titre" class="form-control" placeholder="">
                </div>
              </div> <!-- form-row end.// -->
              <br>
              <div class="form-row">
                <div class="form-group col-md-12">
                  <label>Contenu : <span style="color: red">*</span></label>
                  <div class="NgxEditor__Wrapper">
                    <ngx-editor-menu [editor]="editor5"> </ngx-editor-menu>
                    <ngx-editor
                      name="descriptionStage"
                      [spellcheck]="true"
                      outputFormat ="html"
                      [editor]="editor5"
                      [(ngModel)]="stage.description"
                      placeholder="Contenu de votre proposition de stage"
                      required>
                    </ngx-editor>
                  </div>
                </div>
              </div> <!-- form-row end.// -->
              <hr>
              <br>
              <div class="form-group" align="center">
                <button type="submit" class="btn btn-primary" *ngIf="!stage.id" [disabled]="!stageForm.form.valid" (click)="saveStage()"> Enregistrer </button>
                <button type="submit" class="btn btn-primary" *ngIf="stage.id" [disabled]="!stageForm.form.valid" (click)="updateStage()"> Modifier </button>&nbsp;&nbsp;&nbsp;
                <button type="submit" class="btn btn-danger" (click)="showListStage()"><i class="fa fa-arrow-left"></i> Retour</button>
              </div> <!-- form-group// -->
              <div class="pull-right">
                <span style="color: red;font-size: small">* les champs obligatoires</span>
              </div>
            </form>
          </div> <!-- card-body end .// -->
        </mat-card>
      </div>


      <div *ngIf="postulantStage">
        <mat-card>
          <button class="btn btn-sm pull-left" style="background-color: #dddddd" (click)="showListStage()"><i class="fa fa-arrow-left"></i> Retour</button>
          <h3 align="center">Liste des postulants : {{stage?.titre}}</h3>
          <p align="center" style="color: #1a68ff">{{postulants?.length}} postulant(s)</p>
          <div class="card card-body bg-light" style="margin-bottom: 10px" *ngFor="let item of postulants">
            <div class="row">
              <div class="col-sm-12 col-lg-12 col-md-12 col-xl-12 col-12">
                <span>{{item.prenom}} {{item.nom}}</span> &nbsp;&nbsp;&nbsp;&nbsp;
                <span style="color: #0b0b0b"><a [href]="'mailto:'+item.email">{{item.email}}</a></span> &nbsp;&nbsp;&nbsp;&nbsp;
                <a href="javascript:void(0)" style="color:#1a68ff" (click)="consultePostulantCv(item)">
                  <i class="fa fa-file-pdf-o"></i> Consulter CV
                </a>
              </div>
            </div>
          </div>
        </mat-card>
      </div>

      <!-------------SHOW CV -------------->
      <div *ngIf="showCv">
        <mat-card>
          <div class="row mt-2">
            <button class="btn btn-sm pull-left" style="background-color: #dddddd" (click)="showStagePostulant(postulant.stage)"><i class="fa fa-arrow-left"></i>
              Retour
            </button>
          </div>
          <div class="row mt-5">
            <a matTooltip="Télécharger le cv" [href]="cvFileUri+postulant.fileName" target="_blank">
              <i style="color:blue" class="fa fa-download">Télécharger le cv</i>
            </a>
          </div>
          <div class="row mt-2">
            <div class="col-sm-12 col-lg-12 col-md-12 col-xl-12 col-12">
              <pdf-viewer [src]="cvFileUri+postulant.fileName"
                          [render-text]="true"
                          style="display: block;">
              </pdf-viewer>
            </div>
          </div>
        </mat-card>
      </div>

    </mat-tab>

    <mat-tab [label]="messageNotSeeCount? 'messages ('+messageNotSeeCount+')': 'message ('+0+')'">
      <div class="table-responsive">
        <table class="table">
          <thead>
          <tr>
            <th>Date</th>
            <th>Expéditeur</th>
            <th>Email</th>
            <th>Objet</th>
            <th>Options</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let row of messages | paginate: { itemsPerPage: 4, currentPage: p }">
            <td>{{row.dateCreation | date: 'medium'}}</td>
            <td>{{row.nom}}</td>
            <td><a  matTooltip="Repondre" [href]="'mailto:'+row.email">{{row.email}}</a></td>
            <td>{{row.objet}}</td>
            <td>
              <button class="btn" (click)="showDetailMessage(row,messageDetailTemplate)"><i class="fa fa-eye"></i></button>
            </td>
          </tr>
          </tbody>
        </table>
        <pagination-controls (pageChange)="p = $event"
                             directionLinks="true"
                             autoHide="true"
                             responsive="true"
                             previousLabel="Précédent"
                             nextLabel="Suivant"
                             screenReaderPaginationLabel="Pagination"
                             screenReaderPageLabel="page"
                             screenReaderCurrentLabel="Vous êtes dejà sur la page">
        </pagination-controls>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
<!-- #sites -->
