<div style="width: 300px;">
  <div mat-dialog-title=""></div>
  <div mat-dialog-content>
      <form>
        <mat-form-field style="width: 100%">
          <input matInput  name="nom" placeholder="Nom complet" required>
        </mat-form-field>
        <br>
        <mat-form-field style="width: 100%">
          <input matInput  name="objet" placeholder="Objet" required>
        </mat-form-field>
        <br>
        <mat-form-field style="width: 100%">
          <input matInput  name="email" placeholder="Email" required>
        </mat-form-field>
        <mat-form-field style="width: 100%">
          <textarea matInput name="message" placeholder="Message" required></textarea>
        </mat-form-field>
      </form>
  </div>
  <hr>
  <div mat-dialog-actions align="center">
    <button class="btn btn-danger btn-sm" (click)="onNoClick()">Annuler</button>
    <button class="btn btn-primary btn-sm" style="margin-left: 20px;">
      Envoyer
    </button>
  </div>
</div>

