import { Component, OnInit } from '@angular/core';
import {Contact} from '../../shared/components/models/contact';
import {MessageService} from '../../core/services/message.service';
import {ToastService} from '../../shared/components/utils/date-util';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
  message: Contact = new Contact();
  loading = false;
  constructor(private messageService: MessageService,
              private toast: ToastrService) {
  }

  ngOnInit() {
    window.scrollTo(0, 0);
  }

  sendMessage() {
    console.log(this.message);
    this.loading = true;
    this.messageService.sendContactMessage(this.message).subscribe(ret => {
      if (ret['status'] === 'OK') {
        console.log('message', ret['message']);
        this.toast.success(ret['message']);
        this.loading = false;
        this.message = new Contact();
      } else {
        this.toast.error(ret['message']);
        this.loading = false;
      }
    }, error => {
      this.toast.error('Problème de connexion au serveur');
      this.loading = false;
    });
  }

}
