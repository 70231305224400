<ngx-loading-bar color="#fff"></ngx-loading-bar>
<section id="portfolio" class="wow fadeInUp">
<div class="container">
  <div class="section-header">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a href="/accueil">Accueil</a></li>
        <li class="breadcrumb-item active" aria-current="page">Recherche</li>
        <li *ngIf="searchResult?.length>0" class="breadcrumb-item active" aria-current="page">Resultats de la recherche du mot clé " <span style="font-weight: bold">{{searchParam.query}}</span> " <span style="font-weight: bold">({{searchResult?.length}} elements trouvés)</span></li>
      </ol>
    </nav>
  </div>
</div>

<div class="container" style="min-height: 400px">
    <div class="card card-body" style="margin-bottom: 10px" *ngFor="let result of searchResult | paginate: { itemsPerPage: 30, currentPage: p }">
      <div class="row" (click)="showDetail(result)" style="cursor: pointer">
        <div class="col-sm-12 col-lg-4 col-md-4 col-xl-4 col-12" >
          <div class="text-center">
            <a href="javascript:void(0)">
              <img *ngIf="result.type === 'NEWS'" class="img-thumbnail"  style="height: 120px;width: 200px" [src]="newsImageUri+result.imageName">
              <img *ngIf="result.type === 'JOBS'" class="img-thumbnail"  style="height: 120px;width: 200px" [src]="opportuniteImageUri+result.imageName">
              <img *ngIf="result.type === 'EVENTS'" class="img-thumbnail"  style="height: 120px;width: 200px" [src]="eventsImageUri+result.imageName">
            </a>
          </div>
        </div>
        <div class="col-sm-12 col-lg-8 col-md-8 col-xl-8 col-12">
          <div>
            <a href="javascript:void(0)" (click)="showDetail(result)" style="color: black">
              <h4 style="font-size: 18px;color: #007cdc">{{result.titre}}</h4>
            </a>
            <p [innerHtml]="(result.description | slice:0:500) + '... voir plus'">
            </p>
            <div class="row">
              <div class="col-sm-6 col-lg-6 col-md-6 col-6 col-xl-6">
                <span style="color: #959595"><i class="fa fa-calendar"></i>  {{result.dateCreation | date : 'medium'}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <pagination-controls (pageChange)="p = $event"
                         directionLinks="true"
                         autoHide="true"
                         responsive="true"
                         previousLabel="Précédent"
                         nextLabel="Suivant"
                         screenReaderPaginationLabel="Pagination"
                         screenReaderPageLabel="page"
                         screenReaderCurrentLabel="Vous êtes dejà sur la page">
    </pagination-controls>
    <div class="col-md-12 col-lg-12 col-12 col-sm-12" *ngIf="searchResult?.length==0" align="center">
      Aucun element trouvé! pour le mot clé " {{searchParam?.query}} "
    </div>
  </div>

</section>
