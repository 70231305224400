import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import { MineInsidersUtils } from 'src/app/shared/components/utils/mineInsiders-util';

@Injectable({
  providedIn: 'root'
})
export class PaysService {
  private serviceURL: string;
  private httpOptions: any;

  constructor(private http: HttpClient) {
    this.serviceURL = environment.apiUrl;
    this.httpOptions = new MineInsidersUtils().httpHeaders();
  }

  public loadCountriesList() {
    return this.http.get(this.serviceURL + '/pays', this.httpOptions);
  }
}
