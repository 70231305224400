import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SlidersService {

  private serviceUrl: string;
  private httpOptions: any;
  

  constructor(private http: HttpClient) {
   
    this.serviceUrl = environment.apiUrl;
  }
  public findAllSlider() {
    return this.http.get(this.serviceUrl + '/slider');
  }
  public list(all) {
    return this.http.get(this.serviceUrl + '/slider?all='+all,this.httpOptions);
}

public save(slider) {
    return this.http.post(this.serviceUrl+'/slider', slider, this.httpOptions);
}

public update(slider) {
    return this.http.put(this.serviceUrl+'/slider', slider, this.httpOptions);
}

public desactivate(slider) {
    return this.http.put(this.serviceUrl + '/slider/desactivate/'+slider,this.httpOptions);
}

public activate(slider) {
    return this.http.put(this.serviceUrl + '/slider/activate/'+slider,this.httpOptions);
}

public upload(slider) {
    return this.http.post(this.serviceUrl+'/slider/uploadImage', slider, this.httpOptions);
}
  
}
