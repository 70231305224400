import {Deserializable} from './deserializable';
import { Injectable } from '@angular/core';
import {Minier} from './minier';
@Injectable()
export class Stage implements Deserializable<Stage> {
  public id: number;
  public titre: string;
  public description: string;
  public datePublication: Date;
  public dateCreation: Date;
  public dateModification: Date;
  public enabled: boolean;
  public imageName: string;
  public imageData: string;
  public minier: Minier;
  deserialize(input: any): Stage {
    Object.assign(this, input);
    return this;
  }
}
