import { Deserializable } from './deserializable';
import { Injectable } from '@angular/core';
import { Profile } from './profile';
import { File } from './file';

@Injectable()
export class Utilisateur implements Deserializable<Utilisateur> {

  public id: number;
  public nom: string;
  public nomComplet: string;
  public prenom: string;
  public login: string;
  public password: string;
  public continent: string;
  public adresse: string;
  public pays: string;
  public dateNaiss?: Date;
  public telephone: string;
  public email: string;
  public files: File[];
  public imageData?: string;
  public enabled: boolean;
  public dateCreation: Date;
  public dateModification: Date;
  public profile: Profile;

  deserialize(input: any): Utilisateur {
    Object.assign(this, input);
    return this;
  }
}
