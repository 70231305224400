import {Deserializable} from './deserializable';
import { Injectable } from '@angular/core';
@Injectable()
export class SearchParam implements Deserializable<SearchParam> {
  public id: number = 0;
  public countryId: number = 0;
  public ecoleId: number = 0;
  public domaineId: number = 0;
  public query: string;
  public enabled: boolean = true;
  public type: string;
  deserialize(input: any): SearchParam {
    Object.assign(this, input);
    return this;
  }
}
