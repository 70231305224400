
<section id="portfolio" class="wow fadeInUp">
  <div class="container-fluid">
    <div class="section-header">
      <h2>Guide pour obtenir un permis de recherche ou d’exploitation minière au sein de l’UEMOA</h2>
    </div>
  </div>

  <div class="container-fluid" style="min-height: 400px">
    <div class="row">

      <div class="col-md-12 col-lg-12 col-12 col-sm-12" *ngFor="let guide of guideList | paginate: { itemsPerPage: 5, currentPage: p }">
        <mat-list>
          <mat-list-item>
            <mat-icon mat-list-icon>file_copy</mat-icon>
            <a href="javascript:void(0)" (click)="showDetails(guide)" style="color: #959598" mat-line>{{guide.nom}}</a>
          </mat-list-item>
        </mat-list>
      </div>
      <div class="col-md-12 col-lg-12 col-12 col-sm-12" *ngIf="guideList?.length==0" align="center">
        Aucun guide trouvé
      </div>
    </div>
  </div>
</section>
<!-- #portfolio -->
